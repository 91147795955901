import { combineReducers } from 'redux'
import loginReducer from '../pages/login/redux/reducers'
import layoutReducer from '../components/Layout/redux/reducers'
import loansReducer from '../pages/loans/redux/reducers'
import AddUserReducer from '../pages/user_add/redux/reducers'
import GetAnalyticsReducer from '../pages/dashboard/redux/reducers'
import getNotificationReducer from '../pages/notifications/redux/reducers'
import getUsersReducer from '../pages/users/redux/reducers'
import getBanksReducer from '../pages/banks/redux/reducers'
import driversReducers from '../pages/drivers/redux/reducers'
import RejectedLoansReducer from '../pages/rejected_loans/redux/reducers'
import repaymentsReducer from '../pages/loan_repayment/redux/reducers'
import DriverReducer from '../pages/driver/redux/reducers'
import getParamsReducer from '../pages/params/redux/reducers'
import pendingReviewReducer from '../pages/pending_review/redux/reducers'

//

const reducers = {
  login: loginReducer,
  layout: layoutReducer,
  loans: loansReducer,
  addUser: AddUserReducer,
  analytics: GetAnalyticsReducer,
  notifications: getNotificationReducer,
  users: getUsersReducer,
  banks: getBanksReducer,
  driversReducer: driversReducers,
  rejected: RejectedLoansReducer,
  repayments: repaymentsReducer,
  resubmit: DriverReducer,
  params: getParamsReducer,
  review: pendingReviewReducer,
}

const rootReducer = combineReducers(reducers)
export default rootReducer
