import { call, put } from 'redux-saga/effects'
// import history from './history';
//import { uistartLoading, uistopLoading } from "../Ui/actions";
// import { useHistory } from "react-router-dom";
import { analyticsCall } from './api'
import { getAnalyticsSuccess } from './actions'
import { toast } from 'react-toastify'

export function* GetAnalyticsSaga(action) {
  try {
    const res = yield call(analyticsCall, action.id)
    console.log('analytics console', res)

    yield put(getAnalyticsSuccess(res.data.analytics))
    // action.history.push('/');
    // console.log("{{{{{{{[[[[happened");
  } catch (err) {
    //   yield put(loginFailed(err.message));
    //   console.log("Login error", err);
    //  // alert("an error occurred" + err.message);
    toast('An error Occurred ' + err.response.data.message)
  }
}
