import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    borderRadius: '10px',
    color: '#f1f1f1',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      color: '#ffffff',
      borderRadius: '10px',
    },
    '&:focus': {
      color: '#536DFE',
    },
  },
  linkActive: {
    backgroundColor: '#ffffff',
    color: '#536DFE',
    borderRadius: '10px',
  },
  linkNested: {
    borderRadius: '10px',
    paddingLeft: 0,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      color: '#ffffff',
      borderRadius: '10px',
    },
    '&:focus': {
      color: '#ffffff',
    },
  },
  linkIcon: {
    marginRight: theme.spacing(1),
    color: '#ffffff',
    '&:hover': {
      color: '#ffffff',
    },

    width: 24,
    display: 'flex',
    justifyContent: 'center',
  },
  linkIconActive: {
    color: theme.palette.primary.main,
  },
  linkText: {
    padding: 0,

    fontSize: 14,
  },
  linkTextActive: {
    color: theme.palette.text.primary,
  },
  linkTextHidden: {
    opacity: 0,
  },
  nestedList: {
    paddingLeft: theme.spacing(2) + 5,
  },
  sectionTitle: {
    marginLeft: theme.spacing(4.5),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    height: 1,
    backgroundColor: '#D8D8D880',
  },
  nested: {
    paddingLeft: theme.spacing(4),
    textDecoration: 'none',
    borderRadius: '10px',
    color: '#f1f1f1',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      color: '#ffffff',
      borderRadius: '10px',
    },
    '&:focus': {
      color: '#ffffff',
    },
  },
}))
