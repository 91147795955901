import React, { useState } from 'react'
import { Grid, Button } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

// styles
import PropagateLoader from 'react-spinners/PropagateLoader'

import useStyles from './styles'
import { Paper } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import validator from 'validator'
import { registerUser } from '../user_add/redux/actions'

//custom columns in the table

//data dummy

const options = {
  filterType: 'checkbox',
  print: 'false',
  selectableRows: 'single',
  selectableRowsOnClick: 'true',
}

export default function Settings(props) {
  var classes = useStyles()
  const dispatch = useDispatch()

  var theme = useTheme()
  const user = useSelector((state) => state.login.user)
  const loading = useSelector((state) => state.addUser.loading)
  const bankId = useSelector((state) => state.login.user.BankID)

  const [button, setbutton] = useState(true)

  const [errorMessage, setErrorMessage] = useState('')

  const [newPass, setnewPass] = useState('')
  const [errorPass, setErrorPass] = useState(false)
  const [errorConfirm, setErrorConfirm] = useState(false)
  const [rePassword, setRePassword] = useState('')
  const [errorOldPass, setErrorOldPass] = useState(false)
  const [OldPass, setOldPass] = useState('')
  const [errorMessageRe, seterrorMessageRe] = useState('')

  //confirm old password
  const checkPassword = (value) => {
    if (validator.equals(value, user.PIN)) {
      setbutton(true)
      setErrorOldPass(false)
      setOldPass(value)
    } else {
      setbutton(false)
      setErrorOldPass(true)
      setOldPass(value)
    }
  }

  const validate = (value) => {
    if (
      validator.isStrongPassword(value, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setnewPass(value)
      setbutton(false)
      setErrorMessage('')
      setErrorPass(false)
    } else {
      setnewPass(value)
      setErrorMessage(
        'Password should be more than 8 characters, at least 1 lowercase and uppercase letters, at least 1 number and 1 symbol',
      )
      setErrorPass(true)
    }
  }

  //validateConfirmPassword
  const validateConfirmPassword = (value) => {
    if (validator.equals(value, newPass)) {
      setbutton(false)
      setErrorConfirm(false)
      setRePassword(value)
      seterrorMessageRe('')
    } else {
      setErrorConfirm(true)
      setRePassword(value)
      seterrorMessageRe(
        'Password does not Match, Tips; Retype the secure password above',
      )
    }
  }

  return (
    <>
      <div className={classes.root}>
        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={4}>
            <Typography variant="h6" gutterBottom>
              Change Password
            </Typography>
            <Grid container spacing={3} style={{ marginTop: 30 }}>
              <Grid item xs={12} sm={6}>
                {/* <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            onChange={handleChange('password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >

                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
        </FormControl> */}

                <TextField
                  required
                  id="firstName"
                  type="password"
                  variant="outlined"
                  name="firstName"
                  label="Current Password"
                  onChange={(e) => checkPassword(e.target.value)}
                  value={OldPass}
                  error={errorOldPass}
                  fullWidth
                  autoComplete="given-name"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* {errorOldPass} */}
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  variant="outlined"
                  id="email"
                  type="password"
                  name="email"
                  value={newPass}
                  onChange={(e) => validate(e.target.value)}
                  label="New Password"
                  disabled={errorOldPass || OldPass.length == 0}
                  error={errorPass}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <span style={{ color: 'red', fontSize: '12px' }}>
                  {errorMessage}
                </span>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  variant="outlined"
                  id="email"
                  name="email"
                  value={rePassword}
                  onChange={(e) =>
                    validateConfirmPassword(e.target.value)
                  }
                  type="password"
                  error={errorConfirm}
                  disabled={errorPass || newPass.length == 0}
                  label="Repeat New Password"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {
                  <span style={{ color: 'red', fontSize: '12px' }}>
                    {errorMessageRe}
                  </span>
                }
              </Grid>

              <Grid item xs={12} sm={6}>
                {!loading ? (
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    onClick={() =>
                      dispatch(
                        registerUser(
                          { ...user, bankId: bankId, PIN: newPass },
                          bankId,
                          'password',
                        ),
                      )
                    }
                    disabled={
                      errorOldPass ||
                      errorConfirm ||
                      errorPass ||
                      newPass.length == 0 ||
                      rePassword.length == 0 ||
                      OldPass.length == 0
                    }
                  >
                    Change Password
                  </Button>
                ) : (
                  <PropagateLoader color={'#536DFE'} size={10} />
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </div>
    </>
  )
}
