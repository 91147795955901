import axios from 'axios'
// const config = {
//   headers: { Authorization: `Bearer ${token}` }
// };

// const bodyParameters = {
//  key: "value"
// };

// Axios.post(
// 'http://localhost:8000/api/v1/get_token_payloads',
// bodyParameters,
// config
// ).then(console.log).catch(console.log);

// let user = JSON.stringify({
//   email: 'kamal@craftsilicon.com',
//   password: '123456'
// })
//read datas
export async function analyticsCall(id) {
  return await axios.post(
    `https://stats.little.bz/banks/alanytics/` + `${id}` + `?page=1`,
    {
      headers: {
        authorization: `${localStorage.getItem('id_token')}`,
        'Content-Type': 'application/json',
      },
    },
  )
}
