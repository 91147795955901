import React, { useEffect, useState } from 'react'
import {
  Button,
  IconButton,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import moment from 'moment'
// styles
import useStyles from './styles'
import MUIDataTable from 'mui-datatables'
import {
  Autorenew,
  BlockOutlined,
  Edit,
  VerifiedUser,
} from '@material-ui/icons'
import CustomToolbar from './CustomToolbar'
import { useDispatch, useSelector } from 'react-redux'
import { deleteUser, getUsers, setUser } from './redux/actions'
import SyncLoader from 'react-spinners/SyncLoader'
import { Dialog } from '@material-ui/core'
import { DialogTitle } from '@material-ui/core'
import { DialogContent } from '@material-ui/core'
import { DialogContentText } from '@material-ui/core'
import { DialogActions } from '@material-ui/core'
import html2canvas from 'html2canvas'
import jsPdf from 'jspdf'

//custom columns in the table

//data dummy



////

export default function Users(props) {
  var classes = useStyles()
  var theme = useTheme()
  const [open, setOpen] = useState(false)
  const [theUser, settheUser] = useState('')
  const [userSelected, setUserSelected] = useState({})

  const [userIdDelete, setUserId] = useState('')
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  //initialize dispatch
  const dispatch = useDispatch()
  let su = useSelector((state) => state.login.su)
  const users = useSelector((state) => state.users.users)
  const bankId = useSelector((state) => state.login.user.BankID)
  const loading = useSelector((state) => state.users.loading)
  const user = useSelector((state) => state.login.user)

  //component did mount/update
  const options = {
    filter: false,
    download: false,
    filterType: 'checkbox',
    print: 'false',
    selectableRows: false,
    downloadOptions: {
      filename: 'UsersData.csv',
      separator: ',',
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    elevation: 7,
    fixedHeader: false,
    customToolbar: () => {
      return user.Roles.includes('AU') ? <CustomToolbar /> : null
    },
  }

  const printPDF = () => {
    const domElement = document.getElementById('root')
    html2canvas(domElement, {
      onclone: (document) => {
        document.getElementById('print').style.visibility = 'hidden'
      },
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png')
      const pdf = new jsPdf()
      pdf.addImage(imgData, 'JPEG', 10, 10)
      pdf.save(`${new Date().toISOString()}.pdf`)
    })
  }

  useEffect(() => {
    dispatch(getUsers(user))
    console.log("user USERS", users.filter(
      (data) =>
        data.DeletedOn === null && data.UserID != 'LS4921',
    ))
    return () => {
      // cleanup
    }
  }, [])

  // DELETE USER DIALOGUE FUNCTIONS

  const handleClickOpen = (id) => {
    // dispatch(setUser( props.history, users[users.findIndex(p => p.UserID === value)]))
    settheUser(users[users.findIndex((p) => p.UserID === id)])
    setUserSelected(users[users.findIndex((p) => p.UserID === id)])
    setUserId(id)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  //handleDelete
  const userId = useSelector((state) => state.login.user.BankID)

  const handleDelete = (id) => {
    dispatch(deleteUser(id))
    let myId = theUser.UserID
    //blocked user id
    // alert(theUser.UserID)
    let blockedId = myId.substring(0, 1) + "D" + myId.substring(1, myId.length)

    // alert(JSON.stringify(userSelected))

    // dispatch(
    //   registerUser(
    //     userSelected,
    //     userId,
    //     'block',
    //   ))
    setOpen(false)
  }

  //active state columns
  const label = { inputProps: { 'aria-label': 'Switch demo' } }

  const columns = [
    {
      name: 'UserID',
      label: 'User ID',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) =>
        ( value.includes('@') ? <small>{value.split('*')[1]}</small> : value)
       
        ,
      },
    },

    // {
    //   name: 'Roles',
    //   label: 'Roles',
    //   options: {
    //     filter: false,
    //     sort: true,
    //   },
    // },

    {
      name: 'Name',
      label: 'User Name',
      options: {
        filter: false,
        sort: true,
       
      },
    },
    {
      name: 'MobileNumber',
      label: 'Phone Number',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'IDNumbre',
      label: 'ID No.',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'EMailID',
      label: 'Email',
      su: false,
      options: {
        filter: false,
        sort: true,
      },
    },

    {
      name: 'CreatedOn',
      label: 'Created On',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) =>
          value !== null ? (
            <small>{moment(value).format('MMM Do YY')}</small>
          ) : (
            <h1></h1>
          ),
      },
    },

    {
      name: 'UserID',
      label: 'Actions',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            // OPEN MODAL
            <div>
              <Tooltip title="Edit">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  disabled={user.UserID === value}
                  component="span"
                  onClick={() =>
                    dispatch(
                      setUser(
                        props.history,
                        users[
                          users.findIndex((p) => p.UserID === value)
                        ],
                      ),
                    )
                  }
                >
                  <Edit
                    style={{ marginRight: '5px' }}
                    fontSize="small"
                  />
                </IconButton>
              </Tooltip>

              <Tooltip title="Disable User">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  // disabled
                  
                  disabled={value === user.UserID}
                  onClick={() => handleClickOpen(value)}
                >
                  {value === user.UserID ? (
                    <BlockOutlined color="primary" fontSize="small" />
                  ) : (
                    <VerifiedUser fontSize="small" />
                  )}
                </IconButton>
                {/* <Switch {...label} defaultChecked /> */}
                {/* <FormGroup>
                  <FormControlLabel
                    control={<Switch defaultChecked size="small" />}
                    label="Label"
                    size="small"
                    disabled={value === user.UserID}
                  />
                </FormGroup> */}
              </Tooltip>

            </div>
          )
        },
      },
    },
  ]

  return (
    <>
      <>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{`Suspend ${theUser.Name}'s record`}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {`Are you sure you want to suspend ${theUser.Name}?`}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button autoFocus onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={() => handleDelete(userIdDelete)}
              color="primary"
              autoFocus
            >
              Suspend
            </Button>
          </DialogActions>
        </Dialog>
      </>

      <MUIDataTable
        title={
          !loading ? (
            <Button
              variant="text"
              color="primary"
              onClick={() => dispatch(getUsers(bankId))}
              className={classes.button}
              startIcon={<Autorenew />}
            >
              Registered Users
            </Button>
          ) : (
            <SyncLoader color={'#536DFE'} size={5} />
          )
        }
        data={users.filter(
          (data) =>
            data.DeletedOn === null && data.UserID != 'LS4921',
        )}
        columns={su ? columns : columns.filter((data) => !data.su)}
        options={{
          ...options,
          textLabels: {
            body: {
              noMatch: 'No records, Loading...',
            },
          },
        }}
      />
    </>
  )
}
