import {
  GET_BANKS,
  GET_BANKS_FAILED,
  GET_BANKS_SUCCESS,
} from './actionTypes'

const InitialState = {
  banks: [],
  loading: false,
  error: 'Loading...',
  fetched: false,
}

export default function getBanksReducer(
  state = InitialState,
  action,
) {
  switch (action.type) {
    //create agents

    case GET_BANKS:
      return {
        ...state,
        loading: true,
      }

    case GET_BANKS_SUCCESS:
      return {
        ...state,
        banks: action.response,
        loading: false,
        fetched: true,
      }

    case GET_BANKS_FAILED:
      return {
        ...state,
        error: action.response,
        loading: false,
        fetched: false,
      }

    default:
      return state
  }
}
