import {
  GET_LOANS,
  GET_LOANS_SUCCESS,
  GET_LOANS_FAILED,
  CHANGE_FILTER,
} from './actionTypes'

const InitialState = {
  loans: [],
  loading: false,
  error: '',
  fetched: false,
  open: false,
}

export default function loansReducer(state = InitialState, action) {
  switch (action.type) {
    //create agents
    case GET_LOANS:
      return {
        ...state,
        loading: true,
      }
    case CHANGE_FILTER:
      return {
        ...state,
        open: action.data,
      }
    case GET_LOANS_SUCCESS:
      return {
        ...state,
        loans: action.response,
        loading: false,
        fetched: true,
      }
    case GET_LOANS_FAILED:
      return {
        ...state,
        loans: [],
        error: action.response,
        loading: false,
      }

    default:
      return state
  }
}
