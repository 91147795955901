import { call, put } from 'redux-saga/effects'
// import history from './history';
//import { uistartLoading, uistopLoading } from "../Ui/actions";
// import { useHistory } from "react-router-dom";
import { repaymentsCall } from './api'
import { getRepaymentFailed, getRepaymentSuccess } from './actions'
import { toast } from 'react-toastify'

export function* repaymentsSaga(action) {
  try {
    // alert('happpening')
    const res = yield call(repaymentsCall, action.id)
    console.log(
      'repayments console//////////////////////',
      res.data.data,
    )
    // alert('yfgygygef')
    let response = res.data.data
    const getTotalAmount = (id) => {
      return response
        .filter((data) => data.LoanID === id)
        .reduce((a, b) => +a + +Math.abs(b.Amount), 0)
    }

    const getUnique = (array) => {
      var uniqueArray = []

      for (let i = 0; i < array.length; i++) {
        if (
          uniqueArray.some(({ LoanID }) => LoanID === array[i].LoanID)
        ) {
          console.log('contains')
        } else {
          let total = getTotalAmount(array[i].LoanID)
          uniqueArray.push({ ...array[i], Amount: total })
        }
      }
      return uniqueArray
    }
    let theRepayments = getUnique(res.data.data)
    yield put(getRepaymentSuccess(res.data.data))
  } catch (err) {
    yield put(getRepaymentFailed(err.message))
    console.log('repayment error', err)
    //  // alert("an error occurred" + err.message);
    toast(err.message)
  }
}
