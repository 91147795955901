export const RESUBMIT_APPLICATION = 'RESUBMIT_APPLICATION'
export const RESUBMIT_APPLICATION_SUCCESS =
  'RESUBMIT_APPLICATION_SUCCESS'
export const RESUBMIT_APPLICATION_FAILED =
  'RESUBMIT_APPLICATION_FAILED'

export const BLOCK_DRIVER = 'BLOCK_DRIVER'
export const BLOCK_DRIVER_SUCCESS = 'BLOCK_DRIVER_SUCCESS'
export const BLOCK_DRIVER_FAILED = 'BLOCK_DRIVER_FAILED'
export const RESET_MESSAGE = 'RESET_MESSAGE'

