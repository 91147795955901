import axios from 'axios'

//Get Users

export async function usersCall(data) {
  let the_id = data['bank.BankID'] === '66' ? 'sidian' : 'dtb'
  // alert()

  return await axios.get(`https://stats.little.bz/banks/users/63`, {
    headers: {
      authorization: `${localStorage.getItem('id_token')}`,
      'Content-Type': 'application/json',
    },
  })
}

//delete user

export async function deleteUser(id) {
  return await axios.delete(
    `https://stats.little.bz/banks/users/${id}`,
    {
      headers: {
        authorization: `${localStorage.getItem('id_token')}`,
        'Content-Type': 'application/json',
      },
    },
  )
}
