import {
  GET_LOANS,
  GET_LOANS_SUCCESS,
  GET_LOANS_FAILED,
  CHANGE_FILTER,
} from './actionTypes'

//login actions
export const getLoans = (data, querry) => {
  return {
    type: GET_LOANS,
    data,
    querry,
  }
}

//toggle filter view

//login actions
export const filterView = (data) => {
  return {
    type: CHANGE_FILTER,
    data,
  }
}

export const getLoansSuccess = (response) => {
  return {
    type: GET_LOANS_SUCCESS,
    response,
  }
}

export const getLoansFailed = (response) => {
  return {
    type: GET_LOANS_FAILED,
    response,
  }
}
