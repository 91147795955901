import axios from 'axios'

export async function repaymentsCall(data) {
  var thedata = JSON.stringify({
    country: 'KENYA',
    city: 'NAIROBI',
    user: 'brianmburu@dtb.com',
    fromdate: '01 Jan 2021',
    todate: '01 Dec 2021',
  })

  var config = {
    method: 'post',
    url: 'https://stats.little.bz/banks/loantrx/DTB',
    headers: {
      authorization:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJCYW5rSUQiOiI2MyIsIk5hbWUiOiJCcmlhbiBNYnVydSIsIlVzZXJJRCI6IkRUQjIwMjEiLCJFTWFpbElEIjoiYnJpYW5tYnVydUBkdGIuY29tIiwiTW9iaWxlTnVtYmVyIjoiMjU0NzU1NTMzMzIxIiwiUm9sZXMiOm51bGwsIklETnVtYnJlIjoiMzMzMzMzMzMiLCJDcmVhdGVkT24iOiIyMDIxLTA2LTIyVDEzOjI0OjIxLjM2M1oiLCJQSU4iOiJQYXNzd29yZEAxMjMiLCJEZWxldGVkT24iOiIyMDIxLTEwLTEzVDAwOjAwOjAwLjAwMFoiLCJiYW5rLkJhbmtDb2RlIjpudWxsLCJiYW5rLkJhbmtOYW1lIjoiRGlhbW9uZCBUcnVzdCBCYW5rICIsImJhbmsuQmFua0lEIjoiNjMiLCJiYW5rLkJhbmtUeXBlIjoiQkFOSyIsImJhbmsuSW1hZ2VVUkwiOm51bGwsImJhbmsuQ291bnRyeSI6IktFTllBIiwiYmFuay5EaXNhYmxlZCI6ZmFsc2UsImlhdCI6MTYzNDYzMzc5OX0.GUPwK_Qr2c_ecRXsRDIrxoscrtxCOcLDYzbBy9cresU',
      'Content-Type': 'application/json',
    },
    data: thedata,
  }

  return await axios(config)
}
