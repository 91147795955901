import axios from 'axios'

//notifications
export async function notificatopnCall(data) {
  return await axios.post(
    `https://stats.little.bz/banks/notifications/{89}?page=1`,
    JSON.stringify({
      sender: data.sender,
      recipient: data.recipient,
    }),
    {
      headers: {
        authorization: `${localStorage.getItem('id_token')}`,
        'Content-Type': 'application/json',
      },
    },
  )
}
