import React, { useEffect } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import classnames from 'classnames'

// styles
import useStyles from './styles'

// components
import Header from '../Header'
import Sidebar from '../Sidebar'

//pages
import Dashboard from '../../pages/dashboard'
// context
import { useLayoutState } from '../../context/LayoutContext'
import Drivers from '../../pages/drivers/Drivers'
import Driver from '../../pages/driver/Driver'
import Users from '../../pages/users/Users'
import Faq from '../../pages/faq/faq'
import Contact from '../../pages/contact/Contact'
import Documents from '../../pages/documents/Documents'
import AddUser from '../../pages/user_add/AddUser'
import Notifications from '../../pages/notifications/Notifications'
import Banks from '../../pages/banks/Banks'
import Loans from '../../pages/loans/Loans'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { getLoans } from '../../pages/loans/redux/actions'
import Settings from '../../pages/settings/Settings'
import Accounts from '../../pages/accounts/Accounts'
import Params from '../../pages/params/Params'
import RejectedLoans from '../../pages/rejected_loans/RejectedLoans'
import LoanRepayments from '../../pages/loan_repayment/LoanRepayment'
import Pdf from '../../pages/pdf/Pdf'
import EditParam from '../../pages/param_add/EditParam'
import Pending from '../../pages/pending_review/Pending'

const routes = [
  {
    id: '1',
    su: false,
    route: <Route path="/app/dashboard" component={Dashboard} />,
  },

  {
    id: '2',
    su: false,
    route: <Route path="/app/drivers" component={Drivers} />,
  },

  {
    id: '22',
    su: false,
    route: <Route path="/app/pdf" component={Pdf} />,
  },

  {
    id: '3',
    su: false,
    route: <Route path="/app/driver" component={Driver} />,
  },

  {
    id: '4',
    su: false,
    isBank: true,
    route: <Route path="/app/contact" component={Contact} />,
  },

  {
    id: '5',
    su: false,
    isBank: true,
    route: (
      <Route path="/app/notifications" component={Notifications} />
    ),
  },

  {
    id: '6',
    su: false,
    route: <Route path="/app/users" component={Users} />,
  },

  {
    id: '7',
    su: false,
    isBank: true,
    route: <Route path="/app/faq" component={Faq} />,
  },

  {
    id: '1',
    su: false,
    route: <Route path="/app/documents" component={Documents} />,
  },

  {
    id: '8',
    su: false,
    route: <Route path="/app/add_user" component={AddUser} />,
  },

  {
    id: '9',
    su: true,
    route: <Route path="/app/banks" component={Banks} />,
  },

  // {id: '10', su: true, route: <Route path="/app/add_bank" component={AddBank} />},

  {
    id: '11',
    su: false,
    route: <Route path="/app/loans" component={Loans} />,
  },
  {
    id: '12',
    su: false,
    route: <Route path="/app/settings" component={Settings} />,
  },

  {
    id: '15',
    su: false,
    route: <Route path="/app/loaninfo" component={Loans} />,
  },

  {
    id: '18',
    su: false,
    route: <Route path="/app/details" component={Loans} />,
  },

  {
    id: '19',
    su: false,
    route: <Route path="/app/approval" component={Loans} />,
  },
  {
    id: '20',
    su: false,
    route: <Route path="/app/decline" component={Loans} />,
  },
  {
    id: '21',
    su: false,
    route: <Route path="/app/accounts" component={Accounts} />,
  },
  {
    id: '22',
    su: false,
    route: <Route path="/app/params" component={Params} />,
  },
  {
    id: '32',
    su: false,
    route: <Route path="/app/rejected" component={RejectedLoans} />,
  },
  {
    id: '33',
    su: false,
    route: (
      <Route path="/app/repayments" component={LoanRepayments} />
    ),
  },
  {
    id: '13',
    su: false,
    route: <Route path="/app/edit_param" component={EditParam} />,
  },
  {
    id: '144',
    su: false,
    route: <Route path="/app/review" component={Pending} />,
  },
]

function Layout(props) {
  var classes = useStyles()
  // let su = useSelector(state => state.login.su);
  let bankId = useSelector((state) => state.layout.bankId)
  const user = useSelector((state) => state.login.user)

  // global
  var layoutState = useLayoutState()
  const dispatch = useDispatch()

  useEffect(() => {
    // dispatch(getBankCustomers(user , "all"));
    dispatch(getLoans(user, 'all'))
  }, [])

  const su = user.Roles === 'maker' ? true : false
  return (
    <div className={classes.root}>
      <Header history={props.history} />
      <Sidebar history={props.history} />
      <div
        className={classnames(classes.content, {
          [classes.contentShift]: layoutState.isSidebarOpened,
        })}
      >
        <div className={classes.fakeToolbar} />
        <ToastContainer />
        <Switch>
          {/* routes */}
          {!su
            ? routes
                .filter((item) => !item.su)
                .map((route) => route.route)
            : routes
                .filter((data) => !data.isBank)
                .map((route) => route.route)}
        </Switch>
      </div>
    </div>
  )
}

export default withRouter(Layout)
