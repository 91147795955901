import React, { useEffect } from 'react'

import { useTheme } from '@material-ui/styles'

// styles

import useStyles from './styles'
import MUIDataTable from 'mui-datatables'
import moment from 'moment'
import { getDeclinedLoans } from './redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import NumberFormat from 'react-number-format'

//custom columns in the table

const columns = [
  {
    name: 'LittleBankCustomer.FirstName',
    label: 'First Name',
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => (
        <small style={{ fontSize: '10' }}>{value}</small>
      ),
    },
  },
  {
    name: 'LittleBankCustomer.LastName',
    label: 'Last Name',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => (
        <small style={{ fontSize: '10' }}>{value}</small>
      ),
    },
  },
  {
    name: 'LittleBankCustomer.IDNumber',
    label: 'Id Number',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => (
        <small style={{ fontSize: '10' }}>{value}</small>
      ),
    },
  },
  {
    name: 'RejectedOn',
    label: 'Declined Date',
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) =>
        value !== null ? (
          <small>{moment(value).format('MMM Do YY')}</small>
        ) : (
          <h1></h1>
        ),
    },
  },
  {
    name: 'LoanAmount',
    label: 'Amount',
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => (
        <NumberFormat
          value={value}
          className="foo"
          displayType={'text'}
          thousandSeparator={true}
          prefix={''}
          renderText={(value, props) => <div {...props}>{value}</div>}
        />
      ),
    },
  },
  {
    name: 'RejectReason',
    label: 'Reason',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => (
        <small style={{ color: 'blue' }}>{value}</small>
      ),
    },
  },
]

//data dummy

const data = [
  {
    name: 'Nme ',
    company: 'James',
    city: '0726862144',
    state: '33316691',
    word: '',
  },
]

const options = {
  filterType: 'dropdown',
  print: 'false',
  // download: 'false',
  selectableRows: false,
  filter: false,
  data: 'false',
  downloadOptions: {
    fileName: 'DeclinedLoansLittle',
  },
}

//create the statements reports and payments report

export default function RejectedLoans(props) {
  const rejected = useSelector((state) => state.rejected.rejected)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getDeclinedLoans('dtb'))
  }, [])

  var classes = useStyles()
  var theme = useTheme()

  return (
    <>
      <MUIDataTable
        title={'Rejected Loans'}
        data={rejected}
        columns={columns}
        options={options}
      />
    </>
  )
}
