import {
  LOAN_REPAYMENT,
  LOAN_REPAYMENT_SUCCESS,
  LOAN_REPAYMENT_FAILED,
} from './actionTypes'

const InitialState = {
  repayment: [],
  loading: false,
  error: 'Loading...',
}

export default function repaymentsReducer(
  state = InitialState,
  action,
) {
  switch (action.type) {
    //create agents

    case LOAN_REPAYMENT:
      return {
        ...state,
        loading: true,
      }

    case LOAN_REPAYMENT_SUCCESS:
      return {
        ...state,
        repayment: action.response,
        loading: false,
      }

    case LOAN_REPAYMENT_FAILED:
      return {
        ...state,
        error: action.results,
        loading: false,
      }

    default:
      return state
  }
}
