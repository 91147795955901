import {
  GET_ANALYTICS,
  GET_ANALYTICS_FAILED,
  GET_ANALYTICS_SUCCESS,
  GET_DECLINED,
  GET_DECLINED_FAILED,
  GET_DECLINED_SUCCESS,
} from './actionTypes'

//get analytics data
export const getAnalytics = (id) => {
  return {
    type: GET_ANALYTICS,
    id,
  }
}

//getDeclinedLoans
export const getDeclinedLoans = (id) => {
  return {
    type: GET_DECLINED,
    id,
  }
}

export const getDeclinedLoansSuccess = (response) => {
  return {
    type: GET_DECLINED_SUCCESS,
    response,
  }
}

export const getDeclinedLoansFailed = (response) => {
  return {
    type: GET_DECLINED_FAILED,
    response,
  }
}

export const getAnalyticsSuccess = (response) => {
  return {
    type: GET_ANALYTICS_SUCCESS,
    response,
  }
}

export const getAnalyticsFailed = (response) => {
  return {
    type: GET_ANALYTICS_FAILED,
    response,
  }
}

//
