import {
  DEFAULT_USER,
  REGISTER_USER,
  REGISTER_USER_FAILED,
  REGISTER_USER_SUCCESS,
  SET_USER,
  RESET_STORE
} from './actionTypes'

const InitialState = {
  resetMessage:  '',
  user: {
    FullName: '',
    IdNumber: '',
    Email: '',
    PhoneNumber: '',
    company: '',
    role: {},
    bank: 'Little',
    roles: 'PENDING?1',
    bankId: '',
    userId: '',
    DeletedOn: null,
    VA: false,
    AS: false,
    CR: false,
    AL: false,
    DL: false,
    LR: false,
    AU: false,
    DU: false,
  },
  loading: false,
  RegisteredUser: {},
  error: null,
  success: false,
}

export default function AddUserReducer(state = InitialState, action) {
  switch (action.type) {
    //create agents

    case SET_USER:
      state.user[action.field] = action.data
      let userr = state.user
      return {
        ...state,
        user: userr,
        loading: false,
        error: null,
        success: false,
      }
    case REGISTER_USER:
      return {
        ...state,
        loading: true,
      }
      case RESET_STORE:
      return {
        ...state,
        success: false,
      }

    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: true,
        // resetMessage: action.response.user.PIN,
        RegisteredUser: action.response.user,
        user: {
          FullName: '',
          IdNumber: '',
          Email: '',
          PhoneNumber: '',
          company: '',
          role: 'admin',
          bank: 'Little',

          bankId: '',
          DeletedOn: null,
        },
      }

    case REGISTER_USER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.response,
        success: false,
      }
    case DEFAULT_USER:
      return {
        ...state,
        user: {
          FullName: action.data.Name,
          IdNumber: action.data.IDNumbre,
          Email: action.data.EMailID,
          PhoneNumber: action.data.MobileNumber,
          company: action.data.BankID,
          role: 'editor',
          bank: action.data.BankID,
          bankId: action.data.BankID,
          userId: action.data.UserID,
          DeletedOn: 1234,
        },
      }
    default:
      return state
  }
}
