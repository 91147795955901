import { call, put } from 'redux-saga/effects'
// import history from './history';
//import { uistartLoading, uistopLoading } from "../Ui/actions";
// import { useHistory } from "react-router-dom";
import { usersCall } from './api'
import { registerUserSuccess } from './actions'
import { toast } from 'react-toastify'
import { changePass } from '../../login/redux/actions'
import { getUsers } from './../../users/redux/actions';

//register user saga
// let history = useHistory();

export function* AddUserSaga(action) {
  try {
    // alert(action.action)
    // alert(JSON.stringify(action.data))
    console.log('salu', action.action, action.data)
    const res = yield call(usersCall, action.data, action.action)
//     dispatch(getUsers(user))
    // let message = action.data.reset === 'reset' ? 
    console.log('add user console))))))))))))', res.data)
    yield put(registerUserSuccess(res.data))
    yield put(getUsers(res.data))
    if (action.action === 'password_change') {
      action.history.push('/')
      yield put(changePass(false))
    } else {
      toast('success')
    }
  } catch (err) {
    // yield put(registerUserFailed(err.response.data.errors))
    console.log('amapiano', err)
    toast('An error Occurred ' + err.response.data.errors)
  }
}
