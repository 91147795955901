import {
  GET_BANKS,
  GET_BANKS_FAILED,
  GET_BANKS_SUCCESS,
} from './actionTypes'

//get analytics data
export const getBanks = (data) => {
  return {
    type: GET_BANKS,
    data,
  }
}

export const getBanksSuccess = (response) => {
  return {
    type: GET_BANKS_SUCCESS,
    response,
  }
}

export const getBanksFailed = (response) => {
  return {
    type: GET_BANKS_FAILED,
    response,
  }
}
