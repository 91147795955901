import React, { useEffect } from 'react'
import {
  Grid,
  Button,
  Tabs,
  Tab,
  Paper,
  Typography,
  Avatar,
  Badge,
  Card,
  CardHeader,
  IconButton,
  CardMedia,
  CardActions,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from '@material-ui/core'
import NumberFormat from 'react-number-format'

import MUIDataTable from 'mui-datatables'

import { useTheme } from '@material-ui/styles'
// styles

import useStyles from './styles'
import { withStyles } from '@material-ui/core/styles'
import {
  Accessibility,
  AccessTime,
  ArrowBack,
  AssignmentInd,
  CalendarToday,
  Call,
  ChromeReaderMode,
  Email,
  EventNote,
  Help,
  Visibility,
} from '@material-ui/icons'
//custom columns in the table
import Tooltip from '@material-ui/core/Tooltip'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import PuffLoader from 'react-spinners/PuffLoader'
import { Skeleton } from '@material-ui/lab'
import { TableContainer } from '@material-ui/core'
import { Table } from '@material-ui/core'
import { TableHead } from '@material-ui/core'
import { TableRow } from '@material-ui/core'
import { TableCell } from '@material-ui/core'
import { TableBody } from '@material-ui/core'
import { getRepayment } from '../loan_repayment/redux/actions'
import {
  blockDriver,
  resetMessage,
  resubmitDriver,
} from './redux/actions'
import html2canvas from 'html2canvas'
import Canvas2Image from 'canvas2image'
import jsPDF from 'jspdf'

const ref = React.createRef()
//import Skeleton from '@material-ui/lab/Skeleton';

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge)

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
  },
}))(Avatar)

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function Driver(props) {
  const user = useSelector((state) => state.login.user)

  const printProfile = () => {
    const input = document.getElementById('printable')
    html2canvas(input, {
      logging: true,
      letterRendering: 1,
      allowTaint: false,
      useCORS: true,
    }).then((canvas) => {
      var imgWidth = 200
      var pageHeight = 290
      var imgHeight = (canvas.height * imgWidth) / canvas.width
      var heightLeft = imgHeight
      const imgData = canvas.toDataURL('image/png')

      const pdf = new jsPDF('p', 'mm', 'a4')
      var position = 0
      // var heightLeft = imgHeight
      // pdf.addPage()
      pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight)
      pdf.save('customer.pdf')
    })
  }

  const printProfileButton = () => {
    const input = document.getElementById('printable')

    html2canvas(input, {
      onrendered: function (canvas) {
        var imageData = canvas.toDataURL('image/jpeg')
        var image = new Image()
        image = Canvas2Image.convertToJPEG(canvas)
        var doc = new jsPDF()
        doc.addImage(imageData, 'JPEG', 12, 10)
        var croppingYPosition = 1095
        var count = image.height / 1095

        for (var i = 1; i < count; i++) {
          doc.addPage()
          var sourceX = 0
          var sourceY = croppingYPosition
          var sourceWidth = image.width
          var sourceHeight = 1095
          var destWidth = sourceWidth
          var destHeight = sourceHeight
          var destX = 0
          var destY = 0
          var canvas1 = document.createElement('canvas')
          canvas1.setAttribute('height', destHeight)
          canvas1.setAttribute('width', destWidth)
          var ctx = canvas1.getContext('2d')
          ctx.drawImage(
            image,
            sourceX,
            sourceY,
            sourceWidth,
            sourceHeight,
            destX,
            destY,
            destWidth,
            destHeight,
          )
          var image2 = new Image()
          image2 = Canvas2Image.convertToJPEG(canvas1)
          var image2Data = image2.src
          doc.addImage(image2Data, 'JPEG', 12, 10)
          croppingYPosition += destHeight
        }
        var d = new Date()
          .toISOString()
          .slice(0, 19)
          .replace(/-/g, '')
        var filename = 'report_' + d + '.pdf'
        doc.save(filename)
      },
    })
  }
  var classes = useStyles()
  var theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const [showImage, setShowImage] = React.useState(false)
  const [theHeight, setHeight] = React.useState(0)

  const [expanded, setExpanded] = React.useState(false)
  const [currentDoc, setCurrent] = React.useState('')
  const [opena, setOpena] = React.useState(false)

  const [front, setIdBack] = React.useState(false)
  const [back, setIdFront] = React.useState(false)
  const TheMailDeal = []

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein }
  }

  const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
  ]

  const [value, setValue] = React.useState(0)
  const [valuee, setValuee] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
    // alert(newValue);
  }

  //  JSON.stringify()
  const handleChangee = (event, newValue) => {
    setValuee(newValue)
    // alert(newValue);
  }

  const handleChangeIndex = (index) => {
    setValue(index)
  }

  const handleClickOpena = () => {
    setOpena(true)
  }

  const handleClose = () => {
    setOpen(false)
    setOpena(false)
  }

  const handleClickOpen = (url) => {
    setOpen(true)
    setCurrent(url)
  }

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const repayments = useSelector(
    (state) => state.repayments.repayment,
  )

  const resubmit = useSelector((state) => state.resubmit)

  let currentCustomer = useSelector(
    (state) => state.layout.currentCustomer,
  )
  let customers = useSelector((state) => state.layout.drivers)

  const printDocument = ({ divId, title }) => {
    let mywindow = window.open(
      '',
      'PRINT',
      'height=650,width=900,top=100,left=150',
    )

    mywindow.document.write(`<html><head><title>${title}</title>`)
    mywindow.document.write('</head><body >')
    mywindow.document.write(
      document.getElementById('printme').innerHTML,
    )
    mywindow.document.write('</body></html>')

    mywindow.document.close() // necessary for IE >= 10
    mywindow.focus() // necessary for IE >= 10*/

    mywindow.print()
    mywindow.close()

    return true
  }

  // kyc user data to print
  let kycData = [
    {
      title: 'AccountOpeningId ',
      description: `${currentCustomer['LittleBankAccounts.AccountID']}`,
    },
    {
      title: 'Is New Customer',
      description: `Not set`,
    },
    {
      title: 'Is Us Person',
      description: `Not set`,
    },
    {
      title: 'accountOpeningState ',
      description: `ACCOUNT-CREATION`,
    },
    {
      title: 'accountStatusCode ',
      description: `COMPLETED`,
    },
    {
      title: 'Status Message',
      description: `Not set`,
    },
    {
      title: 'First Name',
      description: `${currentCustomer['FirstName']}`,
    },
    {
      title: 'Middle Name',
      description: `${currentCustomer['MiddleName']}`,
    },
    {
      title: 'Last Name',
      description: `${currentCustomer['LastName']}`,
    },
    {
      title: 'ID Number',
      description: `${currentCustomer['IDNumber']}`,
    },
    {
      title: 'Email',
      description: `${currentCustomer['EMailID']}`,
    },
    {
      title: 'Photo ID Match',
      description: `Not set`,
    },
    {
      title: 'Mobile Number',
      description: `${currentCustomer['MobileNumber']}`,
    },
    {
      title: 'Nationality ',
      description: `KE`,
    },
    {
      title: 'KRA Pin Number',
      description: `${currentCustomer['KRAPin']}`,
    },
    {
      title: 'ID Type',
      description: `National ID`,
    },
    {
      title: 'Date Of Birth',
      description: `${currentCustomer['BirthDate']}`,
    },
    {
      title: 'Gender ',
      description: `${currentCustomer['Gender']}`,
    },
    {
      title: 'Home Address',
      description: `${currentCustomer['PhysicalAddress']}`,
    },
    {
      title: 'Location ',
      description: `${currentCustomer['Location']}`,
    },
    {
      title: 'Branch ',
      description: `${currentCustomer['BranchName']}`,
    },
    {
      title: 'Postal Address',
      description: `${currentCustomer['PostalAddress']}`,
    },
    {
      title: 'Country Of Residence',
      description: `${currentCustomer['PhysicalAddress']}`,
    },
    {
      title: 'Town Residence',
      description: `${currentCustomer['Location']}`,
    },
    {
      title: 'Residential Address',
      description: `${currentCustomer['PhysicalAddress']}`,
    },
    {
      title: 'Monthly Income',
      description: `${currentCustomer['MonthlyIncome']}`,
    },
    {
      title: 'Place Of Birth',
      description: `${currentCustomer['BirthPlace']}`,
    },
    {
      title: 'Source Of Funds',
      description: `${currentCustomer['Designation']}`,
    },
    {
      title: 'Short Name',
      description: `Not set`,
    },
    {
      title: 'KYC Reference Number',
      description: `${currentCustomer['LittleBankAccounts.AccountToken']}`,
    },
    {
      title: 'Customer Number',
      description: `Not set`,
    },
    {
      title: 'Account Number',
      description: `${currentCustomer['LittleBankAccounts.AccountID']}`,
    },
    {
      title: 'World Check Name',
      description: `Not set`,
    },
    {
      title: 'IPRS Name',
      description: `Not set`,
    },
    {
      title: 'Account Class',
      description: `Not set`,
    },
    {
      title: 'Date Created',
      description: `${currentCustomer['LittleBankAccounts.CreatedOn']}`,
    },
    {
      title: 'Date Modified',
      description: `${currentCustomer['LittleBankAccounts.AccountID']}`,
    },
    {
      title: 'Updated By',
      description: `Not set`,
    },
    {
      title: 'Maker',
      description: `Not set`,
    },
    {
      title: 'Checker ',
      description: `Not set`,
    },
  ]

  //redux stuff the same

  useEffect(() => {
    currentCustomer.UniqueID == undefined
      ? props.history.goBack()
      : console.log('unauthorized')
    console.log('currentCustomer', currentCustomer)
    dispatch(getRepayment('dtb'))
    dispatch(resetMessage())

    // console.log("mimi",JSON.parse(currentCustomer['BankAccountStatuses.OutputData']));

    //   // //CustomerID
    //   // console.log("general", customers.filter(customer => customer.CustomerID === currentCustomer['CustomerID']));
  }, [])

  // useEffect(() => {
  //   dispatch(getRepayment('dtb'))
  // }, [])

  //documents

  const docs = [
    {
      id: 1,
      name: 'Selfie',
      url: currentCustomer.SelfieURL,
      loaded: showImage,
    },
    {
      id: 2,
      name: 'ID Front',
      url: currentCustomer.IDFrontImageURL,
      loaded: front,
    },
    {
      id: 3,
      name: 'ID Back',
      url: currentCustomer.IDBackImageURL,
      loaded: back,
    },
    // {id: 4,name: 'KRA', url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSH1A_k2QNRQi4PKLLcIUMTqzGpiozxu-ZARA&usqp=CAU"  },
    // {id: 5,name: 'Signature' , url: 'https://www.docsketch.com/assets/vip-signatures/muhammad-ali-signature-6a40cd5a6c27559411db066f62d64886c42bbeb03b347237ffae98b0b15e0005.svg' }
  ]

  const columns = [
    {
      name: 'LoanID',
      label: 'Loan Id',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <small style={{ fontSize: '10' }}>{value}</small>
        ),
      },
    },
    {
      name: 'FirstName',
      label: 'FirstName',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'ProductID',
      label: 'Product ID',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => value,
      },
    },
    // {
    //   name: 'InterestAmount',
    //   label: 'InterestAmount',
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRender: (value, tableMeta, updateValue) => (
    //       <small style={{ fontSize: '10' }}>{value}</small>
    //     ),
    //   },
    // },
    {
      name: 'TrxDate',
      label: 'Date',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) =>
          value !== null ? (
            <small>{moment(value).format('MMM Do YY')}</small>
          ) : (
            <h1></h1>
          ),
      },
    },
    {
      name: 'Amount',
      label: 'Amount',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <NumberFormat
            value={value}
            className="foo"
            displayType={'text'}
            thousandSeparator={true}
            prefix={''}
            renderText={(value, props) => (
              <div {...props}>{value}</div>
            )}
          />
        ),
      },
    },
    // {
    //   name: 'RejectReason',
    //   label: 'Reason',
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRender: (value, tableMeta, updateValue) => (
    //       <small style={{ color: 'blue' }}>{value}</small>
    //     ),
    //   },
    // },
  ]

  const data = []
  const options = {
    filterType: 'dropdown',
    print: 'false',
    // download: 'false',
    selectableRows: false,
    filter: false,
    data: 'false',
    downloadOptions: {
      fileName: 'DeclinedLoansLittle',
    },
  }
  //redux stuff details

  const dispatch = useDispatch()

  const handleBack = (e) => {
    props.history.goBack()
  }

  const resubmitCall = () => {}
  const [openReason, setOpenReason] = React.useState(false)
  const [Reason, setReason] = React.useState('')
  const [action, setAction] = React.useState('block')

  const handleClickOpenReason = (e) => {
    setOpenReason(true)
  }

  const handleCloseReason = () => {
    setOpenReason(false)
  }

  const handleSubmitReason = () => {
    // setOpenReason(false)
    let data = {
      mobile: currentCustomer.MobileNumber,
      reason: `${user.EMailID} * ${Reason}`,
      action: action,
      user: user.EMailID,
    }
    dispatch(blockDriver(data))
    setOpenReason(false)
  }

  const handleChangeReason = (e) => {
    console.log('secure')
    setReason(e.target.value)
  }

  return (
    <>
      <div>
        {/* <Button variant="outlined" onClick={handleClickOpen}>
          Open form dialog
        </Button> */}
        <Dialog open={openReason} onClose={handleCloseReason}>
          <DialogTitle>
            {action === 'block'
              ? `Block Customer`
              : `Unblock Customer`}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {action === 'block'
                ? `Describe the reason for blocking the account, The
              customer will not be able to access a loan after
              submission`
                : `Describe the reason for unblocking the account, The
                request will be supperviced by the admin`}
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Reason"
              type="text"
              fullWidth
              variant="standard"
              onChange={(e) => handleChangeReason(e)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseReason}>Cancel</Button>
            <Button onClick={handleSubmitReason}>Submit</Button>
          </DialogActions>
        </Dialog>
      </div>
      <Paper style={{ padding: 20, flexGrow: 1 }} id="pdfdiv">
        {/* Dialogue for viewing images*/}

        <Grid container direction="row" style={{ marginBottom: 10 }}>
          <Grid item sm={6}>
            {/* <Link to="/app/drivers"> */}
            <IconButton
              color="primary"
              aria-label="add to shopping cart"
              onClick={(e) => handleBack(e)}
            >
              <Tooltip title={'Back'}>
                <ArrowBack />
              </Tooltip>
            </IconButton>
            {/* </Link> */}
          </Grid>

          <Grid item sm={6}>
            <Paper square>
              <Tabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
                aria-label="disabled tabs example"
              >
                <Tab label="Details" />
                {currentCustomer['BankAccountStatuses.RowID'] !==
                  null && <Tab label="Logs" />}
                {/* <Tab label="Loan History" /> */}
              </Tabs>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {/* Profile picture section */}
          <Grid item xs={6}>
            <center>
              <Avatar
                src={currentCustomer.SelfieURL}
                style={{ width: '0px', height: '0px' }}
                onLoad={() => setShowImage(true)}
              />
              <Avatar
                src={currentCustomer.IDFrontImageURL}
                style={{ width: '0px', height: '0px' }}
                onLoad={() => setIdFront(true)}
              />

              <Avatar
                src={currentCustomer.IDBackImageURL}
                style={{ width: '0px', height: '0px' }}
                onLoad={() => setIdBack(true)}
              />

              {!showImage ? (
                <Avatar
                  style={{
                    width: '150px',
                    height: '150px',
                    backgroundColor: '#e2e2e2',
                  }}
                >
                  <PuffLoader color={'#536DFE'} />
                </Avatar>
              ) : (
                <Avatar
                  src={currentCustomer.SelfieURL}
                  style={{ width: '150px', height: '150px' }}
                />
              )}

              <br />
              <Typography variant="subtitle1">
                {currentCustomer.FirstName +
                  ' ' +
                  currentCustomer.MiddleName +
                  ' ' +
                  currentCustomer.LastName}
              </Typography>

              <Grid
                container
                direction="row"
                alignItems="center"
                justify="center"
                style={{ marginTop: 10 }}
              >
                <Grid item>
                  {/* <Chip label={ (currentCustomer.Status === "VALIDATEACCOUNT" ?  "OTP" : currentCustomer.Status === "CREATEACCOUNT" ?  "pending" : currentCustomer.Status === "IDVERIFY" ? 'iprs failed' : "created")} size="small" style={currentCustomer.Status === "CREATECUSTOMER" ? {backgroundColor: "gray", color: "#ffffff", width:'90px'} : {backgroundColor: "darkred", color: "#ffffff",width: '90px'}} /> */}
                  <Typography
                    variant="h6"
                    color="primary"
                    style={{ marginTop: '30px' }}
                  >
                    {currentCustomer[
                      'LittleBankAccounts.AccountID'
                    ] !== null
                      ? 'Account: ' +
                        currentCustomer[
                          'LittleBankAccounts.AccountID'
                        ]
                      : 'Account: '}
                  </Typography>
                </Grid>
              </Grid>

              {/* <Typography variant="subtitle1">
                {currentCustomer.FirstName}
              </Typography> */}
              <Grid style={{ marginBottom: '30px' }}>
                {!resubmit.loading && <h5>{resubmit.message}</h5>}
                {resubmit.loading && <h5>Loading...</h5>}
              </Grid>
              {currentCustomer['LittleBankAccounts.RowID'] ===
                null && user.Roles.includes('RA') &&(
                <Button
                  style={{
                    backgroundColor: '#eb0317',
                    color: '#ffffff',
                    marginRight: '30px',
                  }}
                  variant="contained"
                  onClick={(e) => {
                    if (
                      window.confirm(
                        `Confirm you want to resubmit ${currentCustomer.FirstName}'s application?`,
                      )
                    ) {
                      //resubmitDriver
                      dispatch(
                        resubmitDriver(currentCustomer.MobileNumber),
                      )

                      // Save it!
                      //make an api call to resubmit/call fetch customers/
                      // console.log('Thing was saved to the database.')
                    } else {
                      // Do nothing!
                      console.log(
                        'Thing was not saved to the database.',
                      )
                    }
                  }}
                >
                  Resubmit
                </Button>
              )}

              {!currentCustomer['LittleBankAccounts.Blocked'] && user.Roles.includes('BC') && (
                <Button
                  disabled={resubmit.response}
                  style={{
                    backgroundColor: resubmit.response
                      ? 'gray'
                      : '#eb0317',
                    color: '#ffffff',
                    marginRight: '30px',
                  }}
                  variant="contained"
                  onClick={(e) => {
                    if (
                      window.confirm(
                        `Confirm you want to block ${currentCustomer.FirstName}'s account from accessing loans?`,
                      )
                    ) {
                      //resubmitDriver
                      setAction('block')
                      handleClickOpenReason()

                      // Save it!
                      //make an api call to resubmit/call fetch customers/
                      // console.log('Thing was saved to the database.')
                    } else {
                      // Do nothing!
                      console.log('base.')
                    }
                  }}
                >
                  Block
                </Button>
              )}

              {currentCustomer['LittleBankAccounts.Blocked'] && user.Roles.includes('UC') && (
                <Button
                  // disabled={resubmit.response}
                  style={{
                    backgroundColor: resubmit.response
                      ? 'blue'
                      : '#eb0317',
                    color: '#ffffff',
                    marginRight: '30px',
                  }}
                  variant="contained"
                  onClick={(e) => {
                    if (
                      window.confirm(
                        `Confirm you want to unblock ${currentCustomer.FirstName}'s account?`,
                      )
                    ) {
                      //resubmitDriver
                      setAction('unblock')
                      handleClickOpenReason(e)

                      // Save it!
                      //make an api call to resubmit/call fetch customers/
                      // console.log('Thing was saved to the database.')
                    } else {
                      // Do nothing!
                      console.log('base.')
                    }
                  }}
                >
                  Unblock
                </Button>
              )}
              <button
                onClick={(e) => printProfile()}
                style={{ height: '35px' }}
              >
                Download Pdf
              </button>
              {/* <Pdf targetRef={ref} filename="Driver-kyc.pdf">
                {({ toPdf }) => (
                  <button onClick={toPdf}>Generate Pdf</button>
                )}
              </Pdf> */}

              <br />

              {/* <Typography variant="h6">+254 720 000 000</Typography> */}
            </center>
            {/* <h1>{'Download profile'}</h1> */}
          </Grid>
          {/* About area */}
          {value === 0 && (
            <Grid item xs={6}>
              {/* buttons */}

              {/* <Grid container direction="row" alignItems="center" style={{marginBottom: 20}}>
           </Grid> */}
              <Grid container direction="row" alignItems="center">
                <Help
                  style={{ height: 30, width: 30, marginRight: 10 }}
                />
                <span style={{ fontSize: 15, fontStyle: 'bold' }}>
                  <b>About</b>
                </span>
              </Grid>

              {/* registration date */}

              <Grid
                container
                direction="row"
                alignItems="center"
                style={{ marginTop: 11 }}
              >
                <AccessTime
                  color="primary"
                  style={{ height: 20, width: 20, marginRight: 5 }}
                />
                <span style={{ fontSize: 12, width: '155px' }}>
                  <b>Created At:</b>
                </span>
                <span>
                  {''}
                  {moment(currentCustomer.CreatedOn).format(
                    'MMMM Do YYYY, h:mm:ss a',
                  )}
                </span>
              </Grid>

              {/* name */}

              <Grid
                container
                direction="row"
                alignItems="center"
                style={{ marginTop: 11 }}
              >
                <AssignmentInd
                  color="primary"
                  style={{ height: 20, width: 20, marginRight: 5 }}
                />
                <span style={{ fontSize: 12, width: '155px' }}>
                  <b>Official Name:</b>
                </span>
                <span>
                  {' '}
                  {currentCustomer.FirstName +
                    ' ' +
                    currentCustomer.MiddleName +
                    ' ' +
                    currentCustomer.LastName}
                </span>
              </Grid>

              {/* County */}

              <Grid
                container
                direction="row"
                alignItems="center"
                style={{ marginTop: 11 }}
              >
                <Accessibility
                  color="primary"
                  style={{ height: 20, width: 20, marginRight: 5 }}
                />
                <span style={{ fontSize: 12, width: '155px' }}>
                  <b>Gender:</b>
                </span>
                <span> {currentCustomer.Gender}</span>
              </Grid>

              {/* KRA PIN No. KRAPin */}

              <Grid
                container
                direction="row"
                alignItems="center"
                style={{ marginTop: 11 }}
              >
                <EventNote
                  color="primary"
                  style={{ height: 20, width: 20, marginRight: 5 }}
                />
                <span style={{ fontSize: 12, width: '155px' }}>
                  <b>KRA PIN:</b>
                </span>
                <span> {currentCustomer.KRAPin}</span>
              </Grid>

              {/* Phone No. */}

              <Grid
                container
                direction="row"
                alignItems="center"
                style={{ marginTop: 11 }}
              >
                <Call
                  color="primary"
                  style={{ height: 20, width: 20, marginRight: 5 }}
                />
                <span style={{ fontSize: 12, width: '155px' }}>
                  <b>Phone Number:</b>
                </span>
                <span> {currentCustomer.MobileNumber}</span>
              </Grid>

              {/* Branch code */}

              <Grid
                container
                direction="row"
                alignItems="center"
                style={{ marginTop: 11 }}
              >
                <ChromeReaderMode
                  color="primary"
                  style={{ height: 20, width: 20, marginRight: 5 }}
                />
                <span style={{ fontSize: 12, width: '155px' }}>
                  <b>ID Number</b>
                </span>
                <span> {currentCustomer.IDNumber}</span>
              </Grid>

              {/* Approved  documents */}

              <Grid
                container
                direction="row"
                alignItems="center"
                style={{ marginTop: 11 }}
              >
                <CalendarToday
                  color="primary"
                  style={{ height: 20, width: 20, marginRight: 5 }}
                />
                <span style={{ fontSize: 12, width: '155px' }}>
                  <b>Date of Birth </b>
                </span>
                <span> {currentCustomer.BirthDate}</span>
              </Grid>

              {/* Account email code */}

              <Grid
                container
                direction="row"
                alignItems="center"
                style={{ marginTop: 11 }}
              >
                <Email
                  color="primary"
                  style={{ height: 20, width: 20, marginRight: 5 }}
                />
                <span style={{ fontSize: 12, width: '155px' }}>
                  <b>Email: </b>
                </span>
                <span> {currentCustomer.EMailID}</span>
              </Grid>
            </Grid>
          )}
          {value === 1 && (
            <Grid item xs={6}>
              <TableContainer component={Paper}>
                <Table
                  className={classes.table}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Event</TableCell>
                      <TableCell>Status</TableCell>
                      {/* <TableCell>Reason</TableCell> */}
                      <TableCell align="right">Message</TableCell>
                      <TableCell align="right">Time</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentCustomer[
                      'BankAccountStatuses.OutputData'
                    ] !== 'No Response' &&
                      customers
                        .filter(
                          (customer) =>
                            customer.MobileNumber ===
                            currentCustomer['MobileNumber'],
                        )
                        .filter(
                          (data) =>
                            data['BankAccountStatuses.OutputData'] !==
                            'No Response',
                        )
                        .map((item) => (
                          <TableRow key={item.UniqueID}>
                            <TableCell align="left">
                              {JSON.parse(
                                item[
                                  'BankAccountStatuses.OutputData'
                                ],
                              ).message === 'Blacklisted'
                                ? 'Name Check'
                                : ''}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{
                                color:
                                  JSON.parse(
                                    item[
                                      'BankAccountStatuses.OutputData'
                                    ],
                                  ).message === 'SUCCESS'
                                    ? 'green'
                                    : 'red',
                              }}
                            >
                              {JSON.parse(
                                item[
                                  'BankAccountStatuses.OutputData'
                                ],
                              ).message === 'SUCCESS'
                                ? 'SUCCESS'
                                : 'FAILED'}
                            </TableCell>
                            {/* <TableCell
                              component="th"
                              scope="row"
                              style={{
                                color:
                                  JSON.parse(
                                    item[
                                      'BankAccountStatuses.OutputData'
                                    ],
                                  ).message === 'SUCCESS'
                                    ? 'green'
                                    : 'red',
                              }}
                            >
                              {JSON.parse(
                                item[
                                  'BankAccountStatuses.OutputData'
                                ],
                              ).message === 'SUCCESS'
                                ? 'Account Created'
                                : JSON.parse(
                                    item[
                                      'BankAccountStatuses.OutputData'
                                    ],
                                  ).payload !== null
                                ? JSON.parse(
                                    item[
                                      'BankAccountStatuses.OutputData'
                                    ],
                                  ).payload.CustomerAllowed ||
                                  '' +
                                    ' ' +
                                    JSON.parse(
                                      item[
                                        'BankAccountStatuses.OutputData'
                                      ],
                                    ).payload.ViolationCount
                                : ''}
                            </TableCell> */}

                            <TableCell align="right">
                              {JSON.parse(
                                item[
                                  'BankAccountStatuses.OutputData'
                                ],
                              ).message || ''}{' '}
                              {JSON.parse(
                                item[
                                  'BankAccountStatuses.OutputData'
                                ],
                              ).message === 'Blacklisted'
                                ? `ViolationCount: ${
                                    JSON.parse(
                                      item[
                                        'BankAccountStatuses.OutputData'
                                      ],
                                    ).payload.ViolationCount
                                  }`
                                : ''}
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Grid>

        {/* Logs Area */}

        {/* Documents and statement area section */}
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            spacing="3"
            style={{ backgroundColor: '#f3f1ef', marginTop: 20 }}
          >
            {/* options button */}
            <Grid container direction="row">
              <Grid item xs="12" style={{ marginBottom: '20px' }}>
                <Paper square>
                  <Tabs
                    value={valuee}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChangee}
                    aria-label="disabled tabs example"
                  >
                    <Tab label="Documents" />
                    <Tab label="Statement" />
                    {/* <Tab label="Loan History" /> */}
                  </Tabs>
                </Paper>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              spacing={2}
              // justify="center"
            >
              {valuee === 0 &&
                docs.map((doc) => (
                  <Grid key={doc.RowID} item>
                    <Card className={classes.root}>
                      <CardHeader
                        // action={
                        //   // <IconButton aria-label="settings">
                        //   //   {doc.status === 0 ? <Check size='small' style={{color: 'green'}}/> : doc.status === 1 ? <AccessTime size='small'/> :
                        //   //   <Close size='small' style={{color: 'red'}}/>}
                        //   // </IconButton>
                        // }
                        subheader={doc.name}
                      />
                      {!doc.loaded ? (
                        <Skeleton
                          variant="rect"
                          className={classes.media}
                        />
                      ) : (
                        <CardMedia
                          className={classes.media}
                          image={doc.url}
                        />
                      )}

                      {
                        //  <CardContent>
                        //   <Typography variant="body2" color="textSecondary" component="p">
                        //     This impressive paella is a perfect party dish and a fun meal to cook together with your
                        //     guests. Add 1 cup of frozen peas along with the mussels, if you like.
                        //   </Typography>
                        // </CardContent>
                      }

                      <CardActions>
                        {/* <IconButton aria-label="share">
                                    <ShareIcon />
                                  </IconButton> */}
                        <a
                          href={doc.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          download
                        >
                          <IconButton
                            aria-label="View"
                            disabled={!doc.loaded}
                          >
                            <Visibility />
                          </IconButton>
                        </a>
                        {/* <Link to='/app/documents'>
                                  <IconButton
                                    aria-label="View"
                                  >
                                    <Visibility />
                                  </IconButton>
                                  </Link> */}
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              {/* <small style={{color: 'blue', marginLeft: 10}}>Download all</small> */}
              {valuee === 1 && (
                <>
                  <Grid item xs="12">
                    <MUIDataTable
                      title="Statement"
                      data={repayments.filter(
                        (data) =>
                          data.MobileNumber ===
                          currentCustomer.MobileNumber,
                      )}
                      columns={columns}
                      options={options}
                    />
                  </Grid>
                </>
              )}
              {valuee === 2 && (
                <Grid>
                  <div ref={ref}>
                    <h1>Hello CodeSandbox</h1>
                    <h2>Start editing to see some magic happen!</h2>
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSO0CUH1TfGkpsNtzjKZdzoABXCeAbUS65TWO9720r-xAn8qBlkCBDdK0FIjRCYyPKc5Xo&usqp=CAU"
                      style={{ width: 100, height: 100 }}
                    />
                  </div>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      {/* dialogues */}

      {/* Dialogue decline*/}
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {'Decline ' + currentCustomer.FirstName + '`s records'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to decline this document?
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Comment"
              multiline
              variant="outlined"
              type="text"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleClose} color="primary" disabled>
              Decline
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <div style={{ visibility: 'hidden' }}>
        <div
          style={{
            backgroundColor: '#f2f2f2',

            width: '100%',
            padding: '20px',
          }}
          id="printable"
        >
          {/* top section */}
          {/* <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            fontSize: 30,
            fontWeight: 'bold',
            marginTop: '5px',
            backgroundColor: '#ffffff',
          }}
        >
          DTB -Kenya - LITTLE APP CUSTOMERS
        </div> */}

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',

              // fontSize: 30,
              fontWeight: 'bold',
              marginTop: '5px',
              backgroundColor: '#ffffff',
            }}
          >
            Digital Account Customer Details - Brian Odanga Mburu
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              marginTop: '5px',
              // fontSize: 30,
              fontWeight: 'bold',
              marginBottom: '30px',
              backgroundColor: '#ffffff',
            }}
          >
            <i>
              <small>
                Generated By bmburu on Fri, 08 Oct 2021 12:14:19
              </small>
            </i>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              marginTop: '5px',
              // fontSize: 30,
              fontWeight: 'bold',
              marginBottom: '30px',
              backgroundColor: '#ffffff',
            }}
          >
            <style>
              {`
         table th, table td{
          text-align: center;
          }
          tbody tr:nth-child(even) {
          background: #e8e7e1;
          }
        `}
            </style>
            {kycData.map((data, index) => (
              <div
                key={data.title}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  backgroundColor:
                    index % 2 == 0 ? '#9f9f9f' : '#fefefe',
                  // alignItems: 'center',
                  // justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    marginRight: '30px',
                    marginLeft: '10px',
                    padding: '5px',
                    width: '300px',
                  }}
                >
                  {data.title}
                </div>
                <div
                  style={{
                    marginRight: '30px',
                    marginLeft: '10px',
                    padding: '5px',
                  }}
                >
                  {data.description}
                </div>
              </div>
            ))}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              marginTop: '5px',
              // fontSize: 30,
              fontWeight: 'bold',
              marginBottom: '30px',
              backgroundColor: '#ffffff',
              justifyContent: 'space-between',
            }}
          >
            <img src={docs[0].url} style={{ height: '200px' }} />
            <img src={docs[1].url} style={{ height: '200px' }} />
            <img src={docs[2].url} style={{ height: '200px' }} />
          </div>
        </div>
      </div>
      {/* Dialogue accept */}

      <div>
        <Dialog
          open={opena}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {'Approve ' + currentCustomer.FirstName + '`s records'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to Approve this?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleClose} color="primary">
              Approve
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  )
}
