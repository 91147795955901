import axios from 'axios'

//loans 63
//user["bank.BankID"]
// `https://stats.little.bz/banks/customers/${the_id}?offset=5&page=1`,

export async function customersCall(data, page) {
  console.log('.....>>>>>>>>--->>>>', data)
  let the_id = data['user']['bank.BankID'] === '66' ? 'sidian' : 'dtb'
  return await axios.get(
    `https://stats.little.bz/banks/customers/dtb`,
    {
      headers: {
        authorization: `${localStorage.getItem('id_token')}`,
      },
    },
  )
}
