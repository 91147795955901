import React from 'react'
import { Paper } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { useTheme } from '@material-ui/core/styles'
import thedrawer from './back.png'
// styles

import useStyles from './styles'
import { AccessAlarms, Email, LocationCity } from '@material-ui/icons'

export default function Contact(props) {
  var classes = useStyles()
  var theme = useTheme()

  return (
    <>
      <Paper
        style={{
          height: '90%',
          padding: 70,
          backgroundImage: `url("${thedrawer}")`,
          color: '#ffffff',
        }}
      >
        <center>
          <LocationCity />
          <br />
          <Typography variant="h6">
            Craft Silicon Ltd, Musa Gitau Road, Nairobi
          </Typography>
          <br />
          <AccessAlarms />
          <br />
          {/* <Typography variant={"h5"}>Working Hours:</Typography> */}
          <Typography variant="h6">
            Mon - Fri: 8:30 am - 5:30 pm <br />
            Sat: 9:00 am - 1:00 pm
          </Typography>
          <br />
          {/* <Phone/>    <Typography  variant='h6'>+254 777 777 777</Typography> */}
          <br />
          <Email />{' '}
          <Typography variant="h6">
            customer-support@little.bz
          </Typography>
          <br />
          <br />
          <i>
            Get support from our Little Team whenever you need
            assistance.
          </i>
        </center>
      </Paper>
    </>
  )
}
