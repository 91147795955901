import { call, put } from 'redux-saga/effects'

import { notificatopnCall } from './api'
import {
  getNotifications,
  getNotificationsFailed,
  getNotificationsSuccess,
} from './actions'
import { ToastContainer, toast } from 'react-toastify'

//login saga
// let history = useHistory();

export function* getNotificationSaga(action) {
  try {
    const res = yield call(notificatopnCall, action.data)
    console.log('get notifications console', res)
  } catch (err) {
    yield put(getNotificationsFailed(err.message))
    //   console.log("Login error", err);
    //  // alert("an error occurred" + err.message);
    //toast('An error Occurred '+ err.message)
  }
}
