import { call, put } from 'redux-saga/effects'
import { banksCall } from './api'
import { getBanksFailed, getBanksSuccess } from './actions'
import { ToastContainer, toast } from 'react-toastify'

export function* getBanksSaga(action) {
  try {
    const res = yield call(banksCall, action.data)
    console.log('banks console', res)
    yield put(getBanksSuccess(res.data.users))
  } catch (err) {
    yield put(
      getBanksFailed(err.response.data.message || err.message),
    )
    //   console.log("Login error", err);
    //  // alert("an error occurred" + err.message);
    toast('An error Occurred ' + err.response.data.message)
  }
}
