//login constants

//login action types
export const SET_USER = 'SET_USER'
export const REGISTER_USER = 'REGISTER_USER'
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS'
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED'
export const DEFAULT_USER = 'DEFAULT_USER'

// RESET_STORE

export const RESET_STORE = 'RESET_STORE'

