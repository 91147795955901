export default {
  notifications: [
    {
      id: '0',
      title: 'Driver Name/doc',
      from: 'ADMIN ~ jefjie@email.com',
      to: 'reviewerr@gmail.com',
      comment: 'the fhuhfreuhrg gfurhurh reuhgiureuy jgfr',
      time: 'Today ~ 10:00Am',
    },
    {
      id: '01',
      title: 'Driver Name/doc',
      from: 'ADMIN ~ jefjie@email.com',
      to: 'reviewerr@gmail.com',
      comment: 'the fhuhfreuhrg gfurhurh reuhgiureuy jgfr',
      time: 'Today ~ 10:00Am',
    },
    {
      id: '02',
      title: 'Driver Name/doc',
      from: 'ADMIN ~ jefjie@email.com',
      to: 'reviewerr@gmail.com',
      comment: 'the fhuhfreuhrg gfurhurh reuhgiureuy jgfr',
      time: 'Today ~ 10:00Am',
    },
  ],
}
