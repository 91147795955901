import {
  GET_BLOCKED,
  GET_BLOCKED_FAILED,
  GET_BLOCKED_SUCCESS,
  GET_LIST_PARAMS,
  GET_LIST_PARAMS_FAILED,
  GET_LIST_PARAMS_SUCCESS,
  LIST_BLOCKED,
  LIST_BLOCKED_FAILED,
  LIST_BLOCKED_SUCCESS,
  UNBLOCK_DRIVER,
  UNBLOCK_DRIVER_FAILED,
  UNBLOCK_DRIVER_SUCCESS,
} from './actionTypes'

//get analytics data

export const listBlocked = (data) => {
  return {
    type: GET_BLOCKED,
    data,
  }
}

export const listBlockedSuccess = (results) => {
  return {
    type: GET_BLOCKED_SUCCESS,
    results,
  }
}

export const listBlockedFailed = (results) => {
  return {
    type: GET_BLOCKED_FAILED,
    results,
  }
}

//LOAN PPARAM CHANGES
export const paramChanges = (data) => {
  return {
    type: GET_LIST_PARAMS,
    data,
  }
}

export const paramChangesSuccess = (results) => {
  return {
    type: GET_LIST_PARAMS_SUCCESS,
    results,
  }
}

export const paramChangesFailed = (results) => {
  return {
    type: GET_LIST_PARAMS_FAILED,
    results,
  }
}

//UNBLOCK DRIVER

export const unblockDriver = (data) => {
  return {
    type: UNBLOCK_DRIVER,
    data,
  }
}

export const unblockDriverSuccess = (results) => {
  return {
    type: UNBLOCK_DRIVER_SUCCESS,
    results,
  }
}

export const unblockDriverFailed = (results) => {
  return {
    type: UNBLOCK_DRIVER_FAILED,
    results,
  }
}

// LIST BLOCKED customers

export const listBlockedCustomers = (data) => {
  return {
    type: LIST_BLOCKED,
    data,
  }
}

export const listBlockedCustomersSuccess = (results) => {
  return {
    type: LIST_BLOCKED_SUCCESS,
    results,
  }
}

export const listBlockedCustomersFailed = (results) => {
  return {
    type: LIST_BLOCKED_FAILED,
    results,
  }
}

