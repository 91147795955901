//login constants

//login action types

//export const SET_USER = "SET_USER";
export const GET_USERS = 'GET_USERS'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_FAILED = 'GET_USERS_FAILED'

//set user redux

export const SET_USER_EDIT = 'SET_USER_EDIT'
export const SET_USER_SUCCESS = 'SET_USER_SUCCESS'
export const SET_USER_FAILED = 'SET_USER_FAILED'

export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED'
