import { call, put } from 'redux-saga/effects'
// import history from './history';
//import { uistartLoading, uistopLoading } from "../Ui/actions";
// import { useHistory } from "react-router-dom";

import {
  listBlockeCall,
  listParamsCall,
  unblockdriverCall,
  blockedDriversCall
} from './api'
import {
  listBlocked,
  listBlockedCustomersFailed,
  listBlockedCustomersSuccess,
  listBlockedFailed,
  listBlockedSuccess,
  paramChangesFailed,
  paramChangesSuccess,
  unblockDriverFailed,
  unblockDriverSuccess,
} from './actions'
import { toast } from 'react-toastify'

export function* listBlockedSaga(action) {
  try {
    const res = yield call(listBlockeCall, action.data)
    console.log('get blocked users too unblock console', res)
    // toast('success')listBlocked
    // alert(JSON.stringify(res.data.data))
    yield put(listBlockedSuccess(res.data.data))
  } catch (err) {
    yield put(listBlockedFailed(err.message))
    console.log('>>>>', err)
    // toast('Something went wrong ' + err.message)
  }
}

// get the list of param changes

export function* listParamsChangesSaga(action) {
  try {
    const res = yield call(listParamsCall, action.data)
    console.log('get params too su', res.data.data)
    // toast('success')
    yield put(paramChangesSuccess(res.data.data))
  } catch (err) {
    yield put(paramChangesFailed(err.message))
    console.log('>>>>', err)
    // toast(err.message)
  }
}

export function* unblockDriver(action) {
  try {
    const res = yield call(unblockdriverCall, action.data)
    console.log('get params too su', res)
    toast('Done')
    yield put(unblockDriverSuccess(res.data.data))
    yield put(listBlocked('user'))
  } catch (err) {
    // yield put(listBlocked('user'))
    yield put(unblockDriverFailed(err.message))
    console.log('>>>>', err)
    toast(err.message)
  }
}

// get blocked drivers

export function* blockedDriversSaga(action) {
  try {
    const res = yield call(blockedDriversCall, action.data)
    console.log('get params too su', res)
    // toast('Done')
    yield put(listBlockedCustomersSuccess(res.data.data))
    // yield put(listBlocked('user'))
  } catch (err) {
    // yield put(listBlocked('user'))
    yield put(listBlockedCustomersFailed(err.message))
    console.log('>>>>', err)
    // toast(err.message)
  }
}
