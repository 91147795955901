import {
  GET_NOTIFICATION,
  GET_NOTIFICATION_FAILED,
  GET_NOTIFICATION_SUCCESS,
} from './actionTypes'

const InitialState = {
  notifications: [],
  loading: false,
  error: 'No data',
  fetched: false,
}

export default function getNotificationReducer(
  state = InitialState,
  action,
) {
  switch (action.type) {
    //create agents

    case GET_NOTIFICATION:
      return {
        ...state,
        loading: true,
        fetched: false,
      }

    case GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: action.response,
        fetched: true,
        loading: false,
      }
    case GET_NOTIFICATION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.response,
        fetched: false,
      }
    default:
      return state
  }
}
