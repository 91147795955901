import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { useDispatch, useSelector } from 'react-redux'
import useStyles from './styles'
import { FilterList } from '@material-ui/icons'
import { toggleFilter } from './redux/actions'

const defaultToolbarStyles = {
  iconButton: {},
}

export default function CustomToolbar(props) {
  const handleClick = () => {
    alert('Dispatch')
  }

  const dispatch = useDispatch()
  const open = useSelector((state) => state.driversReducer.isOpen)

  // useEffect(() => {

  //     dispatch(toggleFilter(!open));

  //   }, []);

  var classes = useStyles()

  return (
    <>
      <Tooltip title={'Filter Customers'}>
        <IconButton
          className={classes.iconButton}
          onClick={(e) => dispatch(toggleFilter(!open))}
        >
          <FilterList className={classes.deleteIcon} />
        </IconButton>
      </Tooltip>
    </>
  )
}
