import React, { useEffect, useState } from 'react'
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Button,
  AppBar,
  Tabs,
  Tab,
  Paper,
  Avatar,
  IconButton,
} from '@material-ui/core'
import SyncLoader from 'react-spinners/SyncLoader'

import { useTheme } from '@material-ui/styles'

// styles

import useStyles from './styles'
import MUIDataTable from 'mui-datatables'
import data from './data'
import { Autorenew, Visibility } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getNotifications } from './redux/actions'
import { Skeleton } from '@material-ui/lab'

export default function Notifications(props) {
  var classes = useStyles()
  var theme = useTheme()

  //dispatch plain actions in hooks
  const dispatch = useDispatch()

  //access store for the data
  const notifications = useSelector((state) => state.notifications)
  let su = useSelector((state) => state.login.su)
  let bankId = useSelector((state) => state.layout.bankId)

  useEffect(() => {
    dispatch(getNotifications({}))
    return () => {
      //cleanup
    }
  }, [])

  return (
    <div className={classes.root}>
      {/* <h5>No Notifications</h5> */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {!notifications.loading ? (
            <Button
              variant="text"
              color="primary"
              onClick={() => dispatch(getNotifications(bankId))}
              className={classes.button}
              startIcon={<Autorenew />}
            >
              0 Notifications
            </Button>
          ) : (
            <SyncLoader color={'#536DFE'} size={5} />
          )}
          {notifications.loading ? (
            <>
              <Skeleton
                variant="rect"
                className={classes.paper}
                height={70}
              />
              <Skeleton
                variant="rect"
                className={classes.paper}
                height={70}
              />
              <Skeleton
                variant="rect"
                className={classes.paper}
                height={70}
              />
              <Skeleton
                variant="rect"
                className={classes.paper}
                height={70}
              />
              <Skeleton
                variant="rect"
                className={classes.paper}
                height={70}
              />
            </>
          ) : null}
          {notifications.fetched
            ? data.notifications.map((dat) => (
                <Paper className={classes.paper}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="center"
                  >
                    {/* Avatar */}
                    <Grid item xs={1} style={{ textAlign: 'center' }}>
                      <Avatar className={classes.avater}>TK</Avatar>
                    </Grid>

                    <Grid item xs={2} style={{ textAlign: 'center' }}>
                      {dat.title}
                    </Grid>

                    <Grid item xs={3} className={classes.from}>
                      {dat.from}
                    </Grid>

                    <Grid
                      item
                      xs={3}
                      style={{
                        textAlign: 'center',
                        color: '#000000',
                        fontSize: 14,
                      }}
                    >
                      <i>"{dat.comment.substring(0, 45)} ..."</i>
                    </Grid>

                    <Grid
                      item
                      xs={1}
                      style={{ textAlign: 'center', fontSize: 12 }}
                    >
                      {dat.time}
                    </Grid>

                    <Grid item xs={2} style={{ textAlign: 'center' }}>
                      <Link to="/app/driver">
                        <IconButton aria-label="View" size="small">
                          <Visibility />
                        </IconButton>
                      </Link>
                    </Grid>
                  </Grid>
                </Paper>
              ))
            : null}
        </Grid>
      </Grid>
    </div>
  )
}
