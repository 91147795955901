import React, { useState } from 'react'
import { AppBar, Toolbar, IconButton } from '@material-ui/core'
import {
  Menu as MenuIcon,
  ArrowBack as ArrowBackIcon,
  ExitToApp,
} from '@material-ui/icons'
import classNames from 'classnames'
import logo from './small_dtb.png'
import littleLogo from './favicon.png'

// styles
import useStyles from './styles'

// components
import { Typography, Button } from '../Wrappers'

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from '../../context/LayoutContext'
import { useUserDispatch, signOut } from '../../context/UserContext'
import { useSelector } from 'react-redux'

const messages = []

const notifications = []

export default function Header(props) {
  var classes = useStyles()

  // global
  var layoutState = useLayoutState()
  var layoutDispatch = useLayoutDispatch()
  var userDispatch = useUserDispatch()

  // local
  var [mailMenu, setMailMenu] = useState(null)
  var [isMailsUnread, setIsMailsUnread] = useState(true)
  var [notificationsMenu, setNotificationsMenu] = useState(null)
  var [isNotificationsUnread, setIsNotificationsUnread] =
    useState(true)
  var [profileMenu, setProfileMenu] = useState(null)
  var [isSearchOpen, setSearchOpen] = useState(false)
  const user = useSelector((state) => state.login.user)

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButtonSandwich,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>

        <img
          src={user['BankID'] === '63' ? logo : littleLogo}
          alt="logo"
          height="40"
          style={{ marginRight: '10px' }}
        />
        <Typography
          variant="h6"
          weight="medium"
          className={classes.logotype}
        >
          Portal
        </Typography>
        <div className={classes.grow} />
        <Button
          variant="outlined"
          style={{ color: '#ffffff' }}
          //className={classes.headerMenuButton}
          startIcon={<ExitToApp />}
          onClick={(e) => signOut(userDispatch, props.history)}
        >
          Log out
        </Button>
      </Toolbar>
    </AppBar>
  )
}
