import { TOGGLE_FILTER, CURRENT_DATA_DRIVERS } from './actionTypes'

const InitialState = {
  isOpen: true,
  currentData: [],
}

export default function driversReducers(
  state = InitialState,
  action,
) {
  switch (action.type) {
    //create agents

    case TOGGLE_FILTER:
      return {
        ...state,
        isOpen: action.data,
      }

    case CURRENT_DATA_DRIVERS:
      return {
        ...state,
        currentData: action.response,
      }

    default:
      return state
  }
}
