import { call, put } from 'redux-saga/effects'
// import history from './history';
//import { uistartLoading, uistopLoading } from "../Ui/actions";
// import { useHistory } from "react-router-dom";
import { rejectedCall } from './api'
import {
  getDeclinedLoansFailed,
  getDeclinedLoansSuccess,
} from './actions'
import { toast } from 'react-toastify'

export function* getRejectedSaga(action) {
  try {
    // alert('happpening')
    const res = yield call(rejectedCall, action.id)
    console.log('rejected console//////////////////////', res)
    // alert('yfgygygef')
    yield put(getDeclinedLoansSuccess(res.data.transactions.rows))
  } catch (err) {
    yield put(getDeclinedLoansFailed(err.message))
    console.log('rejected error', err)
    //  // alert("an error occurred" + err.message);
    toast(err.message)
  }
}
