//login constants

//login action types
export const GET_BANK_CUSTOMERS = 'GET_BANK_CUSTOMERS'
export const GET_BANK_CUSTOMERS_SUCCESS = 'GET_BANK_CUSTOMERS_SUCCESS'
export const GET_BANK_CUSTOMERS_FAILED = 'GET_BANK_CUSTOMERS_FAILED'

//SET_CUSTOMER

export const SET_CUSTOMER = 'SET_CUSTOMER'
export const SET_CUSTOMER_SUCCESS = 'SET_CUSTOMER_SUCCESS'
