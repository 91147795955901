import React, { useState } from 'react'
import {
  Badge,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { Inbox as InboxIcon } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

// styles
import useStyles from './styles'

// components
import Dot from '../Dot'

export default function SidebarLink({
  link,
  icon,
  label,
  children,
  location,
  isSidebarOpened,
  checkRoles,
  nested,
  type,
  count,
  notifications,
  history,
}) {
  var classes = useStyles()

  function toggleCollapse(e) {
    if (isSidebarOpened) {
      history.push('/app/details')
      e.preventDefault()
      setIsOpen(!isOpen)
    }
  }

  // const checkRoles =  (menu) => {
  //   let userRole =  user.Roles
  //   return userRole.includes(menu.right) ? true : false
  // }

  // local
  var [isOpen, setIsOpen] = useState(false)
  var isLinkActive =
    link &&
    (location.pathname === link ||
      location.pathname.indexOf(link) !== -1)

  // custom sidebar function and logic
  const [open, setOpen] = React.useState(true)

  const handleClick = () => {
    setOpen(!open)
  }

  if (type === 'title')
    return (
      <Typography
        className={classnames(classes.linkText, {
          [classes.linkTextHidden]: !isSidebarOpened,
        })}
      >
        {label}
      </Typography>
    )

  if (type === 'divider')
    return <Divider className={classes.divider} />

  if (!children)
    return (
      <ListItem
        button
        component={link && Link}
        to={link}
        className={classes.link}
        classes={{
          root: classnames(classes.linkRoot, {
            [classes.linkActive]: isLinkActive && !nested,
            [classes.linkNested]: nested,
          }),
        }}
        disableRipple
      >
        <ListItemIcon
          className={classnames(classes.linkIcon, {
            [classes.linkIconActive]: isLinkActive,
          })}
        >
          <Badge badgeContent={notifications} color="error" max={99}>
            {nested ? (
              <Dot color={isLinkActive && 'primary'} />
            ) : (
              icon
            )}
          </Badge>
        </ListItemIcon>

        <ListItemText
          classes={{
            primary: classnames(classes.linkText, {
              [classes.linkTextActive]: isLinkActive,
              [classes.linkTextHidden]: !isSidebarOpened,
            }),
          }}
          primary={label}
        />
      </ListItem>
    )

  return (
    <>
      <ListItem
        button
        component={link && Link}
        onClick={toggleCollapse}
        className={classes.link}
        to={link}
        disableRipple
      >
        <ListItemIcon
          className={classnames(classes.linkIcon, {
            [classes.linkIconActive]: isLinkActive,
          })}
        >
          {icon ? icon : <InboxIcon />}
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: classnames(classes.linkText, {
              [classes.linkTextActive]: isLinkActive,
              [classes.linkTextHidden]: !isSidebarOpened,
            }),
          }}
          primary={label}
        />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      {children && (
        <Collapse
          in={isOpen && isSidebarOpened}
          timeout="auto"
          unmountOnExit
          className={classes.nestedList}
        >
          <List component="div" disablePadding>
            {children.filter((data) => checkRoles(data))
            .map((childrenLink) => (
              <SidebarLink
                key={childrenLink && childrenLink.link}
                location={location}
                isSidebarOpened={isSidebarOpened}
                classes={classes}
                nested
                {...childrenLink}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  )

  // ###########################################################
}
