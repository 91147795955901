import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import MuiPhoneNumber from 'material-ui-phone-number-input'
import { setUser } from '../redux/actions'
import { useDispatch, useSelector } from 'react-redux'

export default function AddUserForm(props) {

  // const [phoneNumber, handleOnChange] = React.useState('')

  const dispatch = useDispatch()
  const user = props.user
  function handleOnChange(value) {

    dispatch(setUser('PhoneNumber', value))

  }
  
  const registeredUser = useSelector(state => state.registeredUser)

  //function to add the rights during edit 

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Personal Details
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            defaultValue={user['FullName']}
            onChange={(e) =>
              dispatch(setUser('FullName', e.target.value))
            }
            name="firstName"
            label="Name"
            fullWidth
            autoComplete="given-name"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            defaultValue={user.IdNumber}
            name="lastName"
            onChange={(e) =>
              dispatch(setUser('IdNumber', e.target.value))
            }
            label="ID Number"
            fullWidth
            autoComplete="family-name"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            onChange={(e) =>
              dispatch(setUser('Email', e.target.value))
            }
            id="email"
            name="email"
            defaultValue={user.Email}
            label="Email"
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <MuiPhoneNumber
            onlyCountries={['ke']}
            defaultCountry={'ke'}
            onChange={handleOnChange}
            value={user.PhoneNumber}
            fullWidth
            style={{ margin: 15 }}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          {/* Removed departments */}
          <small style={{ color: 'blue' }}>
            <i>{!props.type ? '' : ''}</i>
          </small>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
