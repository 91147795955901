//login constants

//login action types
//export const SET_USER = "SET_USER";

export const GET_ANALYTICS = 'GET_ANALYTICS'
export const GET_ANALYTICS_SUCCESS = 'GET_ANALYTICS_SUCCESS'
export const GET_ANALYTICS_FAILED = 'GET_ANALYTICS_FAILED'
//GET_DECLINED

export const GET_DECLINED = 'GET_DECLINED'
export const GET_DECLINED_SUCCESS = 'GET_DECLINED_SUCCESS'
export const GET_DECLINED_FAILED = 'GET_DECLINED_FAILED'
