import {
  GET_ANALYTICS,
  GET_ANALYTICS_FAILED,
  GET_ANALYTICS_SUCCESS,
} from './actionTypes'

const InitialState = {
  //  registrations: {},
  //  percentage: {},
  //  monthly: {},
  data: [],
  loading: false,
  error: 'Loading...',
}

export default function GetAnalyticsReducer(
  state = InitialState,
  action,
) {
  switch (action.type) {
    //create agents

    case GET_ANALYTICS:
      return {
        ...state,
        loading: true,
      }

    case GET_ANALYTICS_SUCCESS:
      return {
        ...state,
        data: action.results,
        loading: false,
      }

    case GET_ANALYTICS_FAILED:
      return {
        ...state,
        error: action.results,
        loading: false,
      }

    default:
      return state
  }
}
