import React, { useEffect } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Paper from '@material-ui/core/Paper'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

///import AddressForm from './AddressForm';
//import PaymentForm from './PaymentForm';
//import Review from './Review';
// styles

import useStyles from './styles'
import AddUserForm from './components/AddUserForm'
import Roles from './components/Roles'
import Confirmation from './components/Confirmation'
import { Grid } from '@material-ui/core'
import { Done } from '@material-ui/icons'
import { registerUser, resetUserStore } from './redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import ScaleLoader from 'react-spinners/ScaleLoader'

//custom columns in the table
//data dummy
////

const steps = ['Personal Details', 'Rights', 'Confirmation']


export default function AddUser(props) {
  var classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(0)
  const addUser = useSelector((state) => state.addUser)
  const login = useSelector((state) => state.login.user.EMailID)

  const userId = useSelector((state) => state.login.user.BankID)
  let user = useSelector((state) => state.addUser.user)
  const editUser = useSelector((state) => state.users.selectedUser)
  const RegisteredUser = useSelector(
    (state) => state.addUser.RegisteredUser,
  )
  useEffect(() => {
    dispatch(resetUserStore("NODE"))
    return () => {
      // cleanup
    }
  }, [])
  const Edit = {
    FullName: editUser.Name,
    IdNumber: editUser.IDNumbre,
    Email: editUser.EMailID,
    PhoneNumber: editUser.MobileNumber,
    company: editUser.BankID,
    role: editUser.Roles,
    bank: editUser.BankID,
    bankId: editUser.BankID,
  }
//  alert(JSON.stringify(Edit))
  const defaultData = user

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <AddUserForm
            type={props.location.edit}
            user={defaultData}
          />
        )
      case 1:
        return <Roles user={editUser} />
      case 2:
        return <Confirmation user={defaultData} />
      default:
        throw new Error('Unknown step')
    }
  }

  const dispatch = useDispatch()

  function Finish(reset) {

    let resetPassword = reset ? 'reset' : 'edit'
    let mydata = reset ? {...editUser, reset: resetPassword} : { ...addUser.user, bankId: userId || '63' , login: login , reset: resetPassword}
    // alert(JSON.stringify(mydata))
    dispatch(
      registerUser(
        mydata,
        userId,
        props.location.edit ? 'edit' : 'new',
      ),
    )
    !reset && setActiveStep(activeStep + 1)
  }
  // console.log('the user is added')

  const handleNext = () => {
    activeStep === 2 ? Finish(false) : setActiveStep(activeStep + 1)
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1)
  }

  const resetUser = () => {
    setActiveStep(0)
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper
          style={{
            padding: 20,
            flexGrow: 1,
            width: '100%',
            // height: '500px',
          }}
        >
          <Typography component="h1" variant="h4" align="center">
            {props.location.edit ? 'Edit User' : 'Add User'}
          </Typography>
          <Stepper
            activeStep={activeStep}
            className={classes.stepper}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  style={{ textAlign: 'center' }}
                >
                  {addUser.success ? (
                    <Grid item sm={12}>
                      <Done style={{ color: 'green', margin: 10 }} />
                    </Grid>
                  ) : null}

                  {addUser.loading ? (
                    <Grid item sm={12}>
                      <ScaleLoader color={'#536DFE'} size={50} />
                    </Grid>
                  ) : null}

                  {addUser.success ? (
                    <Grid item sm={12}>
                      <Typography color="primary" variant="body1">
                        {props.location.edit
                          ? 'User  has been edited successfully'
                          : `One-Time Password is: ${RegisteredUser.PIN}`}
                      </Typography>
                    </Grid>
                  ) : null}

                  {addUser.error ? (
                    <Grid item sm={12}>
                      <Typography color="secondary" variant="body1">
                        {addUser.error}
                      </Typography>
                    </Grid>
                  ) : null}

                  <Grid item sm={12}>
                    <Button
                      onClick={resetUser}
                      className={classes.button}
                      variant="outlined"
                      fullWidth
                      disabled={addUser.loading}
                    >
                      {addUser.error
                        ? 'Try Again'
                        : props.location.edit
                        ? 'Edit Other Details'
                        : 'Add New User'}
                    </Button>
                  </Grid>
                </Grid>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Back
                    </Button>
                  )}
                  {props.location.edit && (
                    <div>
                    {activeStep == 0 && addUser.success && (
                     <span style={{color : 'blueviolet'}}>User one time password : <b style={{color: 'red', fontSize: '20px'}}>{localStorage.getItem('tempKey')}</b> </span>
                    )}
                   {activeStep == 0 && (
                    <Button
                      onClick={(e)=> Finish(true)}
                      className={classes.button}
                      variant='contained'
                      color="primary"
                      style={{width: '200px'}}
                    >
                      Reset Password
                    </Button>
                  )}
                   {addUser.loading ? (
                    <Grid item sm={12}>
                      <ScaleLoader color={'#536DFE'} size={50} />
                    </Grid>
                  ) : null}
                    </div>
                  )
                  }

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1
                      ? 'Finish'
                      : 'Next'}
                  </Button>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
      </main>
    </React.Fragment>
  )
}
