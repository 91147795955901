import {
  GET_PARAMS,
  GET_PARAMS_FAILED,
  GET_PARAMS_SUCCESS,
  SET_PARAMS,
  UPDATE_PARAMS,
  UPDATE_PARAMS_SUCCESS,
} from './actionTypes'

const InitialState = {
  data: [
    { Percentage: '...' },
    { Percentage: '...' },
    { Percentage: '...' },
    { Percentage: '...' },
  ],
  params: {},
  loading: false,
  error: 'Loading...',
  message: '',
  paramsEdit: {
    processing: '',
    penalty: '',
    excise: '',
    interest: '',
  },
}

export default function getParamsReducer(
  state = InitialState,
  action,
) {
  switch (action.type) {
    //create agents

    case GET_PARAMS:
      return {
        ...state,
        loading: true,
      }

    case GET_PARAMS_SUCCESS:
      return {
        ...state,
        data: action.response,
        loading: false,
        paramsEdit: {
          processing: action.response[0].Percentage.toString(),
          interest: action.response[1].Percentage.toString(),
          penalty: action.response[3].Percentage.toString(),
          excise: action.response[2].Percentage.toString(),
        },
      }

    case SET_PARAMS:
      return {
        ...state,
        paramsEdit: {
          ...state.paramsEdit,
          [action.name]: action.value,
        },
      }

    case GET_PARAMS_FAILED:
      return {
        ...state,
        error: action.results,
        loading: false,
      }

    case UPDATE_PARAMS:
      return {
        ...state,
        error: action.results,
        loading: true,
        message: 'loading...',
      }

    case UPDATE_PARAMS_SUCCESS:
      return {
        ...state,
        error: action.results,
        loading: false,
        message: 'Submitted for approval',
      }

    default:
      return state
  }
}
