import axios from 'axios'
import moment from 'moment'
export async function resubmitCall(mobile) {
  const data = { customer: mobile, city: 'Nairobi', country: 'Kenya' }
  return await axios.post(
    `https://stats.little.bz/banks/createCustomer/dtb`,
    JSON.stringify(data),
    {
      headers: {
        authorization: `${localStorage.getItem('id_token')}`,
        'Content-Type': 'application/json',
      },
    },
  )
}

//blockCall

export async function blockCall(mobile) {
  var today = new Date()
  var day = moment()
  let todayData = () =>
    day.format('dddd MMMM Do YYYY, h:mm:ss a').toString()

  let formId
  mobile.action === 'block'
    // ? (formId = 'BANK.BLOCKAGENT')
    ? (formId = 'BANK.MARKBLOCKAGENT')
    : (formId = 'BANK.MARKUNBLOCKAGENT')
  // alert(mobile.user)BANK.MARKBLOCKAGENT
  console.log('..,,...,,,>>', todayData)
  const data = {
    payload: {
      FormID: formId,
      City: 'Nairobi',
      Country: 'Kenya',
      UserID: mobile.user,
      BlockAgent: {
        MobileNumber: mobile.mobile,
        BankID: 'dtb',
        Reason: mobile.reason,
        ActionDate: '23 Nov 2021',
      },
    },
  }
  return await axios.post(
    `https://stats.little.bz/banks/process`,
    JSON.stringify(data),
    {
      headers: {
        authorization: `${localStorage.getItem('id_token')}`,
        'Content-Type': 'application/json',
      },
    },
  )
}
