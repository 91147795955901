import React from 'react'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
// components
import Layout from './Layout'
// pages
import Error from '../pages/error'
import Login from '../pages/login'
// context\
import { useUserState } from '../context/UserContext'
import { useSelector } from 'react-redux'
// import { PrivateRoute as RouteTest } from './Private'

export default function App() {
  // global
  var { isAuthenticated } = useUserState()
  let token = useSelector((state) => state.login.token)

  return (
    <HashRouter>
      <Switch>
        <Route
          exact
          path="/"
          render={() => <Redirect to="/app/dashboard" />}
        />
        <Route
          exact
          path="/app"
          render={() => <Redirect to="/app/dashboard" />}
        />
        <PrivateRoute path="/app" component={Layout} />
        <Route path="/login" component={Login} />
        {/* <Route path="/pdf" component={Pdf} /> */}
        <Route component={Error} />
      </Switch>
    </HashRouter>
  )

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          localStorage.getItem('id_token') ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    )
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          token ? (
            <Redirect
              to={{
                pathname: '/',
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    )
  }
}
