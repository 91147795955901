import { call, put } from 'redux-saga/effects'
// import history from './history';
//import { uistartLoading, uistopLoading } from "../Ui/actions";
// import { useHistory } from "react-router-dom";
import { deleteUser, usersCall } from './api'
import {
  deleteUserSuccess,
  getUsersFailed,
  getUsersSuccess,
  setUserSuccess,
} from './actions'
import { toast } from 'react-toastify'
import { defaultUser } from '../../user_add/redux/actions'

export function* getUsersSaga(action) {
  try {
    const res = yield call(usersCall, action.data)
    console.log('users console', res)
    let usersAvailable = res.data.users.rows.filter(
      (data) => data.DeletedOn === null && data.UserID != 'LS4921',
    )
    yield put(getUsersSuccess(usersAvailable))
  } catch (err) {
    yield put(getUsersFailed(err))
    //   console.log("Login error", err);
    //  // alert("an error occurred" + err.message);
    //  toast('An error Occurred '+ err.response.data.message);
    toast('An error Occurred ' + err)
  }
}

//set users

export function* setUsersSaga(action) {
  try {
    yield put(setUserSuccess(action.data))
    yield put(defaultUser(action.data))

    action.history.push({
      pathname: '/app/add_user',
      edit: true,
    })
  } catch (err) {
    alert(err.message)
  }
}

//delete  user

export function* deleteUserSaga(action) {
  try {
    const res = yield call(deleteUser, action.data)
    console.log('remover', res)
    yield put(deleteUserSuccess(action.data))
    toast('Successfully suspended and will be reviewed')
  } catch (err) {
    toast(err.response.data.errors)
  }
}
