import { makeStyles } from '@material-ui/styles'
import thedrawer from './test.png'

const drawerWidth = 240

export default makeStyles((theme) => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.6em',
      // '&:hover': {
      //  width: '0.9em'
      // },
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '*::-webkit-scrollbar-thumb': {
      borderRadius: '15px',
      // '&:hover': {
      //   width: '90px'
      //  },
      '-webkit-box-shadow': 'inset 8 0 6px rgba(0,0,0,.3)',
      backgroundColor: '#FEFEFE',
    },
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    overflow: 'hidden',
    height: '100%',
  },
  drawerOpen: {
    // overflow: "scroll",
    height: '100%',
    overflowY: 'scroll',

    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: '#536DFE',
    backgroundImage: `url(${thedrawer})`,
  },
  nyuma: {},

  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 40,
    [theme.breakpoints.down('sm')]: {
      width: drawerWidth,
    },
    backgroundColor: '#536DFE',
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },

  sidebarList: {
    // marginTop: theme.spacing(6),
    margin: '5px',
    // overflow: "hidden",
  },

  mobileBackButton: {
    marginTop: theme.spacing(0.5),
    color: '#000000',
    marginLeft: 18,
    [theme.breakpoints.only('sm')]: {
      marginTop: theme.spacing(0.625),
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    height: 1,
    backgroundColor: '#D8D8D880',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}))
