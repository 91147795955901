import React from 'react'
import { Grid } from '@material-ui/core'
import {
  AccessTime,
  AccountBalance,
  AssignmentInd,
  Call,
  Email,
  ErrorOutline,
} from '@material-ui/icons'
import { useSelector } from 'react-redux'
import moment from 'moment'

export default function Confirmation(props) {
  const [value, setValue] = React.useState('female')
  const [company, setCompany] = React.useState('Little')

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const handleCompany = (event) => {
    setCompany(event.target.value)
  }

  let su = useSelector((state) => state.login.su)
  const RegisteredUser = useSelector(
    (state) => state.addUser.RegisteredUser,
  )
  let user = props.user

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="center"
      >
        <Grid item xs={6}>
          <Grid
            container
            direction="row"
            alignItems="center"
            style={{ width: '100%' }}
          >
            <ErrorOutline
              style={{ height: 30, width: 30, marginRight: 10 }}
            />
            <span style={{ fontSize: 15, fontStyle: 'bold' }}>
              <b>Confirm User Details</b>
            </span>
          </Grid>

          {/* registration date */}

          <Grid
            container
            direction="row"
            alignItems="center"
            style={{ marginTop: 21 }}
          >
            <AccessTime
              color="primary"
              style={{ height: 20, width: 20, marginRight: 5 }}
            />
            <span style={{ fontSize: 12, width: '155px' }}>
              <b>Date</b>
            </span>
            <span> {moment().format('MMMM Do YYYY')} </span>
          </Grid>

          {/* name */}

          <Grid
            container
            direction="row"
            alignItems="center"
            style={{ marginTop: 11 }}
          >
            <AssignmentInd
              color="primary"
              style={{ height: 20, width: 20, marginRight: 5 }}
            />
            <span style={{ fontSize: 12, width: '155px' }}>
              <b>Official Name:</b>
            </span>
            <span> {user.FullName}</span>
          </Grid>

          {/* Phone No. */}

          <Grid
            container
            direction="row"
            alignItems="center"
            style={{ marginTop: 11 }}
          >
            <Call
              color="primary"
              style={{ height: 20, width: 20, marginRight: 5 }}
            />
            <span style={{ fontSize: 12, width: '155px' }}>
              <b>Phone Number:</b>
            </span>
            <span> {user.PhoneNumber}</span>
          </Grid>

          <Grid
            container
            direction="row"
            alignItems="center"
            style={{ marginTop: 11 }}
          >
            <Email
              color="primary"
              style={{ height: 20, width: 20, marginRight: 5 }}
            />
            <span style={{ fontSize: 12, width: '155px' }}>
              <b>Email: </b>
            </span>
            <span>{user.Email}</span>
          </Grid>

          {/* <Grid
            container
            direction="row"
            alignItems="center"
            style={{ marginTop: 11 }}
          >
            <Work
              color="primary"
              style={{ height: 20, width: 20, marginRight: 5 }}
            />
            <span style={{ fontSize: 12, width: '155px' }}>
              <b>Role</b>
            </span>
            <span> {user.role}</span>
          </Grid> */}
          {su ? (
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{ marginTop: 11 }}
            >
              <AccountBalance
                color="primary"
                style={{ height: 20, width: 20, marginRight: 5 }}
              />
              <span style={{ fontSize: 12, width: '155px' }}>
                <b>Company/Bank</b>
              </span>
              <span> {user.bank}</span>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
