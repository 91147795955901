import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from '@material-ui/styles'
import { CssBaseline } from '@material-ui/core'
import Themes from './themes'
import App from './components/App'
import * as serviceWorker from './serviceWorker'
import { LayoutProvider } from './context/LayoutContext'
import { UserProvider } from './context/UserContext'
import { persistor, store } from './redux/store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
// import { PersistGate } from 'redux-persist/integration/react';

// const history = syncHistoryWithStore(createHistory, store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <LayoutProvider>
        <UserProvider>
          <ThemeProvider theme={Themes.default}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </UserProvider>
      </LayoutProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
)

serviceWorker.unregister()
