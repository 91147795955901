import { call, put } from 'redux-saga/effects'
// import history from './history';
//import { uistartLoading, uistopLoading } from "../Ui/actions";
// import { useHistory } from "react-router-dom";
import { resubmitCall, blockCall } from './api'
import { toast } from 'react-toastify'
import {
  blockDriverFailed,
  blockDriversSuccess,
  resubmitDriverFailed,
  resubmitDriverSuccess,
} from './actions'

export function* resubmitDriverSaga(action) {
  try {
    const res = yield call(resubmitCall, action.data)
    console.log('resubmit', res)
    yield put(resubmitDriverSuccess(res.data.data[0].Message))

    //set customer
  } catch (err) {
    yield put(resubmitDriverFailed(err.message))
    //   console.log("Login error", err);
    //  // alert("an error occurred" + err.message);
    toast('An error Occurred, please try again latter ' + err.message)
  }
}

// block driver saga

export function* blockDriverSaga(action) {
  try {
    const res = yield call(blockCall, action.data)
    console.log('block driver', res)
    yield put(blockDriversSuccess(res.data.data[0].Message))

    //set customer
  } catch (err) {
    yield put(blockDriverFailed(err.message))
    //   console.log("Login error", err);
    //  // alert("an error occurred" + err.message);
    toast('An error Occurred, please try again latter ' + err.message)
  }
}
