import axios from 'axios'

export async function paramsCall(data) {
  var options = {
    method: 'GET',
    url: 'https://stats.little.bz/banks/productCharges/dtb',
    params: {
      city: 'NAIROBI',
      country: 'KENYA',
      user: 'brianmburu@dtb.com',
      '': '',
    },
    headers: {
      authorization: `${localStorage.getItem('id_token')}`,
      'Content-Type': 'application/json',
    },
  }

  return await axios.request(options)
}

// paramsupdateCall

export async function paramsupdateCall(dataa) {
  alert(JSON.stringify(dataa))
  var today = new Date()
  const data = {
    payload: {
      FormID: 'UPDATEPRODUCTCHARGES',
      City: 'Nairobi',
      Country: 'Kenya',
      UserID: dataa.email,
      TrxReport: {
        UserID: dataa.email,
        BankID: 'dtb',
        ProductID: 'PL',
      },
      Charges: [
        {
          TrxID: 'INTEREST',
          Description: 'Interest On Loan %%VALUE%%',
          Amount: 0,
          Percentage: dataa.interest,
        },
        {
          TrxID: 'PROCESSINGFEES',
          Description: 'Processing Fees Loan %%VALUE%%',
          Amount: 0,
          Percentage: dataa.processing,
        },
        {
          TrxID: "EXCISEDUTY",
          Description: "Excise Duty Loan %%VALUE%%",
          Amount: 0,
          Percentage: dataa.excise
        },
        {
          TrxID: "PENALTYAMOUNT",
          Description: "Penalty Amount%%VALUE%%",
          Amount: 0,
          Percentage: dataa.penalty
        }
      ],
    },
  }
  return await axios.post(
    `https://stats.little.bz/banks/process`,
    JSON.stringify(data),
    {
      headers: {
        authorization: `${localStorage.getItem('id_token')}`,
        'Content-Type': 'application/json',
      },
    },
  )
}
