import {
  GET_ANALYTICS,
  GET_ANALYTICS_FAILED,
  GET_ANALYTICS_SUCCESS,
} from './actionTypes'

//get analytics data
export const getAnalytics = (id) => {
  return {
    type: GET_ANALYTICS,
    id,
  }
}

export const getAnalyticsSuccess = (response) => {
  return {
    type: GET_ANALYTICS_SUCCESS,
    response,
  }
}

export const getAnalyticsFailed = (response) => {
  return {
    type: GET_ANALYTICS_FAILED,
    response,
  }
}

//
