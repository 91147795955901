import { REGISTER_USER_SUCCESS } from '../../user_add/redux/actionTypes'
import {
  DELETE_USER,
  DELETE_USER_FAILED,
  DELETE_USER_SUCCESS,
  GET_USERS,
  GET_USERS_FAILED,
  GET_USERS_SUCCESS,
  SET_USER_SUCCESS,
} from './actionTypes'

const InitialState = {
  users: [],
  loading: false,
  error: 'none',
  fetched: false,
  selectedUser: {Roles: 'iggr'},
}

export default function getUsersReducer(
  state = InitialState,
  action,
) {
  switch (action.type) {
    //create agents

    case GET_USERS:
      return {
        ...state,
        loading: true,
      }

    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.response,
        loading: false,
        fetched: true,
      }

    case GET_USERS_FAILED:
      return {
        ...state,
        error: action.response,
        users: [],
        loading: false,
        fetched: false,
      }

    case SET_USER_SUCCESS:
      return {
        ...state,
        selectedUser: action.data,
      }
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        selectedUser: {Roles: 'iggr'}
      }
    case DELETE_USER:
      return {
        ...state,
        loading: true,
      }
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter(
          (user) => user.UserID != action.response,
        ),
        loading: false,
      }
    case DELETE_USER_FAILED:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}
