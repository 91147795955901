import axios from 'axios'

export async function banksCall(data) {
  return await axios.get(
    `https://stats.little.bz/banks/users/` + `${data.id}` + `?page=1`,
    {
      headers: {
        authorization: `${localStorage.getItem('id_token')}`,
        'Content-Type': 'application/json',
      },
    },
  )
}
