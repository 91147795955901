import axios from 'axios'

export async function loansCall(data) {
  var thedata = JSON.stringify({
    country: 'KENYA',
    city: 'NAIROBI',
    user: 'brianmburu@dtb.com',
    fromdate: '01 Jan 2021',
    todate: '31 Dec 2021',
  })

  var config = {
    method: 'post',
    url: 'https://stats.little.bz/banks/loansapplied/DTB',
    headers: {
      authorization: `${localStorage.getItem('id_token')}`,
      'Content-Type': 'application/json',
    },
    data: thedata,
  }
  return await axios(config)
}
