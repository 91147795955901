import {
  DEFAULT_USER,
  REGISTER_USER,
  REGISTER_USER_FAILED,
  REGISTER_USER_SUCCESS,
  SET_USER,
  RESET_STORE
} from './actionTypes'

//users actions
export const setUser = (field, data) => {
  return {
    type: SET_USER,
    data,
    field,
  }
}

// register user

export const registerUser = (data, bankId, action, history) => {
  return {
    type: REGISTER_USER,
    data,
    bankId,
    action,
    history,
  }
}

//register user success

export const registerUserSuccess = (response) => {
  return {
    type: REGISTER_USER_SUCCESS,
    response,
  }
}

//register user failed

export const registerUserFailed = (response) => {
  return {
    type: REGISTER_USER_FAILED,
    response,
  }
}

export const defaultUser = (data) => {
  return {
    type: DEFAULT_USER,
    data,
  }
}
// resetUserStore

export const resetUserStore = (data) => {
  return {
    type: RESET_STORE,
    data,
  }
}
