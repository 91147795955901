import {
  DELETE_USER,
  DELETE_USER_FAILED,
  DELETE_USER_SUCCESS,
  GET_USERS,
  GET_USERS_FAILED,
  GET_USERS_SUCCESS,
  SET_USER_EDIT,
  SET_USER_SUCCESS,
} from './actionTypes'

//get analytics data
export const getUsers = (data) => {
  return {
    type: GET_USERS,
    data,
  }
}

export const getUsersSuccess = (response) => {
  return {
    type: GET_USERS_SUCCESS,
    response,
  }
}

export const getUsersFailed = (response) => {
  return {
    type: GET_USERS_FAILED,
    response,
  }
}

// set user in redux for editing'

export const setUser = (history, data) => {
  return {
    type: SET_USER_EDIT,
    data,
    history,
  }
}

export const setUserSuccess = (data) => {
  return {
    type: SET_USER_SUCCESS,
    data,
  }
}

//deleteUser

export const deleteUser = (data) => {
  return {
    type: DELETE_USER,
    data,
  }
}

export const deleteUserSuccess = (response) => {
  return {
    type: DELETE_USER_SUCCESS,
    response,
  }
}

export const deleteUserFailed = (data) => {
  return {
    type: DELETE_USER_FAILED,
    data,
  }
}
