import {
  LOAN_REPAYMENT,
  LOAN_REPAYMENT_FAILED,
  LOAN_REPAYMENT_SUCCESS,
} from './actionTypes'

//get analytics data
export const getRepayment = (data) => {
  return {
    type: LOAN_REPAYMENT,
    data,
  }
}

export const getRepaymentSuccess = (response) => {
  return {
    type: LOAN_REPAYMENT_SUCCESS,
    response,
  }
}

export const getRepaymentFailed = (response) => {
  return {
    type: LOAN_REPAYMENT_FAILED,
    response,
  }
}
