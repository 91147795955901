import { TOGGLE_FILTER, CURRENT_DATA_DRIVERS } from './actionTypes'

//login actions
export const toggleFilter = (data) => {
  return {
    type: TOGGLE_FILTER,
    data,
  }
}

//login actions
export const setCurrentData = (response) => {
  return {
    type: CURRENT_DATA_DRIVERS,
    response,
  }
}
