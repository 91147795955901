import React, { useEffect, useState } from 'react'

import useStyles from './styles'
import { useDispatch, useSelector } from 'react-redux'
import MUIDataTable from 'mui-datatables'
import CustomToolbar from './CustomToolbar'
import { getParams } from './redux/actions'
import { Button } from '@material-ui/core'
import { Autorenew } from '@material-ui/icons'
import { SyncLoader } from 'react-spinners'

const steps = ['Personal Details', 'Role', 'Confirmation']

// PropagateLoader

export default function Params(props) {
  var classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(0)
  const addUser = useSelector((state) => state.addUser)
  const userId = useSelector((state) => state.login.user.BankID)
  let user = useSelector((state) => state.addUser.user)
  let login = useSelector((state) => state.login.user)
  const editUser = useSelector((state) => state.users.selectedUser)
  const [disable, setActive] = React.useState(true)
  // let [loading, setLoading] = useState(true)
  let [color, setColor] = useState('#ffffff')

  const Edit = {
    FullName: editUser.Name,
    IdNumber: editUser.IDNumbre,
    Email: editUser.EMailID,
    PhoneNumber: editUser.MobileNumber,
    company: editUser.BankID,
    role: '',
    bank: editUser.BankID,
    bankId: editUser.BankID,
  }

  const defaultData = user

  const dispatch = useDispatch()

  const columns = [
    {
      name: 'processing',
      label: 'Processing Fee (%)',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'interest',
      label: 'Interest Rate (%)',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'duty',
      label: 'Excise DUTY(%)',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'penalty',
      label: 'Penalty(%)',
      options: {
        filter: true,
        sort: false,
      },
    },
  ]

  const options = {
    filter: false,
    download: false,
    filterType: 'checkbox',
    print: 'false',
    selectableRows: false,
    search: false,
    elevation: 7,
    fixedHeader: false,
    customToolbar: () => {
      return login.Roles.includes('EP') ? <CustomToolbar /> : null
    },
  }

  useEffect(() => {
    dispatch(getParams('user'))
    return () => {
      // cleanup
    }
  }, [])

  const params = useSelector((state) => state.params)

  const data = [
    {
      processing: params.data[1].Percentage,
      interest: params.data[0].Percentage,
      duty: params.data[2].Percentage,
      penalty: params.data[3].Percentage,
    },
  ]

  return (
    <>
      <MUIDataTable
        title={
          !params.loading ? (
            <Button
              variant="text"
              color="primary"
              onClick={() => dispatch(getParams('bankId'))}
              className={classes.button}
              startIcon={<Autorenew />}
            >
              Loan Parameters
            </Button>
          ) : (
            <SyncLoader color={'#536DFE'} size={5} />
          )
        }
        data={data}
        columns={columns}
        options={options}
      />
    </>
  )
}
