import { makeStyles } from '@material-ui/styles'
import { deepOrange, lightBlue } from '@material-ui/core/colors'

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 5,
    color: theme.palette.text.secondary,
    margin: 10,
  },
  avater: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: lightBlue[500],
    margin: 10,
  },
  from: {
    color: lightBlue[500],
    textAlign: 'start',
    fontSize: 12,
  },
}))
