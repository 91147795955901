import React, { useState } from 'react'
import {
  Grid,
  Typography,
  Button,
  TextField,
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import AnalogueClock from 'react-analogue-clock'

// styles
import useStyles from './styles'

// logo
import logo from './littlebg.png'
import smallLogo from './logo.png'
import ScaleLoader from 'react-spinners/ScaleLoader'
// context
import { useUserDispatch } from '../../context/UserContext'

///redux functions

import { useSelector, useDispatch } from 'react-redux'
import { loginRequest } from './redux/actions'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import validator from 'validator'
import { registerUser } from '../user_add/redux/actions'

function Login(props) {
  const loginReducer = useSelector((state) => state.login)
  var classes = useStyles()

  // global

  var userDispatch = useUserDispatch()

  // local
  var [isLoading, setIsLoading] = useState(loginReducer.loading)
  var [error, setError] = useState(null)
  var [activeTabId, setActiveTabId] = useState(0)
  var [nameValue, setNameValue] = useState('')
  var [loginValue, setLoginValue] = useState('')
  var [passwordValue, setPasswordValue] = useState('')
  var [errorMessageEmail, setErrorMessageEmail] = useState('')
  var [error, setError] = useState(false)
  var [greetings, setGreetings] = useState('')

  const [errorMessage, setErrorMessage] = useState('')

  const [newPass, setnewPass] = useState('')
  const [errorPass, setErrorPass] = useState(false)
  const [errorConfirm, setErrorConfirm] = useState(false)
  const [rePassword, setRePassword] = useState('')
  const [errorOldPass, setErrorOldPass] = useState(false)
  const [OldPass, setOldPass] = useState('')
  const [errorMessageRe, seterrorMessageRe] = useState('')

  //Clock options

  const clockOptions = {
    baseColor: '#e6e6e6',
    borderColor: '#44ad44',
    borderWidth: 3,
    centerColor: '#f5cb20',
    handColors: {
      hour: '#1d85c8',
      minute: '#44ad44',
      second: '#d8262a',
    },
    notchColor: '#1d85c8',
    numbersColor: '#1d85c8',
    showNumbers: true,
    size: 230,
  }

  //validate email before submit
  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  //manage email input

  function checkEmail(text) {
    if (validateEmail(text)) {
      setLoginValue(text)
      setError(false)
      setErrorMessageEmail('')
    } else {
      setLoginValue(text)
      setError(true)
      setErrorMessageEmail('Incorrect Email')
    }
  }

  //good morning/afternoon/evening determinant
  var today = new Date()
  var theTime = today.getHours()

  //redux functions
  let token = useSelector((state) => state.login.token)
  let loading = useSelector((state) => state.login.loading)

  const setPassword = useSelector((state) => state.login.setPassword)
  const loading_pass = useSelector((state) => state.addUser.loading)
  const user = useSelector((state) => state.login.user)

  const dispatch = useDispatch()

  // Change the default given password

  //confirm old password

  // const checkPassword = (value) => {
  //   if (validator.equals(value, user.PIN)) {
  //     setErrorOldPass(false)
  //     setOldPass(value)
  //   } else {
  //     setErrorOldPass(true)
  //     setOldPass(value)
  //   }
  // }

  const validate = (value) => {
    if (
      validator.isStrongPassword(value, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setnewPass(value)

      setErrorMessage('')
      setErrorPass(false)
    } else {
      setnewPass(value)
      setErrorMessage(
        'Password should be more than 8 characters, at least 1 lowercase and uppercase letters, at least 1 number and 1 symbol',
      )
      setErrorPass(true)
    }
  }

  //validateConfirmPassword
  const validateConfirmPassword = (value) => {
    if (validator.equals(value, newPass)) {
      setErrorConfirm(false)
      setRePassword(value)
      seterrorMessageRe('')
    } else {
      setErrorConfirm(true)
      setRePassword(value)
      seterrorMessageRe(
        'Password does not Match, Tips; Retype the secure password above',
      )
    }
  }

  return (
    <Grid container className={classes.container}>
      <ToastContainer />
      <div className={classes.logotypeContainer}>
        <div className={classes.overlay}>
          <AnalogueClock {...clockOptions} />
          <img src={logo} alt="logo" />
        </div>
      </div>

      <div className={classes.formContainer}>
        <div className={classes.form}>
          <div className={classes.smallLogo}>
            <img src={smallLogo} alt="Little" />
          </div>

          {activeTabId === 0 && (
            <React.Fragment>
              <Typography variant="h4" className={classes.greeting}>
                {theTime < 12
                  ? 'Good Morning!'
                  : theTime < 16
                  ? 'Good Afternoon!'
                  : 'Good evening!'}
              </Typography>

              <div className={classes.formDividerContainer}>
                <div className={classes.formDivider} />
                <Typography
                  color="primary"
                  className={classes.formDividerWord}
                >
                  {setPassword
                    ? `Change Password`
                    : 'Sign into your Little account'}
                </Typography>
                <div className={classes.formDivider} />
              </div>

              {setPassword ? (
                <>
                  {/* <Grid item xs={12} sm={6}> */}
                  <span
                    style={{
                      fontSize: '12px',
                      marginBottom: '20px',
                    }}
                  >
                    {errorMessage}
                  </span>
                  {/* </Grid> */}
                  <TextField
                    required
                    style={{ marginTop: '20px' }}
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField,
                      },
                    }}
                    margin="normal"
                    variant="outlined"
                    id="email"
                    type="password"
                    name="email"
                    value={newPass}
                    onChange={(e) => validate(e.target.value)}
                    label="New Password"
                    error={errorPass}
                    fullWidth
                  />
                  <TextField
                    required
                    variant="outlined"
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField,
                      },
                    }}
                    margin="normal"
                    id="email"
                    name="email"
                    value={rePassword}
                    onChange={(e) =>
                      validateConfirmPassword(e.target.value)
                    }
                    type="password"
                    error={errorConfirm}
                    disabled={errorPass || newPass.length == 0}
                    label="Repeat New Password"
                    fullWidth
                  />
                </>
              ) : (
                <>
                  <TextField
                    id="email"
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField,
                      },
                    }}
                    value={loginValue}
                    onChange={(e) => checkEmail(e.target.value)}
                    margin="normal"
                    placeholder="Email Address*"
                    type="email"
                    variant="outlined"
                    label="Email*"
                    helperText={errorMessageEmail}
                    error={error}
                    fullWidth
                  />

                  <TextField
                    id="password"
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField,
                      },
                    }}
                    value={passwordValue}
                    onChange={(e) => setPasswordValue(e.target.value)}
                    margin="normal"
                    placeholder="Password*"
                    type="password"
                    fullWidth
                    variant="outlined"
                    label="Password*"
                  />
                </>
              )}

              {!setPassword && (
                <div className={classes.formButtons}>
                  {loading ? (
                    <ScaleLoader color={'#536DFE'} />
                  ) : (
                    <Button
                      disabled={
                        loginValue.length === 0 ||
                        passwordValue.length === 0
                      }
                      onClick={() =>
                        dispatch(
                          loginRequest(
                            {
                              email: loginValue,
                              password: passwordValue,
                            },
                            props.history,
                          ),
                        )
                      }
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                    >
                      Login
                    </Button>
                  )}
                  {/* <Button
                  color="primary"
                  size="large"
                  onClick={(e, id) => setActiveTabId(1)}
                  className={classes.forgetButton}
                >
                  Reset Password?
                </Button> */}
                </div>
              )}

              {setPassword && (
                <div className={classes.formButtons}>
                  {loading_pass ? (
                    <ScaleLoader color={'#536DFE'} />
                  ) : (
                    <Button
                      disabled={
                        loginValue.length === 0 ||
                        passwordValue.length === 0
                      }
                      onClick={() =>
                        dispatch(
                          registerUser(
                            { ...user, bankId: 'dtb', PIN: newPass },
                            'dtb',
                            'password_change',
                            props.history,
                          ),
                        )
                      }
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                    >
                      Continue
                    </Button>
                  )}
                  {/* <Button
                  color="primary"
                  size="large"
                  onClick={(e, id) => setActiveTabId(1)}
                  className={classes.forgetButton}
                >
                  Reset Password?
                </Button> */}
                </div>
              )}
            </React.Fragment>
          )}
          {activeTabId === 1 && (
            <React.Fragment>
              {/* <Typography variant="h4" className={classes.subGreeting}>
              </Typography> */}
              <div className={classes.formDividerContainer}>
                <div className={classes.formDivider} />
                <Typography
                  color="primary"
                  className={classes.formDividerWord}
                >
                  Enter your email address
                </Typography>
                <div className={classes.formDivider} />
              </div>

              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={loginValue}
                onChange={(e) => checkEmail(e.target.value)}
                margin="normal"
                placeholder="Email Address*"
                type="email"
                variant="outlined"
                label="Email*"
                helperText={errorMessageEmail}
                error={error}
                fullWidth
              />
              <div className={classes.formButtons}>
                {loading ? (
                  <ScaleLoader color={'#536DFE'} />
                ) : (
                  <Button
                    disabled={
                      loginValue.length === 0 ||
                      passwordValue.length === 0
                    }
                    onClick={(e) => alert('Reset')}
                    variant="contained"
                    color="primary"
                    size="medium"
                  >
                    Reset
                  </Button>
                )}
                <Button
                  color="primary"
                  size="small"
                  onClick={(e, id) => setActiveTabId(0)}
                  className={classes.forgetButton}
                >
                  Go back to Login?
                </Button>
              </div>
            </React.Fragment>
          )}
        </div>
        <Typography color="primary" className={classes.copyright}>
          © {new Date().getFullYear()} . All rights reserved.
        </Typography>
      </div>
    </Grid>
  )
}

export default withRouter(Login)
