import { call, put } from 'redux-saga/effects'

import { paramsCall, paramsupdateCall } from './api'
import {
  getParamsSuccess,
  getParamsFailed,
  editParamSuccess,
  editParamFailed,
} from './actions'
import { toast } from 'react-toastify'

export function* getParamsSaga(action) {
  try {
    const res = yield call(paramsCall, action.id)
    console.log('params console', res.data.data[0].Charges)
    // alert(JSON.stringify(res))

    yield put(getParamsSuccess(res.data.data[0].Charges))
  } catch (err) {
    yield put(getParamsFailed(err.message))

    toast(
      'An error Occurred, Unable to fetch loan parameters  ' +
        err.message,
    )
  }
}

export function* updateParamsSaga(action) {
  try {
    const res = yield call(paramsupdateCall, action.data)
    console.log('params console', res.data.data[0].Charges)
    // alert(JSON.stringify(res))

    yield put(editParamSuccess(res.data.data[0].Charges))
  } catch (err) {
    yield put(editParamFailed(err.message))

    toast(
      'An error Occurred, Unable to fetch loan parameters  ' +
        err.message,
    )
  }
}
