import React, { useEffect } from 'react'

import { useTheme } from '@material-ui/styles'

// styles
import moment from 'moment'
import useStyles from './styles'
import MUIDataTable from 'mui-datatables'
import {
  Tab,
  Tabs,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Button,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import {
  listBlocked,
  listBlockedCustomers,
  paramChanges,
  unblockDriver,
} from './redux/actions'
import { DeleteForever, Done } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'
import { Tooltip } from '@material-ui/core'
import { getUsers } from '../users/redux/actions'

import axios from "axios";
import { registerUser } from '../user_add/redux/actions'

//custom columns in the table

const data = []

const options = {
  filterType: 'checkbox',
  print: 'false',

  selectableRowsOnClick: 'true',
  selectableRows: false,
  filter: false,
  download: false,
}

////

export default function Pending(props) {
  var classes = useStyles()
  var theme = useTheme()
  // component states
  const [value, setValue] = React.useState(0)
  const [mobile, setMobile] = React.useState('')

  // redux states
  const user = useSelector((state) => state.login.user)
  const users = useSelector((state) => state.users.users)
  // blockedDrivers
  const blockedDrivers = useSelector(
    (state) => state.review.blockedDrivers,
  )

  const blockedList = useSelector((state) => state.review.blockedList)
  const [pendingBlock, setPendingBlock] = React.useState([]);

  const dispatch = useDispatch()
  useEffect(() => {
    // ge the blocked users to unlockk them
    dispatch(listBlocked(user))
    dispatch(paramChanges(user))
    dispatch(getUsers(user))
    dispatch(listBlockedCustomers(user))
    // get the list of loans params changes
    const baseURL = "https://stats.little.bz/banks/process";

    // direct calls

    // blocked pending approval 
    axios
    .post(baseURL, {
      payload: {
        FormID: "BANK.LISTBLOCKAGENTS",
        City: "Nairobi",
        Country: "Kenya",
        UserID: "tihy@little.com",
        BlockAgent: {
          BankID: "dtb",
          ActionDate: "27 Nov 2021"
        }
      }
    }, {
      headers: {
        authorization: `${localStorage.getItem('id_token')}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      let theList = response.data.data.filter((data) => {
        data.BlockedBy === user.EMailID
      })
      // alert(user.EMailID)
       setPendingBlock(response.data.data);
      console.log("results" ,response.data.data,  theList, user.EMailID)
    }).catch(error => {
      // setError(error);
      // alert("error")
    });

    return () => {
      // cleanup
    }
  }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const [openReason, setOpenReason] = React.useState(false)
  const [Reason, setReason] = React.useState('')

  const handleClickOpenReason = () => {
    setOpenReason(true)
  }

  const handleCloseReason = () => {
    setOpenReason(false)
  }

  const handleSubmitReason = (type) => {
    // setOpenReason(false)
    let data = {
      rowID: mobile,
      reason: Reason,
      type: type,
      user: user.EMailID
    }
    dispatch(unblockDriver(data))
    setOpenReason(false)
  }

  const handleChangeReason = (e) => {
    console.log('secure')
    setReason(e.target.value)
  }

  const dataUsers = users.filter(
    (data) =>
      data.DeletedOn === null && data.Roles.includes('PENDING'),
  )

  const columnsUsers = [
    {
      name: 'Name',
      label: 'User Name',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'MobileNumber',
      label: 'Phone Number',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'IDNumbre',
      label: 'ID No.',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'EMailID',
      label: 'Email',
      su: false,
      options: {
        filter: false,
        sort: true,
      },
    },

    {
      name: 'CreatedOn',
      label: 'Created On',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) =>
          value !== null ? (
            <small>{moment(value).format('MMM Do YY')}</small>
          ) : (
            <h1></h1>
          ),
      },
    },
    {
      name: 'UniqueID',
      label: 'Actions',
      // isBank: true,
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            <Tooltip title={'Decline'}>
              <IconButton
                aria-label="View"
                size="small"
                style={{ marginRight: '10px' }}
                onClick={() => {
                  alert('confirm')
                }}
              >
                <DeleteForever style={{ color: 'red' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title={'Approve'}>
              <IconButton
                aria-label="View"
                size="small"
                onClick={() => {
                  // let rowId = blockedList[tableMeta.rowIndex].RowID
                  // setMobile(rowId)
                  // handleClickOpenReason()
                  let theUser = dataUsers[tableMeta.rowIndex]
                  if (
                    window.confirm(
                      `Confirm you want to approve ${theUser.Name}`,
                    )
                  ) {
                    //resubmitDriver
                    // handleSubmitReason('params')
                    dispatch(
                      registerUser(
                        theUser,
                        "userId",
                        'approve',
                      ))

                  } else {
                    // Do nothing!
                    console.log('secure')
                  }
                }}
              >
                <Done style={{ color: 'green' }} />
              </IconButton>
            </Tooltip>
          </>
        ),
        filter: false,
        sort: false,
      },
    },
  ]

  const Loanparams = useSelector(
    (state) => state.review.paramsList[0],
  )
  const successParams = useSelector((state) => state.review.success)
  
  
  var Loanparamsdata = successParams
    ? [
        {
          processing: Loanparams.UnsupervisedCharges[1].Percentage,
          interest: Loanparams.UnsupervisedCharges[0].Percentage,
          duty: Loanparams.UnsupervisedCharges[2].Percentage,
          penalty: Loanparams.UnsupervisedCharges[3].Percentage,
        },
      ]
    : []

  // loan params
  const columnsParams = [
    {
      name: 'processing',
      label: 'Processing Fee (%)',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'interest',
      label: 'Interest Rate (%)',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'duty',
      label: 'Excise DUTY(%)',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'penalty',
      label: 'Penalty(%)',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'UniqueID',
      label: 'Actions',
      // isBank: true,
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            <Tooltip title={'Decline'}>
              <IconButton
                aria-label="View"
                size="small"
                style={{ marginRight: '10px' }}
                onClick={() => {
                  alert('confirm')
                }}
              >
                <DeleteForever style={{ color: 'red' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title={'Approve'}>
              <IconButton
                aria-label="View"
                size="small"
                onClick={(e) => {
                  let rowId = Loanparams.RowID
                  setMobile(rowId)
                  // handleClickOpenReason()
                  if (
                    window.confirm(
                      `Confirm you want to approve the loan parameters`,
                    )
                  ) {
                    //resubmitDriver
                    handleSubmitReason('params')
                  } else {
                    // Do nothing!
                    console.log('secure')
                  }
                }}
              >
                <Done style={{ color: 'green' }} />
              </IconButton>
            </Tooltip>
          </>
        ),
        filter: false,
        sort: false,
      },
    },
  ]

  //blocked drivers
  const blockedListColumn = [
    {
      name: 'ReasonForUnblocking',
      label: 'Maker',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <small style={{ color: 'blue' }}>
            {value.split('*')[0]}
          </small>
        ),
      },
    },
    {
      name: 'MobileNumber',
      label: 'Phone Number',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'FirstName',
      label: 'First Name',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'ReasonForUnblocking',
      label: 'Reason',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <small style={{ color: 'blue' }}>
            {value.split('*')[1]}
          </small>
        ),
      },
    },
    {
      name: 'UniqueID',
      label: 'Actions',
      // isBank: true,
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            <Tooltip title={'Decline'}>
              <IconButton
                aria-label="View"
                size="small"
                style={{ marginRight: '10px' }}
                onClick={() => {
                  alert('confirm')
                }}
              >
                <DeleteForever style={{ color: 'red' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title={'Approve'}>
              <IconButton
                aria-label="View"
                size="small"
                onClick={() => {
                  let rowId = blockedList[tableMeta.rowIndex].RowID
                  setMobile(rowId)
                  handleClickOpenReason()
                  console.log(
                    '>>>>>>',
                    // value,
                    tableMeta.rowIndex,
                    // updateValue,
                  )
                  // alert('confirm')
                }}
              >
                <Done style={{ color: 'green' }} />
              </IconButton>
            </Tooltip>
          </>
        ),
        filter: false,
        sort: false,
      },
    },
  ]

  // pendingBlockColumn

  const pendingBlockColumn = [
    {
      name: 'BlockedBy',
      label: 'Blocked By',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <small style={{ color: 'blue' }}>
            {/* {value.split('*')[0]} */}
            {value}
          </small>
        ),
      },
    },
    {
      name: 'MobileNumber',
      label: 'Phone Number',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'FirstName',
      label: 'First Name',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'ReasonForBlocking',
      label: 'Reason',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <small style={{ color: 'blue' }}>
            {value.split('*')[1]}
          </small>
        ),
      },
    },
    {
      name: 'RowID',
      label: 'Actions',
      // isBank: true,
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            <Tooltip title={'Decline'}>
              <IconButton
                aria-label="View"
                size="small"
                style={{ marginRight: '10px' }}
                onClick={() => {
                  alert('confirm')
                }}
              >
                <DeleteForever style={{ color: 'red' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title={'Approve'}>
              <IconButton
                aria-label="View"
                size="small"
                onClick={(e) => {
                  // let rowId = Loanparams.RowID
                  // setMobile(rowId)
                  // handleClickOpenReason()
                  if (
                    window.confirm(
                      `Confirm you want to approve blocking of the driver`,
                    )
                  ) {
                    //resubmitDriver
                    handleSubmitReason('blocksu')
                  } else {
                    // Do nothing!
                    console.log('secure')
                  }
                }}
              >
                <Done style={{ color: 'green' }} />
              </IconButton>
            </Tooltip>
          </>
        ),
        filter: false,
        sort: false,
      },
    },
  ]

  return (
    <>
    
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        textColor="primary"
        indicatorColor="primary"
        style={{ margin: '20px' }}
      >
        <Tab
          label={`Users - (${
            users.filter(
              (data) =>
                data.DeletedOn === null &&
                data.Roles.includes('PENDING'),
            ).length
          })`}
          {...a11yProps(0)}
        />

        {/* <Tab
          label={`Block Customers - (${value})`}
          {...a11yProps(1)}
        /> */}
        
        <Tab
          label={`unBlock customers  - (${blockedList.filter(data => data.UnBlockedBy !== user.EMailID).length})`}
          {...a11yProps(1)}
        />

        <Tab
          label={`loan Parameters - (${Loanparams.UserID === user.EMailID ? '0' : Loanparamsdata.length})`}
          {...a11yProps(2)}
        />
        <Tab
          label={`Block customer  - (${pendingBlock.filter(data => data.BlockedBy !== user.EMailID).length})`}
          {...a11yProps(1)}
        />
      </Tabs>
      {value === 0 && (
        <MUIDataTable
          title={'Users'}
          data={users.filter(
            (data) =>
              data.DeletedOn === null &&
              data.Roles.includes('PENDING')&&
              data.UserID.split('*')[0] !== user.EMailID
              ,
          )}
          columns={columnsUsers}
          options={options}
        />
      )}

      {/* 
      {value === 10 && (
        <MUIDataTable
          title={'Customers'}
          data={data}
          columns={columns}
          options={options}
        />
      )} */}

      {value === 1 && (
        <MUIDataTable
          title={'Unblock requests'}
          data={blockedList.filter(data => data.UnBlockedBy !== user.EMailID)}
          columns={blockedListColumn}
          options={options}
        />
      )}

      {value === 2 && (
        <MUIDataTable
          title={'Loan Params'}
          data={Loanparams.UserID === user.EMailID ? [] : Loanparamsdata }
          columns={columnsParams}
          options={options}
        />
      )}

      {value === 3 && (
        <MUIDataTable
          title={'Block requests'}
          data={pendingBlock.filter(data => data.BlockedBy !== user.EMailID)}
          columns={pendingBlockColumn}
          options={options}
        />
      )}

      {/* confirm dialogue */}

      <div>
        {/* <Button variant="outlined" onClick={handleClickOpen}>
          Open form dialog
        </Button> */}
        <Dialog open={openReason} onClose={handleCloseReason}>
          <DialogTitle>Unblock Customer</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Describe the reason for unblocking the account, The
              customer will be able to access a loan after submission
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Reason"
              type="text"
              fullWidth
              variant="standard"
              onChange={(e) => handleChangeReason(e)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseReason}>Cancel</Button>
            <Button onClick={(e) => handleSubmitReason('block')}>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  )
}



