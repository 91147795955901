import {
  GET_NOTIFICATION,
  GET_NOTIFICATION_FAILED,
  GET_NOTIFICATION_SUCCESS,
} from './actionTypes'

//get notifications actions

export const getNotifications = (data) => {
  return {
    type: GET_NOTIFICATION,
    data,
  }
}

export const getNotificationsSuccess = (response) => {
  return {
    type: GET_NOTIFICATION_SUCCESS,
    response,
  }
}

export const getNotificationsFailed = (response) => {
  return {
    type: GET_NOTIFICATION_FAILED,
    response,
  }
}
