import React, { useEffect } from 'react'

// styles

import MUIDataTable from 'mui-datatables'
import moment from 'moment'
import { getRepayment } from './redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import NumberFormat from 'react-number-format'

//custom columns in the table

const columns = [
  {
    name: 'LoanID',
    label: 'Loan Id',
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => (
        <small style={{ fontSize: '10' }}>{value}</small>
      ),
    },
  },
  {
    name: 'FirstName',
    label: 'FirstName',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'ProductID',
    label: 'Product ID',
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => value,
    },
  },
  // {
  //   name: 'InterestAmount',
  //   label: 'InterestAmount',
  //   options: {
  //     filter: false,
  //     sort: false,
  //     customBodyRender: (value, tableMeta, updateValue) => (
  //       <small style={{ fontSize: '10' }}>{value}</small>
  //     ),
  //   },
  // },
  {
    name: 'TrxDate',
    label: 'Date',
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) =>
        value !== null ? (
          <small>{moment(value).format('MMM Do YY')}</small>
        ) : (
          <h1></h1>
        ),
    },
  },
  {
    name: 'Amount',
    label: 'Amount',
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => (
        <NumberFormat
          value={value}
          className="foo"
          displayType={'text'}
          thousandSeparator={true}
          prefix={''}
          renderText={(value, props) => <div {...props}>{value}</div>}
        />
      ),
    },
  },
  // {
  //   name: 'RejectReason',
  //   label: 'Reason',
  //   options: {
  //     filter: false,
  //     sort: false,
  //     customBodyRender: (value, tableMeta, updateValue) => (
  //       <small style={{ color: 'blue' }}>{value}</small>
  //     ),
  //   },
  // },
]

//data dummy

const data = [
  {
    name: 'Nme ',
    company: 'James',
    city: '0726862144',
    state: '33316691',
    word: '',
  },
]

const options = {
  filterType: 'dropdown',
  print: 'false',
  // download: 'false',
  selectableRows: false,
  filter: false,
  data: 'false',
  downloadOptions: {
    fileName: 'DeclinedLoansLittle',
  },
}

//create the statements reports and payments report

export default function LoanRepayments(props) {
  const repayments = useSelector(
    (state) => state.repayments.repayment,
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getRepayment('dtb'))
  }, [])

  return (
    <>
      <MUIDataTable
        title={'Loan Repayment'}
        data={repayments.filter((data) => data.Amount >= 0)}
        columns={columns}
        options={options}
      />
    </>
  )
}
