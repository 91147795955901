// /get blocked customers pending unblocking

export const GET_BLOCKED = 'GET_BLOCKED'
export const GET_BLOCKED_SUCCESS = 'GET_BLOCKED_SUCCESS'
export const GET_BLOCKED_FAILED = 'GET_BLOCKED_FAILED'

// GET_LIST_PARAMS

export const GET_LIST_PARAMS = 'GET_LIST_PARAMS'
export const GET_LIST_PARAMS_SUCCESS = 'GET_LIST_PARAMS_SUCCESS'
export const GET_LIST_PARAMS_FAILED = 'GET_LIST_PARAMS_FAILED'


///UNBLOCK A DRIVER
export const UNBLOCK_DRIVER = 'UNBLOCK_DRIVER'
export const UNBLOCK_DRIVER_SUCCESS = 'UNBLOCK_DRIVER_SUCCESS'
export const UNBLOCK_DRIVER_FAILED = 'UNBLOCK_DRIVER_FAILED'

// list blocked drivers

export const LIST_BLOCKED = 'LIST_BLOCKED'
export const LIST_BLOCKED_SUCCESS = 'LIST_BLOCKED_SUCCESS'
export const LIST_BLOCKED_FAILED = 'LIST_BLOCKED_FAILED'





