import React, { useEffect, useState } from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {
  Checkbox,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { setUser } from '../redux/actions'

export default function RadioButtonsGroup(props) {
  const dispatch = useDispatch()

  const handleCompany = (event) => {
    dispatch(setUser('bank', event.target.value))
    setCompany(event.target.value)
  }

  let su = useSelector((state) => state.login.su)
  const bankId = useSelector((state) => state.login.user.BankID)
  const Bank = useSelector((state) => state.login.user.BankName)
  const [radio, setradio] = useState('RESET?1')

  let user = useSelector((state) => state.addUser.user)
  const [value, setValue] = React.useState(user.role)
  const [company, setCompany] = React.useState('Little')
  const [usersRoles, setusersRoles] = React.useState({
    VA: false,
    AS: false,
    CR: false,
    AL: false,
    DL: false,
    LR: false,
    AU: false,
    DU: false,
  })

  const handleChangeRights = (event) => {
    let value = event.target.checked;
    let name = event.target.name

    switch (name) {
      case 'VA':
        dispatch(setUser('VA', value))
        break
      case 'AS':
        dispatch(setUser('AS', value))
        break
      case 'CR':
        dispatch(setUser('CR', value))
        break
      case 'AL':
        dispatch(setUser('AL', value))
        break
      case 'DL':
        dispatch(setUser('DL', value))
        break
      case 'LR':
        dispatch(setUser('LR', value))
        break
      case 'AU':
        dispatch(setUser('AU', value))
        break
      case 'BU':
        dispatch(setUser('BU', value))
        break
        case 'BC':
          dispatch(setUser('BC', value))
          break
          case 'RA':
            dispatch(setUser('RA', value))
            break
            case 'EP':
              dispatch(setUser('EP', value))
              break
              case 'UC':
              dispatch(setUser('UC', value))
              break
              case 'DLS':
                dispatch(setUser('DLS', value))
                break
                 case 'DLL':
                dispatch(setUser('DLL', value))
                break
      default:
        break
    }
  }

  const handleChange = (e) => {
    // alert(e.target.name)
    var name = e.target.name
    var value = e.target.value
    setradio(value)
    dispatch(setUser('roles', value))
  }

  const roles = [
    { id: 0, value: 'VA', title: 'View Analytics' },
    { id: 1, value: 'AS', title: 'Account Search' },
    { id: 2, value: 'CR', title: 'Customer Registration' },
    { id: 3, value: 'DLS', title: 'Disbursed Loans' },
    { id: 4, value: 'DLL', title: 'Declined Loans' },
    { id: 5, value: 'LR', title: 'Loans Repayment' },
    { id: 6, value: 'AU', title: 'Add User' },
    { id: 7, value: 'BU', title: 'Block User' },
    { id: 8, value: 'BC', title: 'Block Customer' },
    { id: 9, value: 'RA', title: 'Resubmit Account' },
    { id: 10, value: 'EP', title: 'Edit Loan Params' },
    { id: 11, value: 'UC', title: 'Unblock Customer' },
  ]

  //component did mount/update
  
  const updateUserRoles = () => {
    let theUser= props.user
    let userRole = theUser.Roles.includes('user') ? 'RESET?1' : 'RESET?0'
    setradio(userRole)
    // alert(JSON.stringify(theUser))
    for (let i = 0; i < roles.length; i++) {
      theUser.Roles.includes(roles[i].value) &&
      dispatch(setUser(roles[i].value, true))
    }
    dispatch(setUser('roles', userRole))
  }

  // the 
  useEffect(() => {
    updateUserRoles()
    // dispatch(setUser('bankId', bankId))
    // alert(JSON.stringify(user))
    return () => {
      // cleanup
    }
  }, [])

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        alignItems="top"
        justify="space-evenly"
      >
        <FormControl component="fieldset">
          <FormLabel
            style={{ marginBottom: '30px' }}
            component="legend"
          >
            Assign Role
          </FormLabel>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={radio}
            onChange={handleChange}
          >
            {/* {su ? (
              <FormControlLabel
                value="su"
                control={<Radio />}
                label="~S.U"
              />
            ) : null} */}
            <FormControlLabel
              value={"RESET?0"}
              control={<Radio color="primary" />}
              label="Supervisor"
              // disabled={!su}
            />
            {!su ? (
              <FormControlLabel
                value="RESET?1"
                control={<Radio color="primary" />}
                label="User"
                // disabled={!su}
              />
            ) : null}
          </RadioGroup>
          {/* {su && value == 'admin' ? (|| props.user.Roles.includes(role.value)
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={user.bank}
              onChange={handleCompany}
            >
              <MenuItem value={'Little'}>NON </MenuItem>
              <MenuItem value={'Sidian'}>SIDIAN</MenuItem>
              <MenuItem value={'DTB'}>DTB</MenuItem>
            </Select>
          ) : (
            <Typography color="primary" variant="body1">
              {Bank}
            </Typography>
          )} */}
        </FormControl>

        <FormGroup>
          {roles.map((role) => (
            <FormControlLabel
              key={role.id}
              control={<Checkbox color="primary"
              defaultChecked={props.user.Roles.includes(role.value)}
               checked={user[role.value]}/>}
              label={role.title}
              name={role.value}
              onChange={handleChangeRights}
            />
          ))}
        </FormGroup>
      </Grid>
    </React.Fragment>
  )
}
