import React, { useState, useEffect } from 'react'
import {
  Grid,
  Select,
  MenuItem,
  Button,
  IconButton,
  Paper,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import moment from 'moment'

// styles

import useStyles from './styles'
import MUIDataTable from 'mui-datatables'
import Chip from '@material-ui/core/Chip'
import { Autorenew, Close, Visibility } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
  getBankCustomers,
  setCustomer,
} from '../../components/Layout/redux/actions'
import SyncLoader from 'react-spinners/SyncLoader'
import CustomToolbar from './CustomToolbar'
import TextField from '@material-ui/core/TextField'
import { useJsonToCsv } from 'react-json-csv'
import { toggleFilter } from './redux/actions'

//custom columns in the table

//data dummy
//
export default function Drivers(props) {
  var date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth()
  var firstDay = new Date(y, 0, 1)
  var lastDay = new Date(y, m + 1, 0)

  firstDay = moment(firstDay).format('MM/DD/YYYY')
  lastDay = moment(lastDay).format('MM/DD/YYYY')

  var classes = useStyles()
  var theme = useTheme()
  let su = useSelector((state) => state.login.su)
  let customers = useSelector((state) => state.layout.customers)
  let bankId = useSelector((state) => state.layout.bankId)
  let error = useSelector((state) => state.layout.error)
  let loading = useSelector((state) => state.layout.loading)
  let fetched = useSelector((state) => state.layout.fetched)
  const count = useSelector((state) => state.layout.count)
  const [pages, setpages] = useState(0)
  const user = useSelector((state) => state.login.user)
  const drivers = useSelector((state) => state.layout.drivers)
  const open = useSelector((state) => state.driversReducer.isOpen)
  const [from, setFrom] = useState(firstDay)
  const [to, setTo] = useState(lastDay)
  const [Status, setStatus] = useState('ALL')
  const currentData = useSelector(
    (state) => state.driversReducer.currentData,
  )

  //data handlers
  const dispatch = useDispatch()

  useEffect(() => {
    //component did mount, get customers with specific default filter
    var dateto = new Date(to)
    var datefrom = new Date(from)
    var status = Status

    var pagination = {}

    var data = {
      query: {
        dateFrom: datefrom,
        dateTo: dateto,
        status: status,
      },
      pagination: {
        page: pages,
      },
      user,
    }

    dispatch(getBankCustomers(data))

    // if (!fetched) {
    //   console.log('initial load extention')
    // }
  }, [])

  //table options

  const { saveAsCsv } = useJsonToCsv()

  const options = {
    // responsive: 'vertical',
    // count: count,
    // rowsPerPage: 10,
    // rowsPerPageOptions: [50],
    onTableChange: (action, tableState) => {
      console.log(action, tableState)

      //alert(action);
      // a developer could react to change on an action basis or
      // examine the state as a whole and do whatever they want

      switch (action) {
        case 'changePage':
          setpages(tableState.page)
          //  alert(tableState.page);
          // dispatch(getBankCustomers(su ? "all": bankId , tableState.page))
          break
        case 'search':
          setpages(tableState.page)

          // alert("oya");
          // dispatch(getBankCustomers(su ? "all": bankId , tableState.page))

          break
        case 'sort':
          // dispatch(getBankCustomers(su ? "all": bankId, tableState.page));
          break
        case 'filterChange':
          console.log('the table state is', tableState.filterList[5])

          //dispatch(getBankCustomers(su ? "all": bankId, tableState.page));

          break
        default:
          console.log('action not handled.' + action)
      }
    },
    filterType: 'dropdown',
    print: 'false',
    download: 'false',
    selectableRows: false,
    filter: false,

    // downloadOptions:
    // {filename: 'CustomersDataLittle.csv', separator: ',',
    // filterOptions:{useDisplayedColumnsOnly: true, useDisplayedRowsOnly: true}
    // },

    elevation: 7,
    fixedHeader: true,
    customToolbar: () => {
      return <CustomToolbar />
    },
  }

  const handleChange = (e) => {
    // alert(e.target.value);

    switch (e.target.name) {
      case 'from':
        setFrom(e.target.value)
        break
      case 'to':
        setTo(e.target.value)
        break
      case 'status':
        setStatus(e.target.value)
        break
      default:
        console.log(e.target.value)
      // code block
    }
  }

  //handleFilter by fetching the data and passing params

  const handleFilter = (type) => {
    var dateto = new Date(to)
    var datefrom = new Date(from)
    var status = Status
    var pagination = {}
    // alert(Status);

    if (to === '' || from === '') {
      alert('To and from date are required')
    } else {
      // dispatch(getBankCustomers(user , "all"))
      //Just run the

      // let filteredData = customers.filter(item => new Date(item.CreatedOn).getTime() >= datefrom.getTime() && new Date(item.CreatedOn) <= dateto.getTime())
      // status === 'ALL' ?  dispatch(getBankCustomersSuccess(filteredData,'200',drivers))
      // : dispatch(getBankCustomersSuccess(filteredData.filter(data => JSON.parse( data['BankAccountStatuses.OutputData']).message === Status),'200',drivers))

      var mystatus = Status
      // var pagination = {}

      var data = {
        query: {
          dateFrom: datefrom,
          dateTo: dateto,
          status: mystatus,
        },
        paggination: {
          page: pages,
        },
        user,
      }

      dispatch(getBankCustomers(data))
      if (type === 'fetch') {
        console.log('getting request redirect')
        //response, count, drivers
      } else {
        // handleDownload
        // let filteredData = customers.filter(item => new Date(item.CreatedOn).getTime() >= datefrom.getTime() && new Date(item.CreatedOn) <= dateto.getTime())
        //  AccountNumber,CustomerNumber,DateCreated,CustomerName,IPRSName,IDNumber,Nationality,Residence,DateOfBirth,EmailAddress,KRAPinNumber,USConfirmation,Address,PhotoIDMatchStatus,MnoName

        function createData(array) {
          var downloadData = []

          // let i;
          // Loop through array values
          // CustomerID

          for (let i = 0; i < array.length; i++) {
            //  MobileNumber
            let data = customers[i]
            let newObject = {
              AccountNumber: data.CustomerID,
              CustomerNumber: JSON.parse(
                data['BankAccountStatuses.InputData'],
              ).MobileNumber,
              DateCreated: moment(data.CreatedOn).format('MMM Do YY'),
              CustomerName: `${data.FirstName} ${data.MiddleName} ${data.LastName}`,
              IPRSName: `${data.FirstName} ${data.MiddleName} ${data.LastName}`,
              IDNumber: data.IDNumber,
              Nationality: 'KE',
              Residence: JSON.parse(
                data['BankAccountStatuses.InputData'],
              ).PhysicalAddress,
              DateOfBirth: data.DateOfBirth,
              EmailAddress: data.EMailID,
              KRAPinNumber: JSON.parse(
                data['BankAccountStatuses.InputData'],
              ).KRAPin,
              USConfirmation: 'No',
              Address: JSON.parse(
                data['BankAccountStatuses.InputData'],
              ).PhysicalAddress,
              PhotoIDMatchStatus: 'Yes',
              MnoName: `${data.FirstName} ${data.MiddleName} ${data.LastName}`,
            }
            downloadData.push(newObject)
          }
          return downloadData
        }

        const myData = createData(customers)

        // console.log("My data>>>>>>>>", myData);
        // console.log()

        const filename = 'Customer Registration',
          fields = {
            AccountNumber: 'AccountNumber',
            CustomerNumber: 'CustomerNumber',
            DateCreated: 'DateCreated',
            CustomerName: 'CustomerName',
            IPRSName: 'IPRSName',
            IDNumber: 'IDNumber',
            Nationality: 'Nationality',
            Residence: 'Residence',
            DateOfBirth: 'DateOfBirth',
            EmailAddress: 'EmailAddress',
            KRAPinNumber: 'KRAPinNumber',
            USConfirmation: 'USConfirmation',
            Address: 'Address',
            PhotoIDMatchStatus: 'PhotoIDMatchStatus',
            MnoName: 'MnoName',
          },
          style = {
            padding: '5px',
          },
          data = [...myData],
          text = 'Convert Json to Csv'
        saveAsCsv({ data, fields, filename })
      }
    }
  }

  //columns

  const columns = [
    {
      name: 'LittleBankAccounts.AccountID',
      label: 'Account',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'CreatedOn',
      label: 'Date',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) =>
          value !== null ? (
            <small>{moment(value).format('MMM Do YY')}</small>
          ) : (
            <h1></h1>
          ),
      },
    },

    {
      name: 'FirstName',
      label: 'Name',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'LastName',
      label: 'Second Name',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'BankID',
      label: 'Company',
      su: true,
      options: {
        filter: true,

        sort: true,
      },
    },
    {
      name: 'IDNumber',
      label: 'ID No.',
      options: {
        filter: false,
        sort: true,
      },
    },



    {
      name: 'IDNumber',
      label: 'Status',
      options: {
        customBodyRender: (value, tableMeta, updateValue) =>
          customers[customers.findIndex((p) => p.IDNumber === value)][
            'LittleBankAccounts.RowID'
          ] === null ? (
            <Chip label={'pending'} size="small" />
          ) : customers[
            customers.findIndex((p) => p.IDNumber === value)
          ]['LittleBankAccounts.AccountID'] !== null &&
            customers[
            customers.findIndex((p) => p.IDNumber === value)
            ]['LittleBankAccounts.AccountToken'] !== null ? (
            customers[
              customers.findIndex((p) => p.IDNumber === value)
            ]['LittleBankAccounts.Blocked'] ? (
              <Chip
                label={'blocked'}
                size="small"
                color="primary"
                style={{ backgroundColor: 'red', color: '#ffffff' }}
              />
            ) : (
              <Chip label={'success'} size="small" color="primary" />
            )
          ) : (
            <Chip
              label={'otp'}
              size="small"
              color="secondary"
              style={{
                backgroundColor: '#000000',
                color: '#ffffff',
                width: '60px',
              }}
            />
          ),
        filter: false,
        sort: true,
      },
    },

    {
      name: 'UniqueID',
      label: 'Actions',
      // isBank: true,
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <IconButton
            aria-label="View"
            size="small"
            onClick={() =>
              dispatch(
                setCustomer(
                  props.history,
                  customers[
                  customers.findIndex((p) => p.UniqueID === value)
                  ],
                ),
              )
            }
          >
            <Visibility />
          </IconButton>
        ),
        filter: false,
        sort: false,
      },
    },
  ]

  return (
    <>
      {open && (
        <Grid container style={{ marginBottom: '10px' }}>
          <IconButton
            className={classes.iconButton}
            style={{ top: '0', right: '0' }}
            onClick={(e) => dispatch(toggleFilter(!open))}
          >
            <Close />
          </IconButton>
          <Grid item xs={12} spacing={3}>
            <Paper style={{ backgroundColor: '#C0C0C0' }}>
              <form
                className={classes.formFilter}
                noValidate
                autoComplete="off"
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant="outlined"
                  name="status"
                  value={Status}
                  onChange={(e) => handleChange(e)}
                >
                  <MenuItem value={'ALL'}>All registrations</MenuItem>
                  <MenuItem value={'SUCCESS'}>
                    Successful registrations
                  </MenuItem>
                  <MenuItem value={'PENDING'}>
                    Pending registrations
                  </MenuItem>
                  <MenuItem value={'VALIDATE'}>
                    Pending OTP validation
                  </MenuItem>
                </Select>

                <TextField
                  id="date"
                  label="From"
                  name="from"
                  variant="outlined"
                  onChange={(e) => handleChange(e)}
                  value={from}
                  type="date"
                  defaultValue="2021-01-21"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <TextField
                  id="date"
                  label="From"
                  type="date"
                  variant="outlined"
                  onChange={(e) => handleChange(e)}
                  name="to"
                  // defaultValue="2021-01-21"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  style={{ height: '100%' }}
                  onClick={() => handleFilter('fetch')}
                >
                  Fetch
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ height: '40px', width: '90px' }}
                  onClick={() => handleFilter('download')}
                >
                  Download
                </Button>
              </form>
            </Paper>
          </Grid>
        </Grid>
      )}
      <MUIDataTable
        title={
          !loading ? (
            <Button
              variant="text"
              color="primary"
              redux-persist
              onClick={() => {
                var dateto = new Date(to)
                var datefrom = new Date(from)
                var status = Status
                var pagination = {}
                var data = {
                  query: {
                    dateFrom: datefrom,
                    dateTo: dateto,
                    status: status,
                  },
                  pagination: {
                    page: pages,
                  },
                  user,
                }

                dispatch(getBankCustomers(data))
              }}
              className={classes.button}
              startIcon={<Autorenew />}
            >
              Drivers
            </Button>
          ) : (
            <SyncLoader color={'#536DFE'} size={5} />
          )
        }
        data={customers}
        columns={
          su
            ? columns.filter((data) => !data.isBank)
            : columns.filter((data) => !data.su)
        }
        options={{
          ...options,
          textLabels: {
            body: {
              noMatch: 'No records found: ' + error,
            },
          },
        }}
      />
    </>
  )
}
