// import { createStore, applyMiddleware } from 'redux'
// import createSagaMiddleware from 'redux-saga';
// import rootReducer from "./RootReducer";

// // ...
// // import { helloSaga } from './sagas'

// const sagaMiddleware = createSagaMiddleware()
// const store = createStore(
//   rootReducer,
//   applyMiddleware(sagaMiddleware)
// )

import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootReducer from './RootReducer'
import RootSaga from './RootSaga'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { persistStore, persistReducer } from 'redux-persist' // imports from redux-persist

///////

const persistConfig = {
  // configuration object for redux-persist
  key: 'root',
  storage, // define which storage to use
  //stateReconciler: hardSet,
  whitelist: ['login'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer) // create a persisted reducer

const sagaMiddleware = createSagaMiddleware()
const middleware = [sagaMiddleware]

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // options like actionSanitizer, stateSanitizer
    })
  : compose
const enhancer = composeEnhancers(
  applyMiddleware(...middleware),
  // other store enhancers if any
)

const store = createStore(persistedReducer, enhancer)
sagaMiddleware.run(RootSaga)
const persistor = persistStore(store)

export { store, persistor }
