import React, { useEffect } from 'react'
import SyncLoader from 'react-spinners/SyncLoader'

import { Button, IconButton, Avatar } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

// styles

import useStyles from './styles'
import MUIDataTable from 'mui-datatables'
import Chip from '@material-ui/core/Chip'
import { Autorenew, Visibility } from '@material-ui/icons'
import { getBanks } from './redux/actions'
import { useDispatch, useSelector } from 'react-redux'

//custom columns in the table

const columns = [
  {
    name: 'image',
    label: 'Logo',

    options: {
      customBodyRender: (value, tableMeta, updateValue) => (
        <Avatar src={value} />
      ),
      filter: false,
      sort: true,
    },
  },
  {
    name: 'date',
    label: 'Created At',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'name',
    label: 'Name',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'admin',
    label: 'Admin',
    options: {
      filter: false,
      sort: true,
    },
  },

  {
    name: 'users',
    label: 'Users',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'drivers',
    label: 'Drivers',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'reg',
    label: 'Registrations',
    options: {
      filter: false,
      sort: true,
    },
  },

  {
    name: 'checks',
    label: 'Failed Checks',
    options: {
      filter: false,
      sort: true,
    },
  },

  {
    name: 'status',
    label: 'Status',
    options: {
      customBodyRender: (value, tableMeta, updateValue) =>
        value ? (
          <Chip
            label={'Active'}
            size="small"
            style={{ backgroundColor: 'green', color: '#ffffff' }}
          />
        ) : (
          <Chip label={'Pending'} size="small" color="primary" />
        ),
      filter: true,
      sort: true,
    },
  },
  {
    name: 'word',
    label: 'Actions',
    options: {
      customBodyRender: (value, tableMeta, updateValue) => (
        <IconButton aria-label="View" size="small" disabled>
          <Visibility />
        </IconButton>
      ),
      filter: false,
      sort: false,
    },
  },
]

//data dummy

const data = [
  {
    name: 'DTB',
    image:
      'http://localhost:3000/static/media/small_dtb.e8d04c59.png',
    date: '05-03-2021',
    admin: 'admin@themail.com',
    users: 4,
    drivers: 2,
    reg: 0,
    checks: 0,
    status: true,
  },
  {
    name: 'Sydian',
    image:
      'https://www.sidianbank.co.ke/wp-content/uploads/2019/05/SIdian-Bank-Logo-White152.png',
    date: '05-03-2021',
    admin: 'admin@themail.com',
    users: 4,
    drivers: 2,
    reg: 0,
    checks: 0,
    status: true,
  },
]

const options = {
  filter: false,
  download: false,
  print: 'false',
  search: false,
  selectableRows: false,
  downloadOptions: {
    filename: 'LittleRideData.csv',
    separator: ',',
    filterOptions: {
      useDisplayedColumnsOnly: true,
      useDisplayedRowsOnly: true,
    },
  },
  elevation: 7,
  fixedHeader: false,
  // customToolbar: () => {
  //   return <CustomToolbar />;
  // }
}

////

export default function Banks(props) {
  var classes = useStyles()
  var theme = useTheme()
  //initialize dispatch
  const dispatch = useDispatch()
  ///get reducer data
  const banks = useSelector((state) => state.banks)

  //component did mount or update

  useEffect(() => {
    dispatch(getBanks({}))
    return () => {
      // cleanup
    }
  }, [])

  return (
    <>
      <MUIDataTable
        title={
          !banks.loading ? (
            <Button
              variant="text"
              color="primary"
              onClick={() => dispatch(getBanks('su ? "all": bankId'))}
              className={classes.button}
              startIcon={<Autorenew />}
            >
              Registered Banks
            </Button>
          ) : (
            <SyncLoader color={'#536DFE'} size={5} />
          )
        }
        data={banks.banks}
        columns={columns}
        options={{
          ...options,
          textLabels: {
            body: {
              noMatch: 'No records found: ' + banks.error,
            },
          },
        }}
      />
    </>
  )
}
