import axios from 'axios'

export async function listBlockeCall(mobile) {

  // alert(JSON.stringify(mobile))
  
  const data = {
    payload: {
      FormID: 'BANK.LISTUNBLOCKAGENTS',
      City: 'Nairobi',
      Country: 'Kenya',
      UserID: mobile.EmailID,
      BlockAgent: {
        BankID: 'dtb',
      },
    },
  }

  return await axios.post(
    `https://stats.little.bz/banks/process`,
    JSON.stringify(data),
    {
      headers: {
        authorization: `${localStorage.getItem('id_token')}`,
        'Content-Type': 'application/json',
      },
    },
  )
}

// listParamsCall

export async function listParamsCall(mobile) {
  // alert(JSON.stringify(mobile))
  var today = new Date()
  const data = {
    payload: {
      FormID: 'GETLISTCHARGES',
      City: 'Nairobi',
      Country: 'Kenya',
      UserID: 'brianmburu@dtb.com',
      TrxReport: {
        UserID: 'brianmburu@dtb.com',
        BankID: 'dtb',
      },
    },
  }
  return await axios.post(
    `https://stats.little.bz/banks/process`,
    JSON.stringify(data),
    {
      headers: {
        authorization: `${localStorage.getItem('id_token')}`,
        'Content-Type': 'application/json',
      },
    },
  )
}

// blockedDriversCall

export async function blockedDriversCall(mobile) {
  // alert("worked ")
  var today = new Date()
  const data = {
    payload: {
      FormID: "BANK.LISTBLOCKAGENTS",
      City: "Nairobi",
      Country: "Kenya",
       UserID: mobile.EMailID,
      BlockAgent: {
        BankID: "dtb",
        ActionDate: "25 Nov 2021"
      }
    }
  }
  return await axios.post(
    `https://stats.little.bz/banks/process`,
    JSON.stringify(data),
    {
      headers: {
        authorization: `${localStorage.getItem('id_token')}`,
        'Content-Type': 'application/json',
      },
    },
  )
}

//unblockdriverCall

export async function unblockdriverCall(customer) {
  // alert(JSON.stringify(customer))
  var today = new Date()
  let data 
  if(customer.type === 'block'){
    data = {
      payload: {
        FormID: 'BANK.SUPERVISEBLOCKAGENTS',
        City: 'Nairobi',
        UserID:  customer.user,
        Country: 'Kenya',
        BlockAgent: {
          RowID: customer.rowID,
          BankID: 'dtb',
        },
      },
    }
  }else if(customer.type === 'blocksu'){
     data = {
      payload: {
        FormID: "BANK.BLOCKAGENT",
        City: "Nairobi",
        Country: "Kenya",
        UserID:  customer.user,
        BlockAgent: {
          MobileNumber: "254715994345",
          BankID: "dtb",
          Reason: "defaulted",
          ActionDate: "24 Nov 2021"
        }
      }
    }
    
  }else if(customer.type === 'params'){
    data = {
      payload: {
        FormID: "BANK.SUPERVISEPRODUCTCHARGES",
        City: "Nairobi",
        Country: "Kenya",
        UserID: customer.user,
        TrxReport: {
          UserID: customer.user,
          BankID: "dtb",
          RowID: "1"
        }
      }
   }
  }
  else{
    data = {
     payload: {
       FormID: "BANK.SUPERVISEPRODUCTCHARGES",
       City: "Nairobi",
       Country: "Kenya",
       UserID:  customer.user,
       TrxReport: {
         UserID: customer.user,
         BankID: "dtb",
         RowID: "1",
       }
     }
   }
 }
  
  return await axios.post(
    `https://stats.little.bz/banks/process`,
    JSON.stringify(data),
    {
      headers: {
        authorization: `${localStorage.getItem('id_token')}`,
        'Content-Type': 'application/json',
      },
    },
  )
}
