import React, { useState } from 'react'
import {
  Grid,
  Divider,
  Avatar,
  Paper,
  Chip,
  Badge,
  Typography,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  IconButton,
  TextField,
  ButtonGroup,
  Button,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

// styles

import useStyles from './styles'
import {
  ArrowBack,
  Close,
  Comment,
  Done,
  Send,
} from '@material-ui/icons'
import { Link } from 'react-router-dom'
// import {PDFtoIMG} from 'react-pdf-to-image';

const handleMouseDownPassword = (event) => {
  event.preventDefault()
}

//// image link
const imgLink =
  'https://images.pexels.com/photos/1681010/pexels-photo-1681010.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260'

export default function Documents(props) {
  var classes = useStyles()
  var theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const [opena, setOpena] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClickOpena = () => {
    setOpena(true)
  }

  const handleClose = () => {
    setOpen(false)
    setOpena(false)
  }

  return (
    <div className={classes.root}>
      <div>
        <Grid container spacing={2}>
          <Grid container>
            {/* the */}
            <Link to="/app/driver">
              <IconButton
                color="primary"
                aria-label="add to shopping cart"
              >
                <Tooltip title={'Back'}>
                  <ArrowBack />
                </Tooltip>
              </IconButton>
            </Link>
          </Grid>
          <Grid style={{ height: '600px' }} item xs={12} sm={8}>
            {/* document view section */}
            <Paper className={classes.paper}>
              2/5
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="center"
                style={{ marginTop: 4 }}
                spacing={3}
              >
                <Grid item>
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                  >
                    <Chip
                      size="small"
                      label={'KRA PIN[pdf]'}
                      color="primary"
                    />
                  </Typography>
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                  >
                    {'Timothy Kibe | ID: 33333333'}
                  </Typography>
                </Grid>

                <Grid item>
                  <ButtonGroup
                    color="primary"
                    aria-label="outlined primary button group"
                  >
                    <Button>pre</Button>
                    <Button>next</Button>
                    <Button
                      variant="contained"
                      startIcon={<Done />}
                      onClick={handleClickOpena}
                    >
                      APPROVE
                    </Button>
                    <Button
                      variant="contained"
                      startIcon={<Close />}
                      color="secondary"
                      onClick={handleClickOpen}
                    >
                      DECLINE
                    </Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
              <Divider
                variant="fullWidth"
                style={{ margin: '10px 0' }}
              />
              {/* Actual document section */}
              {/* <img src="https://nypost.com/wp-content/uploads/sites/2/2020/01/sauntore-thomas-04.jpg?quality=80&strip=all&w=618&h=410&crop=1" 
              style={{width: "100%", height: "80%"}}/> */}
              <object
                data="http://www.africau.edu/images/default/sample.pdf"
                width="100%"
                height="80%"
              ></object>
              <Grid />
            </Paper>
          </Grid>
          {/* Comments section */}
          <Grid item xs={12} sm={4}>
            <Paper className={classes.paper}>
              <Badge badgeContent={2} color="secondary" size="small">
                <Comment />
              </Badge>
              <Typography>{'Comment(s)'}</Typography>
              <Grid container style={{ marginTop: 15 }}>
                <Grid item xs={12}>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={'text'}
                    multiline
                    variant="outlined"
                    placeholder="Type comment..."
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Send"
                          edge="end"
                          onMouseDown={handleMouseDownPassword}
                        >
                          {<Send />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                wrap="nowrap"
                spacing={2}
                style={{ marginTop: '20px' }}
              >
                <Grid item>
                  <Avatar alt="Little" src={imgLink} />
                </Grid>
                <Grid justifyContent="left" item xs zeroMinWidth>
                  <h4 style={{ margin: 0, textAlign: 'left' }}>
                    Admin Admin
                  </h4>
                  <p style={{ textAlign: 'left' }}>
                    The back of this this is not this way, kindly this
                    this this person{' '}
                  </p>
                  <p style={{ textAlign: 'left', color: 'gray' }}>
                    posted 1 minute ago
                  </p>
                </Grid>
              </Grid>
              <Divider
                variant="fullWidth"
                style={{ margin: '10px 0' }}
              />

              <Grid
                container
                wrap="nowrap"
                spacing={2}
                style={{ marginTop: '20px' }}
              >
                <Grid item>
                  <Avatar alt="Little" src={imgLink} />
                </Grid>
                <Grid justifyContent="left" item xs zeroMinWidth>
                  <h4 style={{ margin: 0, textAlign: 'left' }}>
                    Admin Admin
                  </h4>
                  <p style={{ textAlign: 'left' }}>
                    The back of this this is not this way, kindly this
                    this this person{' '}
                  </p>
                  <p style={{ textAlign: 'left', color: 'gray' }}>
                    posted 1 minute ago
                  </p>
                </Grid>
              </Grid>
              <Divider
                variant="fullWidth"
                style={{ margin: '10px 0' }}
              />
            </Paper>
          </Grid>
        </Grid>
      </div>

      {/* Dialogue decline*/}
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Timothy Kibe | KRA ~ pdf
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to decline this document?
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Comment"
              multiline
              variant="outlined"
              type="text"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleClose} color="primary" disabled>
              Decline
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      {/* Dialogue accept */}

      <div>
        <Dialog
          open={opena}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Timothy Kibe | KRA ~ pdf
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to approve this?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleClose} color="primary">
              Approve
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  )
}
