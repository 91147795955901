import {
  BLOCK_DRIVER,
  BLOCK_DRIVER_FAILED,
  BLOCK_DRIVER_SUCCESS,
  RESET_MESSAGE,
  RESUBMIT_APPLICATION,
  RESUBMIT_APPLICATION_FAILED,
  RESUBMIT_APPLICATION_SUCCESS,
} from './actionTypes'

const InitialState = {
  //  registrations: {},
  //  percentage: {},
  //  monthly: {},
  data: [],
  loading: false,
  error: 'Loading...',
  message: '',
}

export default function DriverReducer(state = InitialState, action) {
  switch (action.type) {
    //create agents

    case RESUBMIT_APPLICATION:
      return {
        ...state,
        loading: true,
      }

    case RESUBMIT_APPLICATION_FAILED:
      return {
        ...state,

        loading: false,
        message: 'Please try again ',
      }

    case RESUBMIT_APPLICATION_SUCCESS:
      return {
        ...state,
        error: action.results,
        loading: false,
        message: action.res,
        response: false,
      }
    case BLOCK_DRIVER:
      return {
        ...state,
        loading: true,
      }

    case BLOCK_DRIVER_SUCCESS:
      return {
        ...state,
        error: action.results,
        loading: false,
        message: action.res,
        response: true,
      }

    case BLOCK_DRIVER_FAILED:
      return {
        ...state,

        loading: false,
        message: 'Please try again ',
      }

    case RESET_MESSAGE:
      return {
        ...state,

        message: '',
      }

    default:
      return state
  }
}
