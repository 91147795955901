import axios from 'axios'

// const config = {
//   headers: { Authorization: `Bearer ${token}` }
// };

// const bodyParameters = {
//  key: "value"
// };

// Axios.post(
// 'http://localhost:8000/api/v1/get_token_payloads',
// bodyParameters,
// config
// ).then(console.log).catch(console.log);

// let user = JSON.stringify({
//   email: 'kamal@craftsilicon.com',
//   password: '123456'
// })
//read datas
/////

export async function loginCall(data) {
  return await axios.post(
    `https://stats.little.bz/login/bank`,
    JSON.stringify({
      email: data.email,
      password: data.password,
    }),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}
