import {
  GET_DECLINED,
  GET_DECLINED_FAILED,
  GET_DECLINED_SUCCESS,
} from './actionTypes'

const InitialState = {
  rejected: [],
  loading: false,
  error: 'Loading...',
}

export default function RejectedLoansReducer(
  state = InitialState,
  action,
) {
  switch (action.type) {
    //create agents

    case GET_DECLINED:
      return {
        ...state,
        loading: true,
      }

    case GET_DECLINED_SUCCESS:
      return {
        ...state,
        rejected: action.response,
        loading: false,
      }

    case GET_DECLINED_FAILED:
      return {
        ...state,
        error: action.results,
        loading: false,
      }

    default:
      return state
  }
}
