import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 50,
    textAlign: 'center',
    color: theme.palette.text.primary,
    margin: 'auto',
    height: '540px',
  },
}))
