import React, { useState } from 'react'
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Button,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

// styles

import useStyles from './styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

export default function Faq(props) {
  var classes = useStyles()
  var theme = useTheme()

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            How do I add a new <b>User</b>?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Click <b>Users</b> option in the left side bar options,
            you will see a <b>Table</b> with the users details, in the
            top right corner of that table, the is a <b>+</b> button,
            click it and fill the form as required,{' '}
            <b>
              input the correct email because the added user will
              receive an email with the login instructions.
            </b>
            When you submit the form, and the user is logged in, you
            will be able to see the active status under the users
            table.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>
            When is a <b>Notification</b> sent?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            For the <b>Admin</b> he/she, receives a notification when
            a customers details that the admin <b>Declines</b> is
            updated and submitted for review. for the <b>Reviewer</b>{' '}
            a notification is sent when a <b>Customer's</b> details
            that he/she approved is
            <b>Declined by the Admin</b>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>
            How to get <b>Loan Applications</b> details
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Under the <b>Left</b> side menu, there is a{' '}
            <b>Loan Applications</b> option wish displays a table with
            the loan applications data. You can
            <b>Filter, Search, Download CSV</b> view the general loan
            applications.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>
            How to change your <b>password</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            To change your password, Click <b>LogOut</b> button at the
            top right conner, It will redirect you to the LogIn
            screen, select Forgot password and you will be prompted to
            input your email after-which you will receive an email
            with a secure link to change your password
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>
            How to disable a <b>User</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            To disable a user, means the user will not be able to
            login to the portal unless you re-enable the user again,
            select <b>Users</b> then Clicking the{' '}
            <b>Disable icon under the users table </b> after which a
            confirm dialogue will appear so that you ensure it is the
            correct user, click proceed and the user will not be able
            to access the portal.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>
            How to Get the customers' <b>documents</b>{' '}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            To check and to download a customers documents, select{' '}
            <b>Drivers</b> then view the individual driver details by
            Clicking the <b>View Icon</b> under actions, the drivers
            detail page will appear, click the <b>download all</b>{' '}
            button and the individual driver's documents will be{' '}
            <b>zipped</b>.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>
            How to communicate directly to the <b>customer</b>{' '}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            On the side bar, select Drivers and click the actions view
            button to see the individual driver's details, Under About
            section, there is the drivers contact details, Only
            communicate matters <strong>registration</strong>.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  )
}
