//login constants

//login action types
//export const SET_USER = "SET_USER";
export const GET_PARAMS = 'GET_PARAMS'
export const GET_PARAMS_SUCCESS = 'GET_PARAMS_SUCCESS'
export const GET_PARAMS_FAILED = 'GET_PARAMS_FAILED'
export const SET_PARAMS = 'SET_PARAMS'

//
export const UPDATE_PARAMS = 'UPDATE_PARAMS'
export const UPDATE_PARAMS_SUCCESS = 'UPDATE_PARAMS_SUCCESS'
export const UPDATE_PARAMS_FAILED = 'UPDATE_PARAMS_FAILED'
