import { call, put } from 'redux-saga/effects'
// import history from './history';
//import { uistartLoading, uistopLoading } from "../Ui/actions";
// import { useHistory } from "react-router-dom";
import { loginCall } from './api'
import { changePass, loginFailed, loginSuccess } from './actions'
import { toast } from 'react-toastify'

//login saga
// let history = useHistory();

export function* loginSaga(action) {
  try {
    const res = yield call(loginCall, action.data)
    console.log('Login console', res)
    localStorage.setItem('id_token', res.data.token)

    yield put(loginSuccess(res.data.user))
    // UserID
    if (res.data.user.DeletedOn === null) {
      if (res.data.user.Roles.includes('PENDING')) {
        // alert('Please Change your Password')
        toast('Your Account has not been approved')
        localStorage.removeItem('id_token')
      } else if(res.data.user.Roles.includes('RESET')){
        toast('Please Change your Password')
        yield put(changePass(true))
      }else{
        action.history.push('/')
      }
    } else {
      action.history.push('/')
      // toast('Unable to authenticate, Please contact Admin')
    }

    // alert("success");
  } catch (err) {
    yield put(loginFailed(err.message))
    console.log('Login error', err)
    // alert("an error occurred" + err.message);
    toast(
      'An error Occurred, ' +
        (err.message === 'Network Error'
          ? 'Connection Lost!'
          : 'Incorrect Email or Password'),
    )
  }
}
