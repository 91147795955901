import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  CHANGE_PASS,
} from './actionTypes'

//login actions
export const loginRequest = (data, history) => {
  return {
    type: LOGIN_REQUEST,
    data,
    history,
  }
}
export const loginSuccess = (response) => {
  return {
    type: LOGIN_SUCCESS,
    response,
  }
}
export const loginFailed = (response) => {
  return {
    type: LOGIN_FAILED,
    response,
  }
}

export const changePass = (data) => {
  return {
    type: CHANGE_PASS,
    data,
  }
}
