import React, { useState, useEffect } from 'react'
import { Drawer, IconButton, List } from '@material-ui/core'
import {
  Home as HomeIcon,
  NotificationsNone as NotificationsIcon,
  FilterNone as UIElementsIcon,
  ArrowBack as ArrowBackIcon,
  Group,
  Help,
  ContactPhone,
  AirlineSeatReclineNormal,
  AttachMoney,
  Lock,
  AccountBalance,
} from '@material-ui/icons'
import { useTheme } from '@material-ui/styles'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'

//grid layout

// styles
import useStyles from './styles'

// components
import SidebarLink from './components/SidebarLink/SidebarLink'

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from '../../context/LayoutContext'

import { useSelector } from 'react-redux'

const structure = [
  {
    id: 0,
    label: 'Home',
    link: '/app/dashboard',
    icon: <HomeIcon />,
    su: false,
    right: 'ALL',
  },
  {
    id: 1,
    label: 'Pending Review',
    link: '/app/review',
    icon: <AccountBalance />,
    su: false,
    right: 'supervisor'
  },
  {
    id: 2,
    label: 'Accounts',
    link: '/app/accounts',
    icon: <AirlineSeatReclineNormal />,
    su: false,
    right: 'AS'
  },

  // { id: 3, label: 'Loan Information', link: '/app/loans', icon: <AttachMoney /> ,},

  {
    id: 3,
    label: 'Loans',
    su: false,
    right: 'AL',
    link: '/app/loanss',
    icon: <AttachMoney />,
    children: [
      // { label: 'Loans', link: '/app/loans' },
      // { label: 'Loan History', link: '/app/loan_history' },
      // { label: 'Approved Loans', link: '/app/loan_params' },
      // { label: 'Declined  Loans', link: '/app/loan_params' },
      // {
      //   label: 'Loan Repayment Reports',
      //   link: '/app/loan_repayment',
      // },
      { label: 'Loan Parameters', link: '/app/params' , right: 'EP'},
    ],
  },

  {
    id: 67,
    label: 'Reports',
    su: false,
    right: 'ALL',
    icon: <UIElementsIcon />,
    children: [
      { label: 'Customer Registrations', 
      right: 'CR',
      link: '/app/drivers' },
      { label: 'Disbursed Loans', 
      right: 'DLS',
      link: '/app/loans' },
      {
        label: 'Loan Repayment Reports',
        right: 'LR',
        link: '/app/repayments',
      },
      { label: 'Declined  Loans', 
      right: 'DLL',
      link: '/app/rejected' },
    ],
  },
  {
    id: 4,
    label: 'Users',
    right: 'ALL',
    link: '/app/users',
    icon: <Group />,
    su: false,
  },
  {
    id: 5,
    label: 'Notifications',
    link: '/app/notifications',
    icon: <NotificationsIcon />,
    count: 13,
    su: true,
    isBank: true,
  },
  {
    id: 6,
    label: 'Password Change',
    link: '/app/settings',
    right: 'ALL',
    icon: <Lock />,
    su: false,
    // isBank: true,
  },
  { id: 7, type: 'divider', isBank: true },
  {
    id: 8,
    type: 'title',
    label: 'Miscellaneous',
    su: false,
    // isBank: true,
  },
  {
    id: 9,
    label: 'FAQs',
    link: '/app/faq',
    right: 'ALL',
    icon: <Help />,
    su: false,
    // isBank: true,
  },
  {
    id: 10,
    label: 'Contact Us',
    link: '/app/contact',
    right: 'ALL',
    icon: <ContactPhone />,
    su: false,
    // isBank: true,
  },
]

function Sidebar({ location, history }) {
  var classes = useStyles()
  var theme = useTheme()

  // global
  var { isSidebarOpened } = useLayoutState()
  var layoutDispatch = useLayoutDispatch()

  // local
  var [isPermanent, setPermanent] = useState(true)

  // let su = useSelector(state => state.login.su);
  let user = useSelector((state) => state.login.user)
  const notifications = useSelector((state) => state.notifications)
  const su = user.Roles === 'maker' ? true : false

  useEffect(function () {
    window.addEventListener('resize', handleWindowWidthChange)
    handleWindowWidthChange()
    return function cleanup() {
      window.removeEventListener('resize', handleWindowWidthChange)
    }
  })


  const checkRoles =  (menu) => {
    let userRole =  user.Roles
    return userRole.includes(menu.right) ? true : false
  }

  
  return (
    <Drawer
      variant={isPermanent ? 'permanent' : 'temporary'}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.nyuma}>
        <div className={classes.toolbar} />
        <div className={classes.mobileBackButton}>
          <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          </IconButton>
        </div>

        {/* Top company logo */}
        {/* <div style={{height: "10%", marginTop: "10px", marginBottom: "10px"}} > */}
        {/* <Grid container spacing={0} justify="left">
         <Grid item xs={6} >
       {user["bank.BankID"] === "63" ?
            <img src={!su ? dtb_logo : `https://corp.little.bz/payments/little.png`} alt="dtb"  style={{paddingLeft: "20px", height: "55px"}}/>
            : 
            
            <img src={!su ? sidian_logo : `https://corp.little.bz/payments/little.png`} alt="dtb"  style={{paddingLeft: "20px", height: "55px"}}/>
            }
        </Grid>

        <Grid item xs={6}>

        {!su ? isSidebarOpened ?  <h5 style={{color: "#ffffff"}}> {user["bank.BankName"]}</h5> : null : isSidebarOpened ? <h4 style={{color: "#ffffff"}}>Little Admin</h4>: null}
           {isSidebarOpened ?  <h4 style={{color: "#ffffff"}}>DTB BANK</h4> : null }
        </Grid>
      </Grid> */}

        {/* < Divider style={su ? {margin: "12px", marginBottom: "50px"} :  {margin: "12px"}} className={classes.divider}/>
         </div> */}

        {/* End of top company Logo */}

        {/* User avatar and actions */}

        {/* End of user avater and icons */}

        <List className={classes.sidebarList}>
          {!su
            ? structure
                .filter((item) => checkRoles(item))
                .map((link) => (
                  <SidebarLink
                    key={link.id}
                    history={history}
                    rights={user.Roles}
                    checkRoles = {checkRoles}
                    location={location}
                    isSidebarOpened={isSidebarOpened}
                    {...link}
                    notifications={
                      link.count
                        ? notifications.notifications.length
                        : null
                    }
                  />
                ))
            : structure
                .filter((data) => checkRoles(data))
                .map((link) => (
                  <SidebarLink
                    key={link.id}
                    history={history}
                    location={location}
                    isSidebarOpened={isSidebarOpened}
                    checkRoles = {checkRoles}
                    {...link}
                    notifications={
                      link.count
                        ? notifications.notifications.length
                        : null
                    }
                  />
                ))}
        </List>
        {isSidebarOpened ? (
          <a target="blank" href="https://www.little.bz/ke/tnc.php">
            <span style={{ marginLeft: '40px', color: 'white' }}>
            {user.EMailID}<br/><br/>
            </span>
          </a>
        ) : (
          <h5></h5>
        )}
      </div>
    </Drawer>
  )

  //

  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth
    var breakpointWidth = theme.breakpoints.values.md
    var isSmallScreen = windowWidth < breakpointWidth

    if (isSmallScreen && isPermanent) {
      setPermanent(false)
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true)
    }
  }
}

export default withRouter(Sidebar)
