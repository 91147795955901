import React, { useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper'

import TextField from '@material-ui/core/TextField'

import useStyles from './styles'
import { Button } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import {
  getBankCustomers,
  setCustomer,
} from '../../components/Layout/redux/actions'
import moment from 'moment'
import { toast } from 'react-toastify'

const steps = ['Personal Details', 'Role', 'Confirmation']

// PropagateLoader

export default function Accounts(props) {
  // INITIAL VARS FOR USE STATE
  var date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth()
  var firstDay = new Date(y, 0, 1)
  var lastDay = new Date(y, m + 1, 0)

  firstDay = moment(firstDay).format('MM/DD/YYYY')
  lastDay = moment(lastDay).format('MM/DD/YYYY')
  var classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(0)
  const addUser = useSelector((state) => state.addUser)
  const userId = useSelector((state) => state.login.user.BankID)
  let user = useSelector((state) => state.addUser.user)
  const editUser = useSelector((state) => state.users.selectedUser)
  let customers = useSelector((state) => state.layout.customers)

  const [disable, setActive] = React.useState(true)
  let [loading, setLoading] = useState(true)
  let [color, setColor] = useState('#ffffff')
  const [from, setFrom] = useState(firstDay)
  const [to, setTo] = useState(lastDay)
  const [phoneNumber, setphoneNumber] = useState('254')

  const Edit = {
    FullName: editUser.Name,
    IdNumber: editUser.IDNumbre,
    Email: editUser.EMailID,
    PhoneNumber: editUser.MobileNumber,
    company: editUser.BankID,
    role: '',
    bank: editUser.BankID,
    bankId: editUser.BankID,
  }

  const defaultData = user

  const dispatch = useDispatch()

  const getUser = (phone) => {
    const theCustomer = customers.filter(
      (data) => data.phoneNumber === phone,
    )
  }

  // onclick search button

  const handleClick = (e) => {
    const selected = customers.filter(
      (customer) => customer.MobileNumber === phoneNumber,
    )
    if (selected.length > 0) {
      // the customer exists
      // toast('Customer found')

      dispatch(setCustomer(props.history, selected[0]))
    } else {
      // customer not found
      toast('Customer not found')
    }
  }

  const handleChange = (e) => {
    var input = e.target.value

    if (input.length >= 12) {
      setActive(false)
      setphoneNumber(input)
    } else {
      setActive(true)
      setphoneNumber(input)
    }
  }

  useEffect(() => {
    var dateto = new Date(to)
    var datefrom = new Date(from)
    var status = 'ALL'

    var pagination = {}

    var data = {
      query: {
        dateFrom: datefrom,
        dateTo: dateto,
        status: status,
      },
      pagination: {
        page: '1',
      },
      user,
    }

    dispatch(getBankCustomers(data))
  }, [])

  return (
    <>
      <Paper
        style={{
          padding: 20,
          flexGrow: 1,
          width: '100%',
          // height: '100px',
        }}
      >
        {/* <Typography>Customer</Typography> */}

        <TextField
          id="outlined-basic"
          style={{ height: '100%', marginLeft: '20px' }}
          label="Phone number (2547..)"
          type="number"
          value={phoneNumber}
          onChange={(e) => handleChange(e)}
          hint="072........"
          variant="outlined"
        />

        <Button
          variant="contained"
          color="primary"
          disabled={disable}
          onClick={(e) => handleClick(e)}
          style={{ height: '50px', marginLeft: '20px' }}
        >
          Search
        </Button>
      </Paper>
    </>
  )
}
