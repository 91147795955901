import {
  GET_BANK_CUSTOMERS,
  GET_BANK_CUSTOMERS_SUCCESS,
  GET_BANK_CUSTOMERS_FAILED,
  SET_CUSTOMER_SUCCESS,
  SET_CUSTOMER,
} from './actionTypes'

const InitialState = {
  customers: [],
  bankId: '69',
  error: '',
  currentCustomer: {},
  loading: false,
  fetched: false,
  count: null,
  drivers: [],
}

export default function layoutReducer(state = InitialState, action) {
  switch (action.type) {
    //create agents
    case GET_BANK_CUSTOMERS:
      return {
        ...state,
        loading: true,
      }
    case GET_BANK_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: action.response,
        count: action.count,
        loading: false,
        fetched: true,
        drivers: action.drivers,
      }
    case GET_BANK_CUSTOMERS_FAILED:
      return {
        ...state,
        customers: [],
        error: action.response,
        loading: false,
      }
    // SET_CUSTOMER
    case SET_CUSTOMER:
      return {
        ...state,
        currentCustomer: action.data,
      }

    case SET_CUSTOMER_SUCCESS:
      return {
        ...state,
        currentCustomer: action.data,
      }
    default:
      return state
  }
}
