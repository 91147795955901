import { call, put } from 'redux-saga/effects'
// import history from './history';
//import { uistartLoading, uistopLoading } from "../Ui/actions";
// import { useHistory } from "react-router-dom";

import { loansCall } from './api'
import { getLoansFailed, getLoansSuccess } from './actions'

//login saga
// let history = useHistory();

export function* loansSaga(action) {
  try {
    const res = yield call(loansCall, action.data, action.querry)
    let response = res.data.data
    const getUnique = (arr, key) => {
      return [
        ...new Map(arr.map((item) => [item[key], item])).values(),
      ]
    }

    const uniqueLoans = getUnique(response, 'LoanID')
    let actualLoans = res.data.data.filter(
      (data) => data.AccountID.substring(0, 3) !== '254',
    )
    yield put(getLoansSuccess(actualLoans))
    // console.log('{{{{{{{[[[[happened', res.data.data)
  } catch (err) {
    // alert("nope")
    yield put(getLoansFailed('404'))
    console.log('loans error error', err)
    //toast('An error Occurred: '+ err.message);
  }
}
