import { takeLatest } from 'redux-saga/effects'
import {
  GET_BANK_CUSTOMERS,
  SET_CUSTOMER,
} from '../components/Layout/redux/actionTypes'
import {
  customersSaga,
  setCustomerSaga,
} from '../components/Layout/redux/saga'
import { GET_BANKS } from '../pages/banks/redux/actionTypes'
import { getBanksSaga } from '../pages/banks/redux/saga'
import { GET_ANALYTICS } from '../pages/dashboard/redux/actionTypes'
import { GetAnalyticsSaga } from '../pages/dashboard/redux/saga'
import {
  BLOCK_DRIVER,
  RESUBMIT_APPLICATION,
} from '../pages/driver/redux/actionTypes'
import { GET_LOANS } from '../pages/loans/redux/actionTypes'
import { loansSaga } from '../pages/loans/redux/saga'
import { LOAN_REPAYMENT } from '../pages/loan_repayment/redux/actionTypes'
import { repaymentsSaga } from '../pages/loan_repayment/redux/saga'
import { LOGIN_REQUEST } from '../pages/login/redux/actionTypes'
import { loginSaga } from '../pages/login/redux/saga'
import { GET_NOTIFICATION } from '../pages/notifications/redux/actionTypes'
import { getNotificationSaga } from '../pages/notifications/redux/saga'
import { GET_DECLINED } from '../pages/rejected_loans/redux/actionTypes'
import { getRejectedSaga } from '../pages/rejected_loans/redux/saga'
// resubmitDriverSaga
import {
  blockDriverSaga,
  resubmitDriverSaga,
} from '../pages/driver/redux/saga'

import {
  DELETE_USER,
  GET_USERS,
  SET_USER_EDIT,
} from '../pages/users/redux/actionTypes'
import {
  deleteUserSaga,
  getUsersSaga,
  setUsersSaga,
} from '../pages/users/redux/saga'
import { REGISTER_USER } from '../pages/user_add/redux/actionTypes'
import { AddUserSaga } from '../pages/user_add/redux/saga'
import {
  GET_PARAMS,
  UPDATE_PARAMS,
} from '../pages/params/redux/actionTypes'
import {
  getParamsSaga,
  updateParamsSaga,
} from '../pages/params/redux/saga'
import {
  GET_BLOCKED,
  GET_LIST_PARAMS,
  LIST_BLOCKED,
  UNBLOCK_DRIVER,
} from '../pages/pending_review/redux/actionTypes'
import {
  listBlockedSaga,
  unblockDriver,
  listParamsChangesSaga,
  blockedDriversSaga
} from '../pages/pending_review/redux/saga'

// import { signupSaga, loginSaga, logOutSaga, readUsersSaga, deleteUsersSaga } from "./Auth/saga";

export default function* rootSaga() {
  //signup
  // yield takeLatest(SIGNUP, signupSaga);

  // //login
  yield takeLatest(LOGIN_REQUEST, loginSaga)

  //loginSaga
  yield takeLatest(GET_BANK_CUSTOMERS, customersSaga)

  //set current customer saga
  yield takeLatest(SET_CUSTOMER, setCustomerSaga)

  //get loans saga

  yield takeLatest(GET_LOANS, loansSaga)

  //analytics saga
  yield takeLatest(GET_ANALYTICS, GetAnalyticsSaga)

  yield takeLatest(GET_NOTIFICATION, getNotificationSaga)

  yield takeLatest(GET_USERS, getUsersSaga)

  yield takeLatest(GET_BANKS, getBanksSaga)

  yield takeLatest(REGISTER_USER, AddUserSaga)

  yield takeLatest(SET_USER_EDIT, setUsersSaga)

  yield takeLatest(DELETE_USER, deleteUserSaga)

  yield takeLatest(GET_DECLINED, getRejectedSaga)

  // repaymentsSaga

  yield takeLatest(LOAN_REPAYMENT, repaymentsSaga)
  yield takeLatest(RESUBMIT_APPLICATION, resubmitDriverSaga)
  yield takeLatest(GET_PARAMS, getParamsSaga)

  // BLOCK_DRIVER
  yield takeLatest(BLOCK_DRIVER, blockDriverSaga)
  yield takeLatest(UPDATE_PARAMS, updateParamsSaga)
  // listBlockedSaga
  yield takeLatest(GET_BLOCKED, listBlockedSaga)
  //unblockDriver
  yield takeLatest(UNBLOCK_DRIVER, unblockDriver)

  //https://github.com/Cezerin3/Store
  yield takeLatest(GET_LIST_PARAMS, listParamsChangesSaga)
  
  // blockedDriversSaga
  yield takeLatest(LIST_BLOCKED, blockedDriversSaga)

}
