import axios from 'axios'
// const config = {
//   headers: { Authorization: `Bearer ${token}` }
// };

// const bodyParameters = {
//  key: "value"
// };

// Axios.post(
// 'http://localhost:8000/api/v1/get_token_payloads',
// bodyParameters,
// config
// ).then(console.log).catch(console.log);

// let user = JSON.stringify({
//   email: 'kamal@craftsilicon.com',
//   password: '123456'
// })
//read datas

export async function usersCall(data, action) {
  const customerPass = Math.random().toString(10).substr(2, 5)
  const userId = `${data.login}*LS${Math.random().toString(10).substr(2, 4)}`
  const Thedata = data
  // console.log('----------------------', data, action)
  // alert(action)

  // firstname: "",
  // secondname: "",
  // email: "",
  // phone: "",
  // role: "",
  // company:  "",
  // role: "admin",
  // bank: "Little",
  // bankId: ""

  const userRoles = {
    VA: data.VA,
    AS: data.AS,
    CR: data.CR,
    AL: data.AL,
    DL: data.DL,
    LR: data.LR,
    AU: data.AU,
    DU: data.DU,
    BU: data.BU,
    BC: data.BC,
    RA: data.RA,
    EP: data.EP,
  }

  // const myrole = JSON.stringify(userRoles)

  const roleString = () => {
    var VA = userRoles.VA ? 'VA~' : ''
    var AS = userRoles.AS ? 'AS~' : ''
    var AL = userRoles.AL ? 'AL~' : ''
    var DL = userRoles.DL ? 'DL~' : ''
    var LR = userRoles.LR ? 'LR~' : ''
    var AU = userRoles.AU ? 'AU~' : ''
    var BU = userRoles.BU ? 'BU~' : ''
    var BC = userRoles.BC ? 'BC~' : ''
    var RA = userRoles.RA ? 'RA~' : ''
    var EP = userRoles.EP ? 'EP~' : ''
    var UC = userRoles.RA ? 'UC~' : ''
    var CR = userRoles.CR ? 'CR~' : ''
    var DLL = userRoles.CR ? 'DLL~' : ''
    var DLS = userRoles.CR ? 'DLS~' : ''
    return `${'ALL~'+VA + AS + AL + DL + LR + AU + BU  + CR + BC + RA + EP + UC + DLL + DLS}`
  }
  // alert(customerPass)
  let getRoles = (role) => {
    if (role.includes('?0')) {
      // alert('should change')
      let newWord = role.replace("RESET?0", "supervisor")
      return newWord
      // SHOULD CHANGE THE ROLE
    } else if (role.includes('?1')) {
      let newWord = role.replace("RESET?1", "user")
      return newWord
    } else {
      // alert('should not change')
      return role
      // dont change anything
    }
  }

  const user = {
    userid: userId,
    name: data.FullName,
    id: data.bankId,
    roles: `${data.roles} < ${roleString()} < PENDING`,
    mobile: data.PhoneNumber,
    idnumber: data.IdNumber,
    pin: customerPass,
    email: data.Email,
  }
  // alert(JSON.stringify(user))
  // alert(action)
  // let newRole = data.Roles.replace("PENDING", "")
  // alert(JSON.stringify(data))

  // alert(action)
  const user_edit = {
    userid: data.UserID,
    name: data.FullName,
    id: data.bankId,
    roles: `${data.roles} < ${roleString()}`,
    mobile: data.PhoneNumber,
    idnumber: data.IdNumber,
    email: data.Email,
  }
  
  // let blockedId = data.UserID.substring(0, 1) + "D" + data.UserID.substring(1, data.UserID.length)
  const user_block = {
    name: data.FullName,
    id: data.bankId,
    roles: data.roles,
    mobile: data.PhoneNumber,
    idnumber: data.IdNumber,
    email: data.Email,
    // userid: `${data.newId}C`,
  }

  // console.log('telepot', JSON.stringify(user))
 
  if (action === 'new') {
    // alert('nedsdsw')
    //  alert(action)
    // console.log("Here goes nothing---" , data);

    // alert(JSON.stringify(user))
    return await axios.post(
      `https://stats.little.bz/banks/users`,
      JSON.stringify(user),
      {
        headers: {
          authorization: `${localStorage.getItem('id_token')}`,
          'Content-Type': 'application/json',
        },
      },
    )
  } else if (action == 'edit') {
    // alert(JSON.stringify(data))
    // alert(action);
    // alert(JSON.stringify(data))
    const getReset =(role) => {
      let tempRole = role
      if(tempRole.includes('supervisor')){
        tempRole = tempRole.replace('supervisor', 'RESET?0')
      }
      if(tempRole.includes('user')){
        tempRole = tempRole.replace('user', 'RESET?0')
      }
      return tempRole
    }
    let user_reset
    if(data.reset === 'reset'){
      user_reset = {
        userid: data.UserID,
        name: data.Name,
        id: data.BankID,
        mobile: data.MobileNumber,
        idnumber: data.IDNumbre,
        pin: customerPass,
        email: data.EMailID,
        roles: getReset(data.Roles),
      }
      localStorage.setItem('tempKey', user_reset.pin)
    }
    let thadata = data.reset === 'reset' ? user_reset : user_edit
    console.log('Here goes nothing---', data)
    // alert(JSON.stringify(user_reset))
    return await axios.patch(
      `https://stats.little.bz/banks/users/${data.userId || data.UserID}`,
      JSON.stringify(thadata),
      {
        headers: {
          authorization: `${localStorage.getItem('id_token')}`,
          'Content-Type': 'application/json',
        },
      },
    )}
    else if (action == 'approve') {
      // alert(action);
      // alert(JSON.stringify(data))
      const user_approve = {
        userid: data.UserID,
        name: data.Name,
        id: data.BankID,
        roles: data.Roles.replace("PENDING", ""),
        mobile: data.MobileNumber,
        idnumber: data.IDNumbre,
        pin: data.PIN,
        email: data.EMailID,
      }
      console.log('Here goes nothing---', data)
      return await axios.patch(
        `https://stats.little.bz/banks/users/${data.UserID}`,
        JSON.stringify(user_approve),
        {
          headers: {
            authorization: `${localStorage.getItem('id_token')}`,
            'Content-Type': 'application/json',
          },
        },
      )}
    else if (action == 'block') {
      // alert(action);
      let blockedId = data.UserID.substring(0, 1) + "D" + data.UserID.substring(1, data.UserID.length)
      // alert(JSON.stringify(blockedId))
     
      const Mydata = { 
        name: data.Name,
        id: data.BankID,
        roles: `${data.Roles}tbl`,
        mobile: data.MobileNumber,
        idnumber: data.IDNumbre,
        email: data.EMailID,
        userid: blockedId,
      }
      console.log("user_block", data, Mydata)
      console.log('Here goes nothing---', data)
      return await axios.patch(
        `https://stats.little.bz/banks/users/${data.UserID}`,
        JSON.stringify(Mydata),
        {
          headers: {
            authorization: `${localStorage.getItem('id_token')}`,
            'Content-Type': 'application/json',
          },
        },
      )
    }
   else {
    // alert()
    const password_new = {
      userid: data.UserID,
      name: data.Name,
      id: data.BankID,
      mobile: data.MobileNumber,
      idnumber: data.IDNumbre,
      roles: getRoles(data.Roles),
      email: data.EMailID,
      pin: data.PIN,
    }

    const password = {
      userid: data.UserID,
      name: data.Name,
      id: data.BankID,
      mobile: data.MobileNumber,
      idnumber: data.IDNumbre,
      email: data.EMailID,
      pin: data.PIN,
    }
    // alert('this is where is will happen')
    // console.log('Here goes SOMETHING---', data.UserID, password, data)
    // alert(data.UserID)

    let Mydata = action === 'password' ? password : password_new

    // console.log('sali', password_new , data.us)
    // alert(JSON.stringify(password_new))
    // alert(data.UserID)
    return await axios.patch(
      `https://stats.little.bz/banks/users/${Mydata.userid}`,
      JSON.stringify(Mydata),
      {
        headers: {
          authorization: `${localStorage.getItem('id_token')}`,
          'Content-Type': 'application/json',
        },
      },
    )
  }
}
