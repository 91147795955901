import { call, put } from 'redux-saga/effects'

import { customersCall } from './api'
import {
  getBankCustomersFailed,
  getBankCustomersSuccess,
  setCustomerSuccess,
} from './actions'
import { toast } from 'react-toastify'

export function* customersSaga(action) {
  try {
    const res = yield call(customersCall, action.data)
    // console.log("customers ......>>> console",res);
    function getUnique(array) {
      var uniqueArray = []
      // let i;
      // Loop through array values
      // CustomerID
      for (let i = 0; i < array.length; i++) {
        if (
          uniqueArray.some(
            ({ IDNumber }) => IDNumber === array[i].IDNumber,
          )
        ) {
          // console.log(uniqueArray.indexOf(array[i].CustomerID))
          console.log('contains')
          // alert("contains");
        } else {
          if (
            array[i]['BankAccountStatuses.OutputData'] !==
            'No Response'
          ) {
            uniqueArray.push(array[i])
            // alert("hy")
          }
          console.log('not there')
          // alert("Data the finding");
        }
      }
      return uniqueArray
    }
    // MobileNumber
    console.log('^^^^^^^^^^^', res.data.customers.rows)
    // const querry = res.data.customers.rows.filter(
    //   (data) => data.MobileNumber === '254110629015',
    // )

    // alert(querry.length)

    const drivers = getUnique(res.data.customers.rows)
    const resultCustomersData = drivers.filter((a) => {
      var date = new Date(a.CreatedOn)
      return (
        date >= action.data.query.dateFrom &&
        date <= action.data.query.dateTo
      )
    })

    const errorCustomers = resultCustomersData.filter(
      (data) => JSON.parse(data['LittleBankAccounts.RowID']) === null,
    )
    // AccountToken  AccountID
    const validateCustomers = resultCustomersData.filter(
      (data) =>
        data['LittleBankAccounts.AccountID'] !== null &&
        data['LittleBankAccounts.AccountToken'] === null,
    )

    const successCustomers = resultCustomersData.filter(
      (data) =>
        data['LittleBankAccounts.AccountID'] !== null &&
        data['LittleBankAccounts.AccountToken'] !== null,
    )

    //  if(action.data.query.status !== 'ALL'){
    // alert(action.data.query.status)
    //     resultCustomersData.filter(data => JSON.parse(data['BankAccountStatuses.OutputData']).message == action.data.query.status)
    //  }

    let feedback = []
    switch (action.data.query.status) {
      case 'SUCCESS':
        feedback = successCustomers
        break
      case 'PENDING':
        feedback = errorCustomers
        break
      case 'VALIDATE':
        feedback = validateCustomers
        break
      case 'ALL':
        feedback = resultCustomersData
        break
      default:
        feedback = resultCustomersData
        break
    }

    yield put(
      getBankCustomersSuccess(
        feedback,
        res.data.customers.count,
        res.data.customers.rows,
      ),
    )
    // alert("success customers");
  } catch (err) {
    yield put(getBankCustomersFailed(err.message))

    console.log('the error is: the error', err)

    // alert("an error occurred" + err.message);

    toast('An error Occurred, ' + err.response.data.message)
  }
}

///SET CUSTOMER SAGA

export function* setCustomerSaga(action) {
  console.log('the customer %%%%5', action.history)
  try {
    yield put(setCustomerSuccess(action.data))
    action.history.push('/app/driver')
  } catch (err) {
    alert(err.message)
  }
}
