import {
  GET_PARAMS,
  GET_PARAMS_FAILED,
  GET_PARAMS_SUCCESS,
  SET_PARAMS,
  UPDATE_PARAMS,
  UPDATE_PARAMS_FAILED,
  UPDATE_PARAMS_SUCCESS,
} from './actionTypes'

//get analytics data
export const getParams = (id) => {
  return {
    type: GET_PARAMS,
    id,
  }
}

export const getParamsSuccess = (response) => {
  return {
    type: GET_PARAMS_SUCCESS,
    response,
  }
}

export const getParamsFailed = (response) => {
  return {
    type: GET_PARAMS_FAILED,
    response,
  }
}

// UPDATE PARAMS

export const setParam = (name, value) => {
  return {
    type: SET_PARAMS,
    name,
    value,
  }
}

//editParam

export const editParam = (data) => {
  return {
    type: UPDATE_PARAMS,
    data,
  }
}

export const editParamSuccess = (res) => {
  return {
    type: UPDATE_PARAMS_SUCCESS,
    res,
  }
}

export const editParamFailed = (res) => {
  return {
    type: UPDATE_PARAMS_FAILED,
    res,
  }
}
