import {
  GET_BLOCKED,
  GET_BLOCKED_FAILED,
  GET_BLOCKED_SUCCESS,
  GET_LIST_PARAMS,
  GET_LIST_PARAMS_FAILED,
  GET_LIST_PARAMS_SUCCESS,
  LIST_BLOCKED,
  LIST_BLOCKED_FAILED,
  LIST_BLOCKED_SUCCESS,
  UNBLOCK_DRIVER,
  UNBLOCK_DRIVER_FAILED,
  UNBLOCK_DRIVER_SUCCESS,
} from './actionTypes'

const InitialState = {
  blockedList: [],
  loading: false,
  message: '',
  error: '',
  paramsList: [{UserID: '', UnsupervisedCharges: [{Percentage: ''},{Percentage: ''},{Percentage: ''},{Percentage: ''}]}],
  success: false,
  blockedDrivers: []
}

export default function pendingReviewReducer(
  state = InitialState,
  action,
) {
  switch (action.type) {
    //create agents

    case GET_BLOCKED:
      return {
        ...state,
        loading: true,
        success: false
      }

    case GET_BLOCKED_SUCCESS:
      return {
        ...state,
        blockedList: action.results,
        loading: false,
      }

    case GET_BLOCKED_FAILED:
      return {
        ...state,
        error: action.results,
        loading: false,
        blockedList: []
      }

    case GET_LIST_PARAMS:
      return {
        ...state,
        loading: true,
        success: false
      }

    case GET_LIST_PARAMS_SUCCESS:
      return {
        ...state,
        paramsList: action.results,
        loading: false,
        success: true
      }

    case GET_LIST_PARAMS_FAILED:
      return {
        ...state,
        error: action.results,
        loading: false
        // paramsList: []
      }

    case UNBLOCK_DRIVER:
      return {
        ...state,
        loading: true,
      }

    case UNBLOCK_DRIVER_SUCCESS:
      return {
        ...state,
        // paramsList: action.results,
        loading: false,
      }

    case UNBLOCK_DRIVER_FAILED:
      return {
        ...state,
        error: action.results,
        loading: false,
      }

      case LIST_BLOCKED:
      return {
        ...state,
        loading: true,
      }

    case LIST_BLOCKED_SUCCESS:
      return {
        ...state,
        // paramsList: action.results,
        loading: false,
        blockedDrivers: action.results
      }

    case LIST_BLOCKED_FAILED:
      return {
        ...state,
        error: action.results,
        loading: false,
        blockedDrivers: []
      }

    default:
      return state
  }
}
