import {
  BLOCK_DRIVER,
  BLOCK_DRIVER_FAILED,
  BLOCK_DRIVER_SUCCESS,
  RESET_MESSAGE,
  RESUBMIT_APPLICATION,
  RESUBMIT_APPLICATION_FAILED,
  RESUBMIT_APPLICATION_SUCCESS,
} from './actionTypes'

//get analytics data

export const resubmitDriver = (data) => {
  return {
    type: RESUBMIT_APPLICATION,
    data,
  }
}

export const resubmitDriverFailed = (res) => {
  return {
    type: RESUBMIT_APPLICATION_FAILED,
    res,
  }
}

export const resubmitDriverSuccess = (res) => {
  return {
    type: RESUBMIT_APPLICATION_SUCCESS,
    res,
  }
}

// blockDriver

export const blockDriver = (data) => {
  return {
    type: BLOCK_DRIVER,
    data,
  }
}

export const blockDriversSuccess = (res) => {
  return {
    type: BLOCK_DRIVER_SUCCESS,
    res,
  }
}

export const blockDriverFailed = (res) => {
  return {
    type: BLOCK_DRIVER_FAILED,
    res,
  }
}

export const resetMessage = () => {
  return {
    type: RESET_MESSAGE,
  }
}
