import React, { useState, useEffect } from 'react'
import { Button } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import moment from 'moment'

// styles

import useStyles from './styles'
import MUIDataTable from 'mui-datatables'
import { Autorenew } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import SyncLoader from 'react-spinners/SyncLoader'
import { getLoans } from './redux/actions'
import { useJsonToCsv } from 'react-json-csv'
import NumberFormat from 'react-number-format'

//custom columns in the table

const options = {
  print: 'false',
  filter: 'true',
  // download: 'false',
  selectableRows: false,
  fixedHeaderOptions: {
    xAxis: true,
    yAxis: true
  },
  setTableProps: () => ({
    padding: 'small',
    size: 'small'
  }),
  downloadOptions: {
    filename: 'LoansDataLittle.csv',
    separator: ',',
    filterOptions: {
      useDisplayedColumnsOnly: true,
      useDisplayedRowsOnly: true,
    },
  },
  elevation: 7,
  fixedHeader: false,
  // customToolbar: () => {
  //   return <CustomToolbar />
  // },
}

//

export default function Loans(props) {
  var classes = useStyles()
  var theme = useTheme()
  let su = useSelector((state) => state.login.su)
  let loans = useSelector((state) => state.loans.loans)
  let bankId = useSelector((state) => state.layout.bankId)
  const user = useSelector((state) => state.login.user)
  let error = useSelector((state) => state.loans.error)
  let loading = useSelector((state) => state.loans.loading)
  let fetched = useSelector((state) => state.loans.fetched)
  const open = useSelector((state) => state.loans.open)
  const [dateRange, setDateRange] = useState({})
  const [dateViewOpen, setdateViewOpen] = useState(false)
  const [loanStatus, setLoanStatus] = useState('')
  const [loanType, setLoanType] = useState('')
  const [currentFilter, setFilter] = useState('')
  let customers = useSelector((state) => state.layout.customers)
  const [appliedValue, setAppliedValue] = useState('all')
  const [maturityValue, setMaturityValue] = useState('all')
  const [closedValue, setClosedValue] = useState('all')
  const [disbursedValue, setDisbursedValue] = useState('all')

  const [myFilterData, setQuery] = useState({
    user: {},
    CustomerID: '',
    AppliedOnFrom: '',
    AppliedOnTo: '',
    MaturityDateFrom: '',
    MaturityDateTo: '',
    ClosedOnFrom: '',
    ClosedOnTo: '',
    DisbursedOnFrom: '',
    DisbursedOnTo: '',
    BankID: user['bank.BankCode'],
    LoanType: '',
    Status: '',
    download: false,
  })

  //data handlers

  const dispatch = useDispatch()

  useEffect(() => {
    // myFilterData.user = {...myFilterData, user : user};
    // console.log("harry porter ",myFilterData.user);
    // alert("I can")
    if (!fetched) {
      dispatch(getLoans(user, null))
    }
  }, [])

  //columns

  // const formatter = ()=> {
  //   return new Intl.NumberFormat('en-US', {
  //     style: 'currency',
  //     currency: 'KES',

  //     // These options are needed to round to whole numbers if that's what you want.
  //     //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //     //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  //   });
  // }

  const columns = [
    // {
    //   name: 'LoanID',
    //   label: 'Loan ID',
    //   options: {
    //     customBodyRender: (value, tableMeta, updateValue) => (
    //       <IconButton
    //         aria-label="View"
    //         size="small"
    //         onClick={
    //           () =>
    //             dispatch(
    //               setCustomer(
    //                 props.history,
    //                 customers[
    //                   customers.findIndex(
    //                     (p) => p.CustomerID === value,
    //                   )
    //                 ],
    //               ),
    //             )
    //           // console.log(customers[0])
    //         }
    //       >
    //         {/* <Visibility /> */}
    //         {value}
    //       </IconButton>
    //     ),
    //     filter: false,
    //     sort: true,
    //   },
    // },
    // {
    //   name: 'CustomerID',
    //   label: 'Customer ID',
    //   options: {
    //     filter: false,
    //     sort: true,
    //     customBodyRender: (value, tableMeta, updateValue) => (
    //       <small style={{ fontSize: '14', fontWeight: 'bold' }}>
    //         {value}
    //       </small>
    //     ),
    //   },
    // },
    {
      name: 'AccountID',
      label: 'Account ID',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'LoanID',
      label: 'Loan ID',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'ProductID',
      label: 'Product ID',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'FirstName',
      label: 'First Name',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'MobileNumber',
      label: 'Mobile Number',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'AppliedOn',
      label: 'Book Date',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) =>
          value ? (
            <small>{moment(value).format('MMM Do YY')}</small>
          ) : (
            <small>~</small>
          ),
      },
    },
    {
      name: 'MaturityDate',
      label: 'Maturity Date',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) =>
          value ? (
            <small>
              {/* {moment(value).add(1, 'W').format('MMM Do YY')} */}
              {value}
            </small>
          ) : (
            <small>~</small>
          ),
      },
    },
    {
      name: 'MaturityDat',
      label: 'Company Name',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <small>Little App</small>
        ),
      },
    },
    {
      name: 'Maturiate',
      label: 'Employer a/c',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <small>8F686BF31F26464C</small>
        ),
      },
    },
    {
      name: 'Int',
      label: 'Int Rate',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <small>~</small>
        ),
      },
    },

    {
      name: 'Processing',
      label: 'Processing Rate',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <small>~</small>
        ),
      },
    },

    {
      name: 'LoanID',
      label: 'Overdue Amount',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let total
          let data = loans[loans.findIndex((p) => p.LoanID === value)]
          var varDate = new Date(data.MaturityDate); //dd-mm-YYYY
          var today = new Date();
          total = 0
          today.setHours(0,0,0,0);
          if(varDate > today) {
          total = Math.round(
            (+data.LoanAmount +
              +data.InterestAmount +
              +data.ProcessingFees +
              Number.EPSILON) *
            100,
          ) / 100
          }

          return Math.sign(value) == -1 ? (
            <NumberFormat
              style={{ color: 'red' }}
              value={value}
              allowNegative={false}
              className="foo"
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
              renderText={(value, props) => (
                <div {...props}>{'(' + total + ')'}</div>
              )}
            />
          ) : (
            <NumberFormat
              value={value}
              className="foo"
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
              renderText={(value, props) => (
                <div {...props}>
                  {total}
                </div>
              )}
            />
          )
        },
      },
    },

    {
      name: 'LoanID',
      label: 'Overdue Days',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) =>{
          let total
          let data = loans[loans.findIndex((p) => p.LoanID === value)]
          var varDate = new Date(data.MaturityDate); //dd-mm-YYYY
          var today = new Date();
          total = 0
          today.setHours(0,0,0,0);
          var Difference_In_Time = varDate.getTime() - today.getTime();
          
          if(varDate < today) {
            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            total = Difference_In_Days
          }

          return total
        }
      },
    },

    {
      name: 'LoanID',
      label: 'Outstanding Amount',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let data = loans[loans.findIndex((p) => p.LoanID === value)]
          return Math.sign(value) == -1 ? (
            <NumberFormat
              style={{ color: 'red' }}
              value={value}
              allowNegative={false}
              className="foo"
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
              renderText={(value, props) => (
                <div {...props}>{'(' + value + ')'}</div>
              )}
            />
          ) : (
            <NumberFormat
              value={value}
              className="foo"
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
              renderText={(value, props) => (
                <div {...props}>
                  {Math.round(
                    (+data.LoanAmount +
                      +data.InterestAmount +
                      +data.ProcessingFees +
                      Number.EPSILON) *
                    100,
                  ) / 100}
                </div>
              )}
            />
          )
        },
      },
    },
    // {
    //   name: 'DisbursedOn',
    //   label: 'DisbursedOn',
    //   options: {
    //     filter: false,
    //     sort: true,
    //     customBodyRender: (value, tableMeta, updateValue) =>
    //       value ? (
    //         <small>{moment(value).format('MMM Do YY')}</small>
    //       ) : (
    //         <small>~</small>
    //       ),
    //   },
    // },
    // {
    //   name: 'MaturityDate',
    //   label: 'MaturityDate',
    //   options: {
    //     filter: false,
    //     sort: true,
    //     customBodyRender: (value, tableMeta, updateValue) =>
    //       value ? (
    //         <small>{moment(value).format('MMM Do YY')}</small>
    //       ) : (
    //         <small>~</small>
    //       ),
    //   },
    // },
    // {
    //   name: 'ClosedOn',
    //   label: 'ClosedOn',
    //   options: {
    //     filter: false,
    //     sort: true,
    //     customBodyRender: (value, tableMeta, updateValue) =>
    //       value ? (
    //         <small>{moment(value).format('MMM Do YY')}</small>
    //       ) : (
    //         <small>~</small>
    //       ),
    //   },
    // },

    {
      name: 'LoanAmount',
      label: 'Loan Amount',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) =>
          Math.sign(value) == -1 ? (
            <NumberFormat
              style={{ color: 'red' }}
              value={value}
              allowNegative={false}
              className="foo"
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
              renderText={(value, props) => (
                <div {...props}>{'(' + value + ')'}</div>
              )}
            />
          ) : (
            <NumberFormat
              value={value}
              className="foo"
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
              renderText={(value, props) => (
                <div {...props}>{value}</div>
              )}
            />
          ),
      },
    },

    {
      name: 'InterestAmount',
      label: 'Interest Amount',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) =>
          Math.sign(value) == -1 ? (
            <NumberFormat
              style={{ color: 'red' }}
              value={value}
              allowNegative={false}
              className="foo"
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
              renderText={(value, props) => (
                <div {...props}>{'(' + value + ')'}</div>
              )}
            />
          ) : (
            <NumberFormat
              value={value}
              className="foo"
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
              renderText={(value, props) => (
                <div {...props}>{value}</div>
              )}
            />
          ),
      },
    },

    {
      name: 'Penalty',
      label: 'Accrued Penalty',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) =>
          Math.sign(value) == -1 ? (
            <NumberFormat
              style={{ color: 'red' }}
              value={value}
              allowNegative={false}
              className="foo"
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
              renderText={(value, props) => (
                <div {...props}>{'(' + value + ')'}</div>
              )}
            />
          ) : (
            <NumberFormat
              value={value}
              className="foo"
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
              renderText={(value, props) => (
                <div {...props}>{0}</div>
              )}
            />
          ),
      },
    },

    {
      name: 'ProcessingFees',
      label: 'Processing Fees',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) =>
          Math.sign(value) == -1 ? (
            <NumberFormat
              style={{ color: 'red' }}
              value={value}
              allowNegative={false}
              className="foo"
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
              renderText={(value, props) => (
                <div {...props}>{'(' + value + ')'}</div>
              )}
            />
          ) : (
            <NumberFormat
              value={value}
              className="foo"
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
              renderText={(value, props) => (
                <div {...props}>
                  {/* {Math.round((value + Number.EPSILON) * 100) / 100} */}
                 { value}
                </div>
              )}
            />
          ),
      },
    },
    {
      name: 'LoanID',
      label: 'Payable Amount',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let data = loans[loans.findIndex((p) => p.LoanID === value)]
          return Math.sign(value) == -1 ? (
            <NumberFormat
              style={{ color: 'red' }}
              value={value}
              allowNegative={false}
              className="foo"
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
              renderText={(value, props) => (
                <div {...props}>{'(' + value + ')'}</div>
              )}
            />
          ) : (
            <NumberFormat
              value={value}
              className="foo"
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
              renderText={(value, props) => (
                <div {...props}>
                  {Math.round(
                    (+data.LoanAmount +
                      +data.InterestAmount +
                      +data.ProcessingFees +
                      Number.EPSILON) *
                    100,
                  ) / 100}
                </div>
              )}
            />
          )
        },
      },
    },
  ]

  const [age, setAge] = React.useState('')
  const { saveAsCsv } = useJsonToCsv()

  // Loan Type
  const handleSelectType = (event) => {
    setLoanType(event.target.value)
    // setQuery({
    //   ...myFilterData, LoanType  : event.target.value
    // })

    if (event.target.value === 'all') {
      setQuery({
        ...myFilterData,
        LoanType: '',
      })
    } else {
      setQuery({
        ...myFilterData,
        LoanType: event.target.value,
      })
    }
  }

  // Loan status
  const handleSelectStatus = (event) => {
    setLoanStatus(event.target.value)
    if (event.target.value === 'all') {
      setQuery({
        ...myFilterData,
        Status: '',
      })
    } else {
      setQuery({
        ...myFilterData,
        Status: event.target.value,
      })
    }

    // alert(event.target.value)
  }

  const handleChange = (event) => {
    let the_value = event.target.value
    if (the_value === 'AppliedOn') {
      setdateViewOpen(true)
      //  setQuery({
      //    ...myFilterData, AppliedOn  : ""
      //  })
      // appliedValue

      setAppliedValue(the_value)
      setFilter(the_value)
    } else if (the_value === 'DisbursedOn') {
      setdateViewOpen(true)
      setQuery({
        ...myFilterData,
        DisbursedOn: '',
      })
      setDisbursedValue(the_value)
      setFilter(the_value)
    } else if (the_value === 'MaturityDate') {
      setdateViewOpen(true)
      setQuery({
        ...myFilterData,
        MaturityDate: '',
      })
      setMaturityValue(the_value)
      setFilter(the_value)
    } else if (the_value === 'ClosedOn') {
      setdateViewOpen(true)
      setQuery({
        ...myFilterData,
        ClosedOn: '',
      })
      setClosedValue(the_value)
      setFilter(the_value)
    } else {
      setdateViewOpen(false)
    }

    // event.target.value === "AppliedOn" ?
    // setdateViewOpen(true) :
    // setdateViewOpen(false)
  }

  const filename = 'Csv-file',
    fields = {
      index: 'Index',
      guid: 'GUID',
    },
    style = {
      padding: '5px',
    },
    data = [
      { index: 0, guid: 'asdf231234' },
      { index: 1, guid: 'wetr2343af' },
    ],
    text = 'Convert Json to Csv'

  const submitRange = (e) => {
    setdateViewOpen(false)
    const date_type = currentFilter
    const daterange = dateRange.startDate
    const daterangeto = dateRange.endDate

    // alert(daterange);

    if (date_type === 'AppliedOn') {
      // alert("doone ;)"+ daterange)

      setQuery({
        ...myFilterData,
        AppliedOnFrom: daterange.toString(),
        AppliedOnTo: daterangeto.toString(),
      })
    } else if (date_type === 'DisbursedOn') {
      setQuery({
        ...myFilterData,
        DisbursedOnFrom: daterange.toString(),
        DisbursedOnTo: daterangeto.toString(),
      })
    } else if (date_type === 'MaturityDate') {
      setQuery({
        ...myFilterData,
        MaturityDateFrom: daterange.toString(),
        MaturityDateTo: daterangeto.toString(),
      })
    } else if (date_type === 'ClosedOn') {
      setQuery({
        ...myFilterData,
        ClosedOnFrom: daterange.toString(),
        ClosedOnTo: daterangeto.toString(),
      })
    } else {
      setdateViewOpen(true)
    }

    console.log('wings', myFilterData)
  }

  moment.updateLocale(moment.locale(), { invalidDate: '' })
  return (
    <>
      <MUIDataTable
        title={
          !loading ? (
            <Button
              variant="text"
              color="primary"
              onClick={() => dispatch(getLoans(user, null))}
              className={classes.button}
              startIcon={<Autorenew />}
            >
              {!open
                ? 'Loan Applications'
                : 'Filter Loan Applications'}
            </Button>
          ) : (
            <SyncLoader color={'#536DFE'} size={5} />
          )
        }
        data={loans}
        columns={columns}
        options={{
          ...options,
          textLabels: {
            body: {
              noMatch: 'No records found: ' + error,
            },
          },
        }}
      />
    </>
  )
}
