import {
  GET_BANK_CUSTOMERS,
  GET_BANK_CUSTOMERS_SUCCESS,
  GET_BANK_CUSTOMERS_FAILED,
  SET_CUSTOMER,
  SET_CUSTOMER_SUCCESS,
} from './actionTypes'

//login actions
export const getBankCustomers = (data) => {
  return {
    type: GET_BANK_CUSTOMERS,
    data,
  }
}

export const getBankCustomersSuccess = (response, count, drivers) => {
  return {
    type: GET_BANK_CUSTOMERS_SUCCESS,
    response,
    count,
    drivers,
  }
}
export const getBankCustomersFailed = (response) => {
  return {
    type: GET_BANK_CUSTOMERS_FAILED,
    response,
  }
}

//setCustomer

export const setCustomer = (history, data) => {
  return {
    type: SET_CUSTOMER,
    history,
    data,
  }
}

//setcustomer success

export const setCustomerSuccess = (data) => {
  return {
    type: SET_CUSTOMER_SUCCESS,
    data,
  }
}
