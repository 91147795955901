import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  CHANGE_PASS,
} from './actionTypes'

const InitialState = {
  user: {},
  token: null,
  login: false,
  loading: false,
  su: false,
  setPassword: false,
}

///   login working
//add reset password t the login logic

export default function loginReducer(state = InitialState, action) {
  switch (action.type) {
    //create agents

    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.response,
        token: true,
        login: true,
        loading: false,
      }

    case LOGIN_FAILED:
      return {
        ...state,
        user: null,
        loading: false,
        token: false,
      }

    // CHANGE_PASS
    case CHANGE_PASS:
      return {
        ...state,
        setPassword: action.data,
      }

    default:
      return state
  }
}
