import React, { useState } from 'react'

import useStyles from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import { Paper } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { PropagateLoader } from 'react-spinners'
import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { IconButton } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { editParam, setParam } from '../params/redux/actions'

const steps = ['Personal Details', 'Role', 'Confirmation']

// PropagateLoader

export default function EditParam(props) {
  var classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(0)
  const addUser = useSelector((state) => state.addUser)
  const userEmail = useSelector((state) => state.login.user.EMailID)
  let user = useSelector((state) => state.addUser.user)
  const editUser = useSelector((state) => state.users.selectedUser)
  const [disable, setActive] = React.useState(true)
  let [loading, setLoading] = useState(true)
  let [color, setColor] = useState('#ffffff')

  const params = useSelector((state) => state.params)
  //  alert(userEmail)
  const defaultData = user

  const dispatch = useDispatch()

  const handleChange = (e) => {
    let name = e.target.name
    let value = e.target.value
    dispatch(setParam(name, value))
    // console.log(name, value)
  }

  // const editParam = (e) => {
  //   dispatch(editParam(params.paramsEdit))
  // }

  const validateData = () => {
    var checks = false
    var values = params.paramsEdit

    if (values.processing.length < 1) {
      checks = true
    }
    if (values.processing > 40) {
      checks = true
    }

    if (values.interest.length < 1) {
      checks = true
    }
    if (values.interest > 40) {
      checks = true
    }

    if (values.excise.length < 1) {
      checks = true
    }
    if (values.excise > 40) {
      checks = true
    }

    if (values.penalty.length < 1) {
      checks = true
    }
    if (values.penalty > 40) {
      checks = true
    }
    // alert(checks)
    return checks
  }

  return (
    <>
      <div className={classes.root}>
        <Link to="/app/params">
          <IconButton aria-label="delete">
            <ArrowBack />
          </IconButton>
        </Link>
        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={4}>
            <Typography variant="h6" gutterBottom>
              Edit Loans Params
            </Typography>
            {/* <Typography variant="h6" gutterBottom>
              Loading ...
            </Typography> */}
            <Typography variant="h6" gutterBottom>
              {params.message}
            </Typography>
            <Grid container spacing={3} style={{ marginTop: 30 }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="number"
                  type="number"
                  variant="outlined"
                  defaultValue={params.data[1].Percentage}
                  name="processing"
                  label="Processing Fee"
                  value={params.params.processing}
                  // onInput={(e) => {
                  //   e.target.value = Math.max(
                  //     0,
                  //     parseInt(e.target.value),
                  //   )
                  //     .toString()
                  //     .slice(0, 3)
                  // }}
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    maxLength: '3',
                  }}
                  onChange={(e) => handleChange(e)}
                  // value={OldPass}
                  // error={errorOldPass}
                  fullWidth
                  autoComplete="given-name"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="number"
                  type="number"
                  variant="outlined"
                  name="interest"
                  defaultValue={params.data[0].Percentage}
                  value={params.params.interest}
                  onChange={(e) => handleChange(e)}
                  label="Interest Rate"
                  // onInput={(e) => {
                  //   e.target.value = Math.max(
                  //     0,
                  //     parseInt(e.target.value),
                  //   )
                  //     .toString()
                  //     .slice(0, 3)
                  // }}
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    maxLength: '3',
                  }}
                  // onChange={(e) => checkPassword(e.target.value)}
                  // value={OldPass}
                  // error={errorOldPass}
                  fullWidth
                  autoComplete="given-name"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="number"
                  type="number"
                  variant="outlined"
                  defaultValue={params.data[2].Percentage}
                  name="excise"
                  value={params.params.excise}
                  label="Excise Duty"
                  onChange={(e) => handleChange(e)}
                  // onInput={(e) => {
                  //   e.target.value = Math.max(
                  //     0,
                  //     parseInt(e.target.value),
                  //   )
                  //     .toString()
                  //     .slice(0, 3)
                  // }}
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    maxLength: '3',
                  }}
                  // onChange={(e) => checkPassword(e.target.value)}
                  // value={OldPass}
                  // error={errorOldPass}
                  fullWidth
                  autoComplete="given-name"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="number"
                  type="number"
                  variant="outlined"
                  name="penalty"
                  // defaultValue={1}
                  label="Penalty"
                  value={params.params.penalty}
                  onChange={(e) => handleChange(e)}
                  defaultValue={params.data[3].Percentage}
                  // onInput={(e) => {
                  //   e.target.value = parseInt(e.target.value)
                  //     .toString()
                  //     .slice(0, 3)
                  // }}
                  inputProps={{
                    inputMode: 'numeric',
                    // pattern: '[0-9]*',
                    maxLength: '3',
                  }}
                  // onChange={(e) => checkPassword(e.target.value)}
                  // value={OldPass}
                  // error={errorOldPass}
                  fullWidth
                  autoComplete="given-name"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                {!params.loading ? (
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    disabled={validateData()}
                    onClick={(e) =>
                      dispatch(editParam({...params.paramsEdit, email: userEmail}))
                    }
                  >
                    Submit
                  </Button>
                ) : (
                  <PropagateLoader color={'#536DFE'} size={10} />
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </div>
    </>
  )
}
